import React from 'react'
import ReactToPrint from 'react-to-print'

const VaveCmpSettingsBar = (props) => {

    return (
        <div className='row'>
            <div className='col-12 col-md-4'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-3 text-center'>
                        <i className={props.fafaIcon + " fa-2x"} clas></i>
                    </div>
                    <div className='col-9'>
                        <div className="mb-1">
                            <b>{props.title}</b>
                        </div>
                        <span style={{ fontSize: "14px", color: "#474747" }}>{props.description}</span>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-8 justify-content-end'>
                {props.spinTogglar ?
                    <div className="row flex-row-reverse mr-5">
                        <div class="spinner-grow text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="row flex-row-reverse">
                        {props.search && <a class="btn" role="button" onClick={props.searchButtonOnClick}> <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25 text-wrap'>
                                        <i className="fas fa-search text-dark" ></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Search " + (props.searchTextEnd ? props.searchTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        </a>}




                        {props.new && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.newButtonOnClick}><i className="far fa-plus-square text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"New" + (props.newTextEnd ? props.newTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}


                        {props.add && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.addButtonOnClick}><i className="fas fa-sign-in-alt text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Add " + (props.addTextEnd ? props.addTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}



                        {props.edit && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.editButtonOnClick}><i className="fas fa-edit text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Edit" + (props.editTextEnd ? props.editTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}

                        {props.save && <a class="btn" role="button" onClick={props.saveButtonOnClick}> <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <i className="fas fa-save text-success" ></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Save" + (props.saveTextEnd ? props.saveTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        </a>}

                        {props.analize && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.analizeButtonOnClick}><i className="fas fa-chart-pie text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Analize" + (props.analizeTextEnd ? props.analizeTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}


                        {props.scann && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.scannButtonOnClick}><i className="fas fa-qrcode text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Scan" + (props.scannTextEnd ? props.scannTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}

                        {props.upload && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.uploadButtonOnClick}><i className="fas fa-file-upload text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Upload" + (props.uploadTextEnd ? props.uploadTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}

                        {props.refresh && <a class="btn" role="button" onClick={props.refreshButtonOnClick}>  <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <i className="fas fa-sync-alt text-danger" ></i>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Refresh" + (props.refreshTextEnd ? props.refreshTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        </a>}

                        {props.remove && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.removeButtonOnClick}><i className="fas fa-trash-alt text-danger" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Remove" + (props.removeTextEnd ? props.removeTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}

                        {props.print &&
                            <ReactToPrint
                                pageStyle={props.pageStyle}
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <a class="btn"><div className="col-2"> <table>
                                        <tr>
                                            <td className='text-center w-25'>
                                                <i className="fa fa-print" ></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "black" }}>
                                                Print
                                            </td>
                                        </tr>
                                    </table>
                                    </div>
                                    </a>
                                }}
                                content={() => props.componentRef}
                            />
                        }

                        {props.getPDF && <div className="col-2">
                            <table>
                                <tr>
                                    <td className='text-center w-25'>
                                        <a class="btn" role="button" onClick={props.getpdfButtonOnClick}><i className="fa fa-file-pdf-o text-primary" ></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "#474747" }}>
                                        {"Get PDF" + (props.getpdfTextEnd ? props.getpdfTextEnd : "")}
                                    </td>
                                </tr>
                            </table>
                        </div>}
                    </div>
                }
            </div>
        </div>
    )
}

export default VaveCmpSettingsBar