import React from 'react'
import './CmpGenCard.css'


const AlbsCmpGenCard = props => {
  return (
    <div className='container container-fluid px-2 CmpGenCard_med_mob_padding mt-2'>
      <div className="card border rounded-lg " style={{ boxShadow: "0px 3px 6px rgba(00, 00, 00, 16%)" }} >
        <div className="card-body CmpGenCard_med_mob_padding_body mx-2">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default AlbsCmpGenCard;