import React, { useState, useEffect } from 'react'
import firebase from '../../fbConfig/fbConfig'

const BackendUserGroups = (props) => {
    //Whole inputs
    const [inputs, setInputs] = useState({
        groupName: '',
        groupDescription: '',
        priv: []
    });

    const [adminPrivs, setAdminPrivs] = useState({
        priv: []
    });

    //Get existing user privs to the grid
    const getuserPriv = () => {
        firebase.firestore().collection('userGroups').where('groupName', '==', 'Admin').get()
            .then((docs) => {
                docs.forEach((doc) => {
                    setAdminPrivs((current) => ({ ...current, priv: doc.data().priv }))
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Values change in text box
    const inputChange = (e) => {
        e.persist();
        setInputs((current) => ({ ...current, [e.target.name]: e.target.value }))
    }

    //ErrorHandler
    const ErrorHandler = () => {
        if (!(inputs.priv[0])) {
            alert('Add at least one priviladge');
            return 0;
        }
        else if (inputs.groupName == "") {
            alert('Enter user Group');
            return 0;
        }
        else if (inputs.groupDescription == "") {
            alert('Enter user Group description');
            return 0;
        }
        else {
            return 1;
        }
    }

    //Add priviladges to group when click the check box
    const checkBoxClick = (privCode, privDesc) => {
        if ((inputs.priv).find(function (el) { return el.privCode == privCode })) {
            var arr = (inputs.priv).filter(function (el) { return el.privCode != privCode })
            setInputs((current) => (
                {
                    ...current,
                    priv: arr
                }))
        } else {
            setInputs((current) => (
                {
                    ...current,
                    priv:
                        [...current.priv,
                        {
                            privCode: privCode,
                            privDesc: privDesc
                        }
                        ]
                }))
        }
    }

    //Reset all the fields 
    const resetButtonHandler = () => {
        setInputs({
            groupName: '',
            groupDescription: '',
            priv: []
        });
    }

    //Add group details into database
    const addDataToFb = () => {
        console.log(inputs)
        if (ErrorHandler()) {
            firebase.firestore().collection('userGroups').add(inputs)
                .then(() => {
                    alert('Group successfully created')
                    resetButtonHandler();
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    useEffect(() => {
        getuserPriv();
    }, [])

    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">User Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" >
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Create new user group</div>
                                            <div class="card-category">Fill below fields to create new user group</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="card-category mb-2">General details</div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Group Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter group name" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="groupName" value={inputs.groupName} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Group Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="groupDescription" value={inputs.groupDescription} />
                                    </div>
                                </div>

                                {/* User priv list */}
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="card-category mb-2">Priviladges</div>
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <th scope="row">#</th>
                                                <th>Priviladge code</th>
                                                <th>Priviladge description</th>
                                                <th>Select</th>
                                            </tr>
                                            {
                                                (adminPrivs.priv).map((priviladge, index) =>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{priviladge.privCode}</td>
                                                        <td>{priviladge.privDesc}</td>
                                                        <td><input type="checkbox" onClick={() => checkBoxClick(priviladge.privCode, priviladge.privDesc ? priviladge.privDesc : "")} /></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row py-3 ml-5 mr-5 justify-content-center">
                                    <button type="button" class="btn btn-secondary mr-2" onClick={resetButtonHandler}>Reset</button>
                                    <button type="button" class="btn btn-primary mr-2" onClick={addDataToFb}>Create Group</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackendUserGroups;