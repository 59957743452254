import Axios from 'axios'

export const rep_CompletedDeliverySummery = async (inputs) => {
    var sDate = inputs.dateRange.startDate
    var eDate = inputs.dateRange.endDate
    var reportRes = {
        fields: [],
        data: [],
        summary: {}
    };

    const response = await Axios.get(`/reports/completedDeliveries?startDate=${sDate}&endDate=${eDate}`)

    response.data.data.sort((a, b) => {
        let fa = a.phoneNumber.toLowerCase(),
            fb = b.phoneNumber.toLowerCase();

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    }).forEach((data) => {
        reportRes.data.push({
            name: data.name,
            phoneNumber: data.phoneNumber,
            coNumber: data.coNumber,
            onlineId: data.onlineId,
            deliveryDate: data.deliveryDate.split('T')[0],
            deliveredBy: data.deliveredBy,
            totalQty: data.totalQty,
            delCharge: data.delCharge,
            orderValue: data.orderValue,
            totalAmount: data.totalAmount,
            paymentType: data.paymentType
        })
    })

    if (response.data.summary) {
        reportRes.data.push({
            name: "Total",
            phoneNumber: "",
            coNumber: "",
            onlineId: "",
            deliveryDate: "",
            deliveredBy: "",
            totalQty: response.data.summary.totalQty,
            delCharge: response.data.summary.totalDeliveryCharge,
            orderValue: response.data.summary.totalOrderAmount,
            totalAmount: response.data.summary.totalAmount,
            paymentType: ""
        })
    }

    reportRes.fields = ['#', ...response.data.reportHeader]

    return (reportRes)
}