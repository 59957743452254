import React, { useState, useEffect } from 'react'
import firebase from '../../fbConfig/fbConfig'
import MsgModal from '../../components/AlbsCmpMsgModal/AlbsCmpMsgModal'
import validator from 'validator'



const BackenduserGroupUpdate = (props) => {
    //Whole inputs
    const [inputs, setInputs] = useState({
        groupName: '',
        groupDescription: '',
        priv: []
    });

    //Circular progress bar on
    const [loading, setLoading] = useState(false);

    //Show user groups 
    const [userGroupsList, setUserGroupsList] = useState([]);

    const addNewUserGroups = () => {
        setUserGroupsList(() => []);
        firebase.firestore().collection('userGroups').get()
            .then((docs) => {
                docs.forEach((doc) => {
                    setUserGroupsList((current) => ([...current, { id: doc.id, group: doc.data().groupName, groupDescription: doc.data().groupDescription, priv: doc.data().priv }]))
                })
            })
    }

    const [adminPrivs, setAdminPrivs] = useState([]);

    //Get existing user privs to the grid
    const getuserPriv = () => {
        firebase.firestore().collection('userGroups').where('groupName', '==', 'Admin').get()
            .then((docs) => {
                docs.forEach((doc) => {
                    setAdminPrivs((current) => (doc.data().priv))
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    //Add user group details into text boxes
    const rowOnClick = (group, groupDescription, priv, id) => {
        setInputs((current) => ({ ...current, group, groupDescription, priv, id }));
        var newArr = []
        adminPrivs.forEach((element) => {
            if (priv.find(ele => ele.privCode == element.privCode) != undefined) {
                newArr.push({ ...element, selected: true })
            } else {
                newArr.push({ ...element, selected: false })
            }
        })
        setAdminPrivs([...newArr])
    }

    //Values change in text box
    const inputChange = (e) => {
        e.persist();
        setInputs((current) => ({ ...current, [e.target.name]: e.target.value }))
    }

    //Add priviladges to group when click the check box
    const checkBoxClick = (privCode, privDesc) => {
        let newArr = []
        adminPrivs.forEach(element => {
            if (element.privCode == privCode) {
                newArr.push({ ...element, selected: element.selected ? false : true })
            } else {
                newArr.push(element)
            }
        });
        setAdminPrivs(newArr)
    }

    const validationFunction = () => {
        if (inputs.group == '')
            return "Select user group first."
        if (!adminPrivs.find((ele) => ele.selected == true))
            return "User group must have at least one priviladge"
        return false
    }

    //Add data set into firebase
    const updateBtnHandler = () => {
        let message = validationFunction()
        if (!message) {
            notificationToggler('confirmation', 'Are you sure?', 'update');
        } else {
            notificationToggler('error', message, null);
        }
    }

    //Add data set into firebase
    const addDataToFirebase = () => {
        setLoading(true)
        firebase.firestore()
            .collection('userGroups')
            .doc(inputs.id)
            .set({ groupName: inputs.group, groupDescription: inputs.groupDescription, priv: [...adminPrivs.filter(ele => ele.selected == true).map(eleme => ({ privCode: eleme.privCode, privDesc: eleme.privDesc }))] })
            .then(() => {
                resetButtonHandler();
                notificationToggler('success', 'User has created successfuly.', null);
                setLoading(false)
            })
            .catch((err) => {
                notificationToggler('error', err.message, null);
                setLoading(false)
            })
    }

    //Reset all the fields 
    const resetButtonHandler = () => {
        setInputs({
            userName: '',
            userDescription: '',
            authKey: '',
            userMobile: '',
            email: '',
            userAddress1: '',
            userAddress2: '',
            userAddress3: '',
            group: '',
            groupDescription: ''
        });
        getuserPriv()
    }

    useEffect(() => {
        getuserPriv();
    }, [])


    ////////////////////////////////////////////////////////////////
    /**
     * 
     * @param {string} notificationType 
     * @param {string} message 
     * @param {string} crudOperationType 
     */
    const notificationToggler = (notificationType, message, crudOperationType) => {
        if (notificationType == null && message == null && crudOperationType == null) {
            setNotification((cur) => ({
                notificationShow: false,
                notification: message,
                confirmed: false,
                type: notificationType,
                crudOperationType: crudOperationType
            }))
        } else {
            setNotification((cur) => ({
                notificationShow: true,
                notification: message,
                confirmed: false,
                type: notificationType,
                crudOperationType: crudOperationType
            }))
        }

    }

    /**
     * Get Confirmation
     */
    const afterConfirm = async () => {
        switch (notifiaction.crudOperationType) {
            case 'read':
                break;
            case 'insert':
                break;
            case 'update':
                addDataToFirebase()
                break;
            case 'delete':
                break;
            default:
                break;
        }
    }

    //Notification details
    const [notifiaction, setNotification] = useState({
        notificationShow: false,
        notification: '',
        crudOperationType: '',
        type: ''
    })
    ////////////////////////////////////////////////////////////////



    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">User Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" >
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Add new user</div>
                                            <div class="card-category">Fill below fields to add a new user</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row py-3 ml-5 mr-5">
                                    {/* Category details */}
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Group Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Category name must be uniqe" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="group" value={inputs.group} readOnly />
                                        <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#searchModal" onClick={addNewUserGroups}><i class="fas fa-search"></i></button>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Group Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="groupDescription" value={inputs.groupDescription} readOnly />
                                    </div>

                                </div>
                                {/* User priv list */}
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="card-category mb-2">Priviladges</div>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">#</th>
                                                <th>Priviladge code</th>
                                                <th>Priviladge description</th>
                                                <th>Select</th>
                                            </tr>
                                            {
                                                (adminPrivs).map((priviladge, index) =>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{priviladge.privCode}</td>
                                                        <td>{priviladge.privDesc}</td>
                                                        <td><input type="checkbox" checked={priviladge.selected} onClick={() => checkBoxClick(priviladge.privCode, priviladge.privDesc ? priviladge.privDesc : "")} /></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div class="row py-3 ml-5 mr-5 justify-content-center">
                                    <button type="button" class="btn btn-secondary mr-2" onClick={resetButtonHandler}>Reset</button>
                                    <button type="button" class="btn btn-primary mr-2" onClick={updateBtnHandler}>Update User Group</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {Modal for search} */}
                <div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <table class="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <th scope="col text-left" >Group Name</th>
                                            <th scope="col text-left" >Group description</th>
                                        </tr>
                                        {userGroupsList.map((rows) =>
                                            <tr style={{ cursor: 'Pointer' }} data-toggle="modal" data-target="#searchModal" onClick={() => rowOnClick(rows.group, rows.groupDescription, rows.priv, rows.id)}>
                                                <td>{rows.group}</td>
                                                <td>{rows.groupDescription}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MsgModal
                show={notifiaction.notificationShow}
                onHide={() => notificationToggler(null, null, null)}
                message={notifiaction.notification}
                type={notifiaction.type}
                confirmBtnOnClick={afterConfirm}
                loading={loading}
            />
        </div>
    )
}

export default BackenduserGroupUpdate;