import React from 'react';
import './VaveBackendSlideBar.css'
import UserImage from '../../assets/images/admin.png'
import { useHistory, Link } from 'react-router-dom'
import { connect } from 'react-redux'

const VaveBackendSlideBar = (props) => {
    const history = useHistory();

    const prvCheck = (prvCode) => {
        return (props.userPrv.find((obj) => obj.privCode == prvCode))
    }

    return (
        <div className="sidebar" id="slBar" hidden={props.hidden}>
            <div className="sidebar-wrapper scrollbar scrollbar-inner">
                <div className="sidebar-content">
                    <div className="user">
                        <div className="avatar-sm float-left mr-2">
                            <img src={UserImage} alt="..." className="avatar-img rounded-circle" />
                        </div>
                        <div className="info">
                            <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                                <span>
                                    {props.userName}
                                    <span className="user-level">{props.userGroup}</span>
                                    <span className="caret"></span>
                                </span>
                            </a>
                            <div className="clearfix"></div>

                            <div className="collapse in" id="collapseExample">
                                <ul className="nav">
                                    <li>
                                        <Link to="/backendUserProfile">
                                            <span className="link-collapse">My Profile</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-primary">
                        {prvCheck("DB00100") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#dashboard" className="collapsed" aria-expanded="false">
                                    <i className="fas fa-home"></i>
                                    <p>Dashboard</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="dashboard">
                                    <ul className="nav nav-collapse">
                                        {prvCheck("DB00101") ?
                                            <li>
                                                <Link to="/dashboard1">
                                                    <span className="sub-item">Pickup and Delivery</span>
                                                </Link>
                                            </li> : <div></div>}
                                        {/* {prvCheck("DB00102") ?
                                            <li>
                                                <a href="../demo2/index.html">
                                                    <span className="sub-item">Retail Outlets</span>
                                                </a>
                                            </li> : <div></div>} */}
                                    </ul>
                                </div>
                            </li>
                            : <div></div>}

                        {prvCheck("DB00200") ?
                            <li className="nav-item ">
                                <a data-toggle="collapse" href="#reports" >
                                    <i className="fas fa-book-open"></i>
                                    <p>Reports</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="reports">
                                    <ul className="nav nav-collapse">
                                        {prvCheck("DB00201") ?
                                            <li className="active">
                                                <Link to="/backendReports">
                                                    <span className="sub-item">Pickup and Delivery</span>
                                                </Link>
                                            </li> : <div></div>}
                                        {/* {prvCheck("DB00202") ?
                                            <li>
                                                <a href="compact-sidebar.html">
                                                    <span className="sub-item">Retail outlets</span>
                                                </a>
                                            </li> : <div></div>} */}
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00300") ?
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fas fa-toolbox"></i>
                                </span>
                                <h4 className="text-section">Utilities</h4>
                            </li>
                            : <div></div>}
                        {prvCheck("DB00301") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#customer">
                                    <i className="fas fa-user-tie"></i>
                                    <p>Customer</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="customer">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <Link to="/backendNewCustomers">
                                                <span className="sub-item">Add new customer</span>
                                            </Link>

                                        </li>
                                        <li>
                                            <Link to="/backendEditCustomers">
                                                <span className="sub-item">Edit customer details</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}
                        {prvCheck("DB00302") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#items">
                                    <i className="fas fa-tags"></i>
                                    <p>Items</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="items">
                                    <ul className="nav nav-collapse">
                                        <li className="active">
                                            <Link to="/createItems">
                                                <span className="sub-item">Add new item</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/updateItems">
                                                <span className="sub-item">Edit item</span>
                                            </Link>

                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00400") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#locations">
                                    <i className="fas fa-map-marked-alt"></i>
                                    <p>Locations</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="locations">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <Link to="/newLocation">
                                                <span className="sub-item">Add new location</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/locationUpdate">
                                                <span className="sub-item">Update location</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}
                        {prvCheck("DB00500") ?
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fas fa-wrench"></i>
                                </span>
                                <h4 className="text-section">System settings</h4>
                            </li> : <div></div>}

                        {prvCheck("DB00501") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#user">
                                    <i className="fas fa-user"></i>
                                    <p>Users</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="user">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <Link to="/backendNewUsers">
                                                <span className="sub-item">Add new user</span>
                                            </Link>

                                        </li>
                                        <li>
                                            <Link to="/backendEditUsers">
                                                <span className="sub-item">Edit existing user</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00502") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#userGroups">
                                    <i className="fas fa-users"></i>
                                    <p>User Groups</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="userGroups">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <Link to="/backendUserGroups">
                                                <span className="sub-item">Create new user group</span>
                                            </Link>

                                        </li>
                                        <li>
                                            <Link to="/backendUserGroupUpdate">
                                                <span className="sub-item">Update existing user groups</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00600") ?
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fas fa-wrench"></i>
                                </span>
                                <h4 className="text-section">Operations</h4>
                            </li> : <div></div>}

                        {prvCheck("DB00601") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#pickupDeliveries">
                                    <i className="fa fa-truck" aria-hidden="true"></i>
                                    <p>Pickup and delivery</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="pickupDeliveries">
                                    <ul className="nav nav-collapse">
                                        {prvCheck("DB00602") ?
                                            <li>
                                                <Link to="/backendPickups">
                                                    <span className="sub-item">Pickups</span>
                                                </Link>
                                            </li>
                                            : null}
                                        {prvCheck("DB00603") ?
                                            <li>
                                                <Link to="/backendDelivery">
                                                    <span className="sub-item">Deliveries</span>
                                                </Link>
                                            </li>
                                            : null}
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00604") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#routePlan">
                                    <i className="fa fa-road" ></i>
                                    <p>Route Plane</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="routePlan">
                                    <ul className="nav nav-collapse">
                                        <li>
                                            <Link to="/pickupAllocation">
                                                <span className="sub-item">Pickup Allocation</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/deliveryAllocation">
                                                <span className="sub-item">Delivery Allocation</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> : <div></div>}

                        {prvCheck("DB00700") ?
                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fas fa-wrench"></i>
                                </span>
                                <h4 className="text-section">Orders</h4>
                            </li> : <div></div>}
                        {prvCheck("DB00701") ?
                            <li className="nav-item">
                                <a data-toggle="collapse" href="#onCallOrders">
                                    <i className="fas fa-headset" aria-hidden="true"></i>
                                    <p>On Call Orders</p>
                                    <span className="caret"></span>
                                </a>
                                <div className="collapse" id="onCallOrders">
                                    <ul className="nav nav-collapse">
                                        {prvCheck("DB00702") ?
                                            <li>
                                                <Link to="/backendOnCallPd">
                                                    <span className="sub-item">Pickup and Delivery</span>
                                                </Link>
                                            </li> : <div></div>}
                                        {prvCheck("DB00703") ?
                                            <li>
                                                <Link to="/backendOnCallDeliveryHd">
                                                    <span className="sub-item">Home Delivery only</span>
                                                </Link>
                                            </li> : <div></div>}
                                    </ul>
                                </div>
                            </li> : <div></div>}
                    </ul>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    return {
        userPrv: state.userDetails.userPrv,
        userName: state.userDetails.userName,
        userGroup: state.userDetails.group,
        sdTogle: state.isSlideDrawerOpen
    }
}

export default connect(mapStateToProps)(VaveBackendSlideBar);