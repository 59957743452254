import React, { useState, useEffect } from 'react'
import { Line, Pie, Doughnut } from 'react-chartjs-2'


const BackendDashBoard = props => {
    const dailySaleData = {
        labels: [
            '27',
            '28',
            '29',
            '30',
            '31'
        ],
        datasets: [
            {
                label: 'This month',
                data: [3, 1, 2, 5, 4],
                backgroundColor: 'rgba(253, 97, 48, 0.5)'


            },
            {
                label: 'Last Month',
                data: [5, 7, 1, 2, 4,],
                backgroundColor: 'rgba(18, 105, 215,0.5)'
            },
            {
                label: 'Last Year this period',
                data: [10, 8, 5, 12, 7,],
                backgroundColor: 'rgba(30, 30, 30,0.5)'
            }
        ]
    }

    const dailySalesByWashType = {
        labels: [
            'DryCleaning',
            'Washing',
            'Pressing'
        ],
        datasets: [
            {
                label: 'This month',
                data: [30, 40, 30],
                backgroundColor: ['rgba(253, 97, 48, 0.5)', 'rgba(50, 97, 48, 0.5)', 'rgba(100, 50, 150, 0.5)']
            },
        ]
    }
    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">Dashboard</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner">
                <div class="row mt--5">
                    <div class="col-sm-6 col-md-4 mt-5">
                        <div class="card card-stats card-success card-round">
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-analytics"></i>
                                        </div>
                                    </div>
                                    <div class="col-7 col-stats">
                                        <div class="numbers">
                                            <p class="card-category">Sales</p>
                                            <h4 class="card-title">$ 1,345</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-stats card-primary card-round">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-users"></i>
                                        </div>
                                    </div>
                                    <div class="col-7 col-stats">
                                        <div class="numbers">
                                            <p class="card-category">Visitors</p>
                                            <h4 class="card-title">1,294</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-stats card-secondary card-round">
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="icon-big text-center">
                                            <i class="flaticon-success"></i>
                                        </div>
                                    </div>
                                    <div class="col-7 col-stats">
                                        <div class="numbers">
                                            <p class="card-category">Pcs</p>
                                            <h4 class="card-title">1240</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card" >
                            <div class="card-header">
                                <div class="card-title">Sale by Wash type</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">
                                    <Pie data={dailySalesByWashType} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card" >
                            <div class="card-header">
                                <div class="card-title">Sale by Wash type</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">
                                    <Pie data={dailySalesByWashType} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4 ">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Daily Sales</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">
                                    <Line data={dailySaleData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Stock flow</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">
                                    <Doughnut data={dailySalesByWashType} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Daily Collections</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Top Items by Sale</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">
                                    <Pie data={dailySalesByWashType} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Top customers by Sale</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Top Outlets by Sale</div>
                            </div>
                            <div class="card-body">
                                <div class="chart-container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackendDashBoard;