import firebase from '../../../fbConfig/fbConfig'


export const rep_ordersPD = async (inputs) => {
    var reportRes = {
        fields: ['#', 'Order ID', 'Order Placed Date', 'Pickup Date', 'Customer Name', 'Telephone', 'City', 'Total Amount'],
        data: []
    };
    var beginningDateObject2 = new Date(inputs.dateRange.startDate);
    var beginningDateObject = new Date(inputs.dateRange.endDate);
    if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
        beginningDateObject.setHours(23);
        beginningDateObject.setMinutes(59);
        beginningDateObject.setSeconds(59);
    }
    const res = await firebase.firestore().collection('order').where('orderDate', '>', beginningDateObject2).where('orderDate', '<', beginningDateObject).get()
    for (let a = 0; a < res.docs.length; a++) {
        if (res.docs[a].data().generatedId != undefined) {
            console.log(res.docs[a].data().pickupDate.toDate());
            var d = res.docs[a].data().orderDate.toDate();
            var pd = res.docs[a].data().pickupDate.toDate();
            reportRes.data.push({
                CO_Number: res.docs[a].data().generatedId,
                orderDate: d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
                pickupDate: pd.getDate() + '/' + (pd.getMonth() + 1) + '/' + pd.getFullYear(),
                name: res.docs[a].data().name,
                phoneNumber: res.docs[a].data().phoneNumber,
                city: res.docs[a].data().city,
                subtot: (parseFloat(res.docs[a].data().subtot) + parseFloat(res.docs[a].data().delCharge)).toFixed(2)
            })
        }
    }

    let total = 0.00;
    reportRes.data.forEach(element => {
        console.log(element.subtot);
        if (element.subtot != undefined)
            total = parseFloat(total) + parseFloat(element.subtot);
    });
    if (total != 0.00) {
        reportRes.data.push({
            CO_Number: 'Total',
            orderDate: '',
            pickupDate: '',
            name: '',
            item: '',
            quantity: '',
            subtot: (total).toFixed(2)
        })
    }


    return (reportRes)
}