import React, {useEffect, useRef, useState} from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-widgets/dist/css/react-widgets.css";
import Card from "../../components/VaveCard/CmpGenCard/CmpGenCard";
import SettingsBar from "../../components/VaveCmpSettingsBar/VaveCmpSettingsBar";
import firebase from "../../fbConfig/fbConfig";
import "./BackendReports.css";
import {rep_CompletedDeliverySummery} from "./Reports/rep_CompletedDeliverySummery";
import {rep_CompletedPickupSummary} from "./Reports/rep_CompletedPickupSummary";
import {rep_CompletedPickupSummaryFbBased} from "./Reports/rep_CompletedPickupSummaryFbBased";
import {rep_customerDetails} from "./Reports/rep_customerDetails";
import {rep_DailyDeliveriesStUse} from "./Reports/rep_DailyDeliveriesStUse";
import {rep_dailyHomeDeliverySummery} from "./Reports/rep_dailyHomeDeliverySummery";
import {rep_dailyPickupDetails} from "./Reports/rep_dailyPickupDetails";
import {rep_dailyPickupSummery} from "./Reports/rep_dailyPickupSummery";
import {rep_ordersHd} from "./Reports/rep_ordersHd";
import {rep_ordersPD} from "./Reports/rep_ordersPD";
import {rep_outstandingDeliveries} from "./Reports/rep_outstandingDeliveries";
import {rep_outstandingPickups} from "./Reports/rep_outstandingPickups";
import {rep_pkDetails} from "./Reports/rep_pkDetails";
import {rep_rejectedPickups} from "./Reports/rep_rejectedPickups";
import {rep_stockDetails} from "./Reports/rep_stockDetails";
import {rep_upcoming_visits} from "./Reports/rep_upcoming_visits";

const BackendReport = () => {
    //Loading circle
    const [loading, setLoading] = useState(false);

    //Initial details
    const [initDet, setInitDet] = useState({
        location: [],
        washType: [],
        reportName: [],
    });

    //Get inputs from report customize section
    const [inputs, setInputs] = useState({
        reportName: "",
        orderNo: "",
        cmName: "",
        cmTell: "",
        location: "",
        washType: "",
        dateRange: {
            startDate: "",
            endDate: "",
        },
        searchBy: "",
    });

    //Overall report
    const [report, setReport] = useState({
        data: [],
        fields: [],
    });

    //Load details into the report customizer
    useEffect(() => {
        firebase
            .firestore()
            .collection("reportsCollection")
            .where("activated", "==", true)
            .get()
            .then((snap) => {
                const arr = [];
                snap.forEach((doc) => {
                    arr.push(doc.data().repName);
                });
                setInitDet((current) => ({...current, reportName: arr.sort()}));
            })
            .catch((error) => console.log(error));
    }, []);

    //On search button click
    const generateReport = () => {
        setLoading(true);
        switch (inputs.reportName) {
            case "Customer details":
                rep_customerDetails(inputs).then((res) => {
                    setReport((current) => ({
                        fields: res.fields,
                        data: res.data,
                    }));
                    setRepFilter((current) => ({data: res.data}));
                    setLoading(false);
                });
                break;

            case "Completed Pickup Summary - MyPOS based":
                rep_CompletedPickupSummary(inputs).then((res) => {
                    setReport((current) => ({
                        fields: res.fields,
                        data: res.data,
                    }));
                    setRepFilter((current) => ({data: res.data}));
                    setLoading(false);
                });
                break;

            case "Completed Pickup Summary - Online portal based":
                rep_CompletedPickupSummaryFbBased(inputs).then((res) => {
                    setReport((current) => ({
                        fields: res.fields,
                        data: res.data,
                    }));
                    setRepFilter((current) => ({data: res.data}));
                    setLoading(false);
                });
                break;

            case "Home Delivery Summery":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_dailyHomeDeliverySummery(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Pickup and Home Delivery Summery":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_dailyPickupSummery(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Pickup and Home Delivery Details":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_dailyPickupDetails(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Outstanding Pickups":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_outstandingPickups(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Rejected Pickups":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_rejectedPickups(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Online Delivery Orders - HD":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_ordersHd(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Online Delivery Orders - PD":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_ordersPD(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Packing Report":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_pkDetails(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Outstanding Deliveries":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_outstandingDeliveries(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Completed Deliveries Summery":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_CompletedDeliverySummery(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Stock Details":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_stockDetails(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Daily Deliveries - Garment Store Use":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_DailyDeliveriesStUse(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            case "Upcoming Pickups and Deliveries":
                if (
                    inputs.dateRange.endDate != "" ||
                    inputs.dateRange.startDate != ""
                ) {
                    rep_upcoming_visits(inputs).then((res) => {
                        setReport((current) => ({
                            ...current,
                            fields: res.fields,
                            data: res.data,
                        }));
                        setRepFilter((current) => ({...current, data: res.data}));
                        setLoading(false);
                    });
                } else {
                    alert("Select proper date range");
                    setLoading(false);
                }
                break;

            default:
                setLoading(false);
                break;
        }
    };

    //Values change in text box & drop downs
    const inputChange = (e) => {
        e.persist();
        setInputs((current) => ({...current, [e.target.name]: e.target.value}));
    };

    //Custom Input for date picker
    const ExampleCustomInput = ({value, onClick}) => (
        <div class="input-group" onClick={onClick}>
            <div class="input-group mr-2">
                <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fa fa-calendar"></i>
          </span>
                </div>
                <input
                    type="text"
                    class="form-control"
                    placeholder="Dates"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={value}
                />
            </div>
        </div>
    );

    //Filtered Data
    const [repFilter, setRepFilter] = useState({
        data: [],
    });

    //Search Function
    const searchFunction = (e) => {
        setRepFilter((current) => ({
            data: report.data.filter(
                (data) =>
                    JSON.stringify(data).toLowerCase().indexOf(e.toLowerCase()) !== -1
            ),
        }));
    };

    //Show search results in the main table
    useEffect(() => {
        searchFunction(inputs.searchBy);
    }, [inputs.searchBy]);

    const pageStyle = `@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: 210mm 297mm;
    margin-top: 5mm;
  }`;

    const componentRef = useRef();

    return (
        <div className="container-fluid" style={{marginTop: "100px"}}>
            <Card>
                <SettingsBar
                    title="Reports"
                    description="Online delivery portal report section"
                    fafaIcon="fa fa-flag"
                    search
                    searchButtonOnClick={() => generateReport()}
                    spinTogglar={loading}
                    print
                    pageStyle={pageStyle}
                    componentRef={componentRef.current}
                />
                <hr className="mt-2" style={{borderColor: "#707070"}}/>
                <div className="row m-1 col-lg-8 col-md-12">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <label class="input-group-text" for="inputGroupSelect01">
                                Select Report
                            </label>
                        </div>
                        <select
                            class="custom-select"
                            id="inputGroupSelect01"
                            name="reportName"
                            onChange={inputChange}
                        >
                            <option selected={!inputs.reportName} value={0}>
                                Choose...
                            </option>
                            {initDet.reportName.map((reportName, index) => (
                                <option
                                    selected={inputs.reportName == reportName}
                                    key={index}
                                    value={reportName}
                                >
                                    {reportName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row m-1 mt-2 justify-content-start col-lg-8 col-md-12">
                    <DatePicker
                        selected={inputs.dateRange.startDate}
                        onChange={(value) =>
                            setInputs((current) => ({
                                ...current,
                                dateRange: {
                                    ...current.dateRange,
                                    startDate: value,
                                },
                            }))
                        }
                        customInput={<ExampleCustomInput/>}
                        dateFormat="MMMM d, yyyy"
                    />
                    <DatePicker
                        selected={inputs.dateRange.endDate}
                        onChange={(value) =>
                            setInputs((current) => ({
                                ...current,
                                dateRange: {
                                    ...current.dateRange,
                                    endDate: value,
                                },
                            }))
                        }
                        customInput={<ExampleCustomInput/>}
                        dateFormat="MMMM d, yyyy"
                    />
                </div>
                <div class="input-group ml-1 mt-2 col-lg-8 col-md-12">
                    <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              Search
            </span>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        aria-label="Text input with dropdown button"
                        name="searchBy"
                        value={inputs.searchBy}
                        onChange={inputChange}
                    />
                </div>
            </Card>
            {report.data.length != 0 && (
                <Card>
                    <div ref={componentRef}>
                        <div className="w-100 my-2 ">
                            <p
                                style={{
                                    fontSize: 20,
                                    color: "Background",
                                    fontWeight: "bold",
                                }}
                            >
                                {inputs.reportName}
                            </p>
                            <label>
                                Date Range :{" "}
                                {inputs.dateRange.startDate.toString().split("G")[0]}-
                                {inputs.dateRange.endDate.toString().split("G")[0]}
                            </label>
                            <br/>
                            <label>
                                Created Date : {new Date().toString().split("G")[0]}
                            </label>
                            <br/>
                            <label>Created By : {firebase.auth().currentUser.email}</label>
                            <br/>
                        </div>
                        <hr></hr>
                        <table className="table-hover table-sm" style={{width: "100%"}}>
                            <tbody>
                            <tr>
                                {report.fields.map((field, index) => (
                                    <th
                                        key={index}
                                        style={{
                                            border: "solid 1px #D9D9D9",
                                            borderRadius: "50px",
                                        }}
                                    >
                                        {field}
                                    </th>
                                ))}
                            </tr>
                            {repFilter.data.map((data, index) => (
                                <tr key={index}>
                                    <td
                                        style={{
                                            border: "solid 1px #D9D9D9",
                                            borderRadius: "50px",
                                        }}
                                    >
                                        {index + 1}
                                    </td>
                                    {Object.entries(data).map((cell, index) => (
                                        <td
                                            key={index}
                                            style={{
                                                border: "solid 1px #D9D9D9",
                                                borderRadius: "50px",
                                            }}
                                        >
                                            {cell[1]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default BackendReport;
