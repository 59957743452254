import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import VaveBackendSlideBar from "./components/VaveBackendSlideBar/VaveBackendSlideBar";
import VaveBackEndNavBar from "./components/VaveBackEndNavBar/VaveBackEndNavBar";
import VaveBackEndLocations from "./Pages/BackendLocations/BackendLocations";
import BackendDashBoard from "./Pages/BackendDashBoard/BackendDashBoard";
import BackendCreateItems from "./Pages/BackendItems/BackendCreateItems";
import BackendUpdateItems from "./Pages/BackendItems/BackendUpdateItems";
import BackendNewCustomers from "./Pages/BackendCustomerDetails/BackendNewCustomers";
import BackendEditCustomers from "./Pages/BackendCustomerDetails/BackendEditCustomers";
import BackendNewUsers from "./Pages/BackendUsers/BackendNewUsers";
import BackendEditUsers from "./Pages/BackendUsers/BackendEditUsers";
import BackendUserProfile from "./Pages/BackendUserProfile/BackendUserProfile";
import BackendReports from "./Pages/BackendReports/BackendReports";
import SignUpForm from "./Pages/BackendSignUp/BackendSignUp";
import LoginScreen from "./Pages/BackendLogin/BackendLogin";
import BackendDelivery from "./Pages/BackendDeliveryOperation/BackendDelivery";
import firebase from "./fbConfig/fbConfig";
import BackendPickups from "./Pages/BackendPickupsOperation/BackendPickups";
import BackendUserGroups from "./Pages/BackendUsers/BackenduserGroups";
import BackenduserGroupUpdate from "./Pages/BackendUsers/BackenduserGroupUpdate";
import VavePgsOnCallHd from './Pages/BackendOnCallHd/VavePgsOnCallHd/VavePgsOnCallHd'
import VavePgsOnCallPd from './Pages/BackendOnCallPd/VavePgsOnCallPd'
import PickupAllocation from './Pages/BackendPickupAllocation/PickupAllocation'
import DeliveryAllocation from './Pages/BackendDeliveryAllocation/BackendDeliveryAllocation'

const App = (props) => {
  const changeUserDetails = (userEmail) => {
    if (userEmail != null) {
      firebase
        .firestore()
        .collection("user")
        .where("email", "==", userEmail)
        .get()
        .then((results) => {
          firebase
            .firestore()
            .collection("userGroups")
            .where("groupName", "==", results.docs[0].data().group)
            .get()
            .then((groupDet) => {
              props.activeBackendHeader();
              props.changeUser(
                results.docs[0].data().userName,
                results.docs[0].data().email,
                results.docs[0].data().group,
                groupDet.docs[0].data().priv,
                groupDet.docs[0].data().groupDescription,
                results.docs[0].data().userMobile,
                results.docs[0].data().userAddress1,
                results.docs[0].data().userAddress2,
                results.docs[0].data().userAddress3,
                results.docs[0].data().userDescription,
                results.docs[0].data().userProfilePic
              );
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const history = useHistory()

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        console.log(firebase.auth().currentUser.email);
        changeUserDetails(firebase.auth().currentUser.email);
        history.push('/backendUserProfile')
      }
    })

  }, []);

  return (
    <div className="wrapper">
      <VaveBackendSlideBar hidden={props.isFrontEnd} />
      <VaveBackEndNavBar hidden={props.isFrontEnd} />
      <div className="main-panel" style={{ width: props.isFrontEnd ? '100%' : '' }}>
        <div className="content" style={{ marginTop: props.isFrontEnd ? '0px' : '', overflow: 'unset' }}>
          <Switch>
            <Route path="/" exact>
              <LoginScreen />
            </Route>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <Route path="/signUp">
              <SignUpForm />
            </Route>
            <Route path="/locationUpdate">
              <VaveBackEndLocations isUpdate={true} isNewLocation={false} />
            </Route>
            <Route path="/newLocation">
              <VaveBackEndLocations isUpdate={false} isNewLocation={true} />
            </Route>
            <Route path="/dashboard1">
              <BackendDashBoard />
            </Route>
            <Route path="/createItems">
              <BackendCreateItems />
            </Route>
            <Route path="/updateItems">
              <BackendUpdateItems />
            </Route>
            <Route path="/backendNewCustomers">
              <BackendNewCustomers />
            </Route>
            <Route path="/backendEditCustomers">
              <BackendEditCustomers />
            </Route>
            <Route path="/backendNewUsers">
              <BackendNewUsers />
            </Route>
            <Route path="/backendEditUsers">
              <BackendEditUsers />
            </Route>
            <Route path="/backendUserProfile">
              <BackendUserProfile />
            </Route>
            <Route path="/backendReports">
              <BackendReports />
            </Route>
            <Route path="/backendPickups">
              <BackendPickups />
            </Route>
            <Route path="/backendDelivery">
              <BackendDelivery />
            </Route>
            <Route path="/backendUserGroups">
              <BackendUserGroups />
            </Route>
            <Route path="/backendUserGroupUpdate">
              <BackenduserGroupUpdate />
            </Route>
            <Route path="/backendOnCallDeliveryHd">
              <VavePgsOnCallHd />
            </Route>
            <Route path="/backendOnCallPd">
              <VavePgsOnCallPd />
            </Route>
            <Route path="/pickupAllocation">
              <PickupAllocation />
            </Route>
            <Route path="/deliveryAllocation">
              <DeliveryAllocation />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isFrontEnd: state.isFrontEnd };
};

const mapDespatchToProps = (dispatch) => {
  return {
    activeBackendHeader: () => dispatch({ type: "ISFRONTEND", val: false }),
    changeUser: (
      userName,
      email,
      group,
      userPrv,
      groupDescription,
      userMobile,
      userAddress1,
      userAddress2,
      userAddress3,
      userDescription,
      userProfilePic
    ) =>
      dispatch({
        type: "CHANGEUSER",
        userName,
        email,
        group,
        userPrv,
        groupDescription,
        userMobile,
        userAddress1,
        userAddress2,
        userAddress3,
        userDescription,
        userProfilePic,
      }),
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(App);
