import firebase from '../../../fbConfig/fbConfig'


export const rep_outstandingPickups = async (inputs) => {
    var reportRes = {
        fields: ['#', 'Order ID', 'Order Placed Date', 'Pickup Date', 'Customer Name', 'Telephone', 'City','Delivery Charge','Order Amount', 'Total Amount'],
        data: []
    };
    var beginningDateObject2 = new Date(inputs.dateRange.startDate);
    var beginningDateObject = new Date(inputs.dateRange.endDate);
    if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
        beginningDateObject.setHours(23);
        beginningDateObject.setMinutes(59);
        beginningDateObject.setSeconds(59);
    }

    const res = await firebase.firestore().collection('order').where('pickupDate', '>=', beginningDateObject2).where('pickupDate', '<', beginningDateObject).get()
    for (let a = 0; a < res.docs.length; a++) {
        if ((res.docs[a].data().pickupComplete == undefined || res.docs[a].data().pickupRejected != undefined) && res.docs[a].data().payment != "Pending" && res.docs[a].data().generatedId != undefined) {
            var d = res.docs[a].data().orderDate.toDate();
            var p = res.docs[a].data().pickupDate.toDate();
            reportRes.data.push({
                CO_Number: res.docs[a].data().generatedId,
                orderDate: d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
                pickupDate: p.getDate() + '/' + (p.getMonth() + 1) + '/' + p.getFullYear(),
                name: res.docs[a].data().name,
                phoneNumber: res.docs[a].data().phoneNumber,
                city: res.docs[a].data().city,
                delCharge: parseFloat(res.docs[a].data().delCharge).toFixed(2),
                subtot: parseFloat(res.docs[a].data().subtot),
                totalAmount: (parseFloat(res.docs[a].data().subtot) + parseFloat(res.docs[a].data().delCharge)).toFixed(2)
            })
        }
    }

    let total = 0.00;
    reportRes.data.forEach(element => {
        if (element.delCharge != undefined)
            total = parseFloat(total) + parseFloat(element.delCharge);
    });
    if (total != 0.00) {
        reportRes.data.push({
            CO_Number: 'Total',
            orderDate: '',
            pickupDate: '',
            name: '',
            phoneNumber: '',
            city: '',
            delCharge: '',
            subtot: '',
            totalAmount: total.toFixed(2)
        })
    }

    return (reportRes)
}