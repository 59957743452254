import React, { Component } from 'react'
import RRibbon from '../../../components/VaveCmpRedRibbon/VaveCmpRedRibbon'
import VaveCard from '../../../components/AlbsCmpCard/CmpGenCard/CmpGenCard'
import OrderSection from '../VaveContOrderSelection/OrderSelectionPage'
import BootstrapTxt from '../../../components/VaveTextInput/BootstrapTxtInput/BootstrapTxtInput'
import Axios from 'axios'
import MsgModal from '../../../components/AlbsCmpMsgModal/AlbsCmpMsgModal'
import firebase from "../../../fbConfig/fbConfig";
import { DropdownList } from "react-widgets";

const notification = {
    notificationShow: false,
    notification: '',
    crudOperationType: '',
    type: ''
}
class VavePgsOnCallHd extends Component {

    state = {
        inputs: {
            id: '',
            name: '',
            phoneNumber: '',
            email: '',
            houseNo: '',
            street: '',
            city: '',
            landmark: '',
            customerRefId: null
        },
        notification,
        cusMobile: '',
        locations: [],
        locMobile: false,
        loading: false
    }


    componentDidMount() {
        this.getLocations();

    }

    /**
    * Update state.inputs when change the input fields
    */
    addInputs = (key, val) => {
        this.setState((cur) => ({ ...cur, inputs: { ...cur.inputs, [key]: val.toString() } }))
    }

    /**
     * refresh page
     */
    refreshPage = () => {
        this.setState(() => ({
            inputs: {
                id: '',
                name: '',
                phoneNumber: '',
                email: '',
                houseNo: '',
                street: '',
                city: '',
                landmark: '',
                customerRefId: null
            },
            cusMobile: '',
            locations: [],
            locMobile: false,
            loading: false
        }))
        this.getLocations();
    }

    /**
     * Get all the delivery locaitons
     */
    getLocations = () => {
        firebase
            .firestore()
            .collection("location")
            .get()
            .then((snap) => {
                const arr = [];
                snap.forEach((doc) => {
                    arr.push(doc.data().city);
                });
                this.setState((cur) => ({ ...cur, locations: arr.sort() }))
            })
            .catch((error) => console.log(error));
    }

    /**
     * Get customer details from mobile number
     */
    getCustomerDetails = () => {
        this.setState((cur) => ({ ...cur, loading: true }))
        this.notificationToggler('information', '', '')
        Axios.get('/customer/tellNo/' + this.state.inputs.phoneNumber)
            .then((result) => {
                if (result.data.status != "No Data") {
                    this.setState((cur) => ({
                        ...cur,
                        inputs: {
                            ...cur.inputs,
                            id: result.data.data.CM_CODE,
                            name: result.data.data.CM_NAME,
                        },
                        cusMobile: cur.inputs.phoneNumber
                    }))
                    firebase
                        .firestore()
                        .collection("customer")
                        .where("phoneNumber", "==", this.state.inputs.phoneNumber)
                        .get()
                        .then((res) => {
                            if (res.docs.length > 0) {
                                this.setState((cur) => ({
                                    ...cur,
                                    inputs: {
                                        ...cur.inputs,
                                        id: result.data.data.CM_CODE,
                                        name: result.data.data.CM_NAME,
                                        email: res.docs[0].data().email,
                                        houseNo: res.docs[0].data().houseNo,
                                        street: res.docs[0].data().street,
                                        city: res.docs[0].data().city,
                                        customerRefId: res.docs[0].id
                                    },
                                    cusMobile: cur.inputs.phoneNumber
                                }))
                            }
                        })
                    this.setState(cur => ({ ...cur, locMobile: true }))
                    this.setState((cur) => ({ ...cur, loading: false }))
                    this.notificationToggler(null, null, null)
                } else {
                    firebase
                        .firestore()
                        .collection("customer")
                        .where("phoneNumber", "==", this.state.inputs.phoneNumber)
                        .get()
                        .then((res) => {
                            if (res.docs.length > 0) {
                                this.setState((cur) => ({
                                    ...cur,
                                    inputs: {
                                        ...cur.inputs,
                                        id: res.docs[0].id,
                                        name: res.docs[0].data().name,
                                        email: res.docs[0].data().email,
                                        houseNo: res.docs[0].data().houseNo,
                                        street: res.docs[0].data().street,
                                        city: res.docs[0].data().city,
                                        customerRefId: res.docs[0].id
                                    },
                                    cusMobile: cur.inputs.phoneNumber
                                }))
                                this.setState(cur => ({ ...cur, locMobile: true }))
                                this.setState((cur) => ({ ...cur, loading: false }))
                                this.notificationToggler(null, null, null)

                            } else {
                                this.setState((cur) => ({ ...cur, loading: false }))
                                this.notificationToggler('error', 'No customers from this number.', null);
                            }
                        }).catch((err) => {
                            this.setState((cur) => ({ ...cur, loading: false }))
                            this.notificationToggler('error', err.message, null);
                        })

                }
            })
            .catch((error) => {
                this.setState((cur) => ({ ...cur, loading: false }))
                this.notificationToggler('error', error.message, null);
            })
    }

    ////////////////////////////////////////////////////////////////
    /**
     * 
     * @param {string} notificationType 
     * @param {string} message 
     * @param {string} crudOperationType 
     */
    notificationToggler = (notificationType, message, crudOperationType) => {
        if (notificationType == null && message == null && crudOperationType == null) {
            this.setState((cur) => ({
                ...cur,
                notification: {
                    notificationShow: false,
                    notification: message,
                    confirmed: false,
                    type: notificationType,
                    crudOperationType: crudOperationType
                }
            }))
        } else {
            this.setState((cur) => ({
                ...cur,
                notification: {
                    notificationShow: true,
                    notification: message,
                    confirmed: false,
                    type: notificationType,
                    crudOperationType: crudOperationType
                }
            }))
        }
    }

    /**
     * Get Confirmation
     */
    afterConfirm = async () => {
        switch (this.state.notification.crudOperationType) {
            case 'read':
                break;
            case 'insert':
                if (await this.createOrderTransaction()) {
                    this.refreshPage()
                }
                break;
            case 'update':
                break;
            case 'delete':
                break;
            default:
                break;
        }
    }

    ////////////////////////////////////////////////////////////////

    render() {
        return (
            <div className="w-100">
                <RRibbon Title="On Call Home Delivery" icon="fas fa-truck" />
                <div className='row justify-content-center no-gutters w-100' >
                    <VaveCard show={this.state.header}>
                        <div className='row'>
                            <div className='col-12 col-md-12'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-1 text-center'>
                                        <i className="fas fa-file-contract fa-2x"></i>
                                    </div>
                                    <div className='col'>
                                        <div className="mb-1">
                                            <b>Fill below header</b>
                                        </div>
                                    </div>
                                    <div className='col text-right'>
                                        <button className="btn btn-primary btn-sm my-sm-1" type="submit" onClick={this.refreshPage}>Refresh</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-2" style={{ borderColor: "#707070" }} />
                        <div className='row w-100'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12'>
                                <div className='mb-3'>Customer Details</div>
                                <BootstrapTxt
                                    placeHolder='Enter and search'
                                    prependText='Mobile'
                                    value={this.state.inputs.phoneNumber}
                                    onChange={(e) => this.addInputs('phoneNumber', e.target.value)}
                                    appendShow
                                    appendOnClick={this.getCustomerDetails}
                                    readOnly={this.state.locMobile}
                                    onKeyDown={(e) => e.keyCode === 13 ? this.getCustomerDetails() : null}
                                />
                                <BootstrapTxt
                                    readOnly={!this.state.locMobile}
                                    placeHolder='Customer Name'
                                    prependText='Customer Name'
                                    value={this.state.inputs.name}
                                    onChange={(e) => this.addInputs('name', e.target.value)}
                                />
                                <BootstrapTxt
                                    readOnly={!this.state.locMobile}
                                    placeHolder='Email'
                                    prependText='Email'
                                    value={this.state.inputs.email}
                                    onChange={(e) => this.addInputs('email', e.target.value)}
                                />
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12'>
                                <div className='mb-3'>Delivery Address</div>
                                <BootstrapTxt
                                    readOnly={!this.state.locMobile}
                                    placeHolder='No.'
                                    prependText='No.'
                                    value={this.state.inputs.houseNo}
                                    onChange={(e) => this.addInputs('houseNo', e.target.value)}
                                />
                                <BootstrapTxt
                                    readOnly={!this.state.locMobile}
                                    placeHolder='Street Name'
                                    prependText='Street Name'
                                    value={this.state.inputs.street}
                                    onChange={(e) => this.addInputs('street', e.target.value)}
                                />
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend d-none d-md-block">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">City</span>
                                    </div>
                                    <DropdownList
                                        readOnly={!this.state.locMobile}
                                        value={this.state.inputs.city}
                                        onChange={(city) => {
                                            this.setState((cur) => ({ ...cur, inputs: { ...cur.inputs, city: city } }));
                                        }}
                                        filter
                                        data={this.state.locations}
                                        style={{ width: "60%", height: '31px', fontSize: '13px' }}
                                        placeholder="City"
                                    />
                                </div>
                            </div>
                        </div>
                    </VaveCard>
                </div>
                <VaveCard show>
                    <OrderSection refreshPage={this.refreshPage} inputs={this.state.inputs} location={this.state.inputs.city} cusMobile={this.state.cusMobile} />
                </VaveCard>
                <MsgModal
                    show={this.state.notification.notificationShow}
                    onHide={() => this.notificationToggler(null, null, null)}
                    message={this.state.notification.notification}
                    confirmBtnOnClick={this.afterConfirm}
                    type={this.state.notification.type}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default VavePgsOnCallHd