import React from 'react'
import DateTime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import moment from 'moment';

const AlbsCmpDatePicker = props => {
    return (
        <div className="input-group input-group-sm mb-2">
            <div className="input-group-prepend d-none d-lg-block">
                <span className="input-group-text justify-content-center" style={{ width: props.width, fontSize: "14px" }} id={props.id}>{props.InputName}</span>
            </div>
            <DateTime timeConstraints={{ hours: { min: 8, max: 20, step: 1 } }} input={props.input} onChange={props.onChange} className='input-group-sm mb-2 w-50' inputProps={{ placeholder: props.placeholder }} style={{ maxHeight: '50px' }} timeFormat="HH:mm" dateFormat="DD-MM-yyyy" />
        </div>
    )
}

export default AlbsCmpDatePicker