import React from 'react'



const VaveCmpRedRibbon = props => {
    return (
        <div className='container-fluid'
            style={{
                backgroundColor: '#F1F1F1',
                width: "100%",
                height: '50px'
            }}>
            <div className="row h-100 align-content-center pl-3" style={{ fontSize: "17px" }}>
                <div className='col-12 col-md-5 h-50 mt-2'>
                    <i className={" pr-3 " + props.icon}></i>
                    <b>{props.Title}</b>
                </div>
            </div>
        </div>
    )
}

export default VaveCmpRedRibbon;