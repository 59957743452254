import firebase from '../../../fbConfig/fbConfig'

export const rep_customerDetails = async (inputs) => {
    var beginningDateObject2 = new Date(inputs.dateRange.startDate);
    var beginningDateObject = new Date(inputs.dateRange.endDate);
    if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
        beginningDateObject.setHours(23);
        beginningDateObject.setMinutes(59);
        beginningDateObject.setSeconds(59);
    }
    var reportRes = {
        fields: ['#', 'Name', 'MobileNumber', 'Email', 'House Number', 'Street', 'City', 'Discount'],
        data: []
    };
    const res = await firebase.firestore().collection('customer').where('createdAt', '>', beginningDateObject2).where('createdAt', '<', beginningDateObject).get()
    res.forEach((data) => {
        reportRes.data.push({
            name: data.data().name,
            phoneNumber: data.data().phoneNumber,
            email: data.data().email,
            houseNo: data.data().houseNo,
            street: data.data().street,
            city: data.data().city,
            discount: data.data().discount
        })
    })
    return reportRes;
}