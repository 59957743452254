import * as fb_functions from '../../../functions/FirebaseFunctions'
import Axios from 'axios'

export const rep_stockDetails = async (inputs) => {
    var sDate = inputs.dateRange.startDate
    var eDate = inputs.dateRange.endDate
    var reportRes = {
        fields: ['#', 'CO Number', 'Washing Type', 'Delivery Form', 'Rack', 'Customer Name', 'Customer Tell', 'PCS', 'Amount'],
        data: []
    };


    const responseData = await Axios.post('/posIntegration/stock', {
        stDate: sDate.getFullYear() + "-" + (sDate.getMonth() + 1) + "-" + sDate.getDate() + " 00:00:00",
        enDate: eDate.getFullYear() + "-" + (eDate.getMonth() + 1) + "-" + eDate.getDate() + " 23:59:59",
        location: 'RE'
    })

    responseData.data.forEach((data) => {
        reportRes.data.push({
            col2: data.CO_Number,
            col3: data.PROCESS,
            col4: data.DELIVERY_FORM,
            col5: data.RACK,
            col6: data.CUSTOMER_NAME,
            col7: data.CUSTOMER_TELL,
            col8: data.TOTAL_PCS,
            col9: data.TOTOAL_AMOUNT
        })
    })

    let totalAmt = 0.00;
    let totalPcs = 0;
    reportRes.data.forEach(element => {
        if (element.col9 != undefined)
            totalAmt = totalAmt + parseFloat(element.col9);
        if (element.col8 != undefined)
            totalPcs = totalPcs + parseInt(element.col8);
    });
    if (totalAmt != 0.00 && totalPcs != 0) {
        reportRes.data.push({
            col2: 'Total',
            col3: '',
            col4: '',
            col5: '',
            col6: '',
            col7: '',
            col8: totalPcs,
            col9: totalAmt
        })
    }


    return (reportRes)
}