import React from "react";
import AlbsCmpMsgModal from "../../components/AlbsCmpMsgModal/AlbsCmpMsgModal";
import CustomMapWrapper from "../../components/Map/MapWrapper";
import firebase from "../../fbConfig/fbConfig";

const BackendEditCustomers = () => {
  const [mobile, setMobile] = React.useState();
  const [locations, setLocations] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("information");
  const [loading, setLoading] = React.useState(false);
  const [originalValues, setOriginalValues] = React.useState(null);
  const [details, setDetails] = React.useState({
    id: null,
    name: "",
    phoneNumber: "",
    email: "",
    houseNo: "",
    street: "",
    city: "default",
    latLng: null,
  });

  React.useEffect(() => {
    getLocations();
  }, []);

  const getLocations = () => {
    firebase
      .firestore()
      .collection("location")
      .get()
      .then((snap) => {
        const arr = [];
        snap.forEach((doc) => {
          arr.push(doc.data().city);
        });
        setLocations(arr.sort());
      })
      .catch((error) => console.log(error));
  };

  const closeHandler = () => setShow(false);

  const setValue = (e) => {
    e.persist();
    setDetails((prevDetails) => ({
      ...prevDetails,
      [e.target.name]: e.target.value,
    }));
  };

  const searchCustomer = (e) => {
    e.preventDefault();
    setShow(true);
    setType("information");
    setLoading(true);
    firebase
      .firestore()
      .collection("customer")
      .where("phoneNumber", "==", mobile)
      .get()
      .then((res) => {
        setLoading(false);
        if (res.empty) {
          setType("error");
          setMessage("Invalid mobile number or not found");
          return;
        }
        const { name, phoneNumber, email, houseNo, street, city, latLng } =
          res.docs[0].data();
        setOriginalValues({
          id: res.docs[0].id,
          name,
          phoneNumber,
          email,
          houseNo,
          street,
          city,
          latLng,
        });
        setDetails({
          id: res.docs[0].id,
          name,
          phoneNumber,
          email,
          houseNo,
          street,
          city,
          latLng,
        });
        setShow(false);
      });
  };

  const updateCustomer = () => {
    setShow(true);
    setLoading(true);

    if (!originalValues) {
      setLoading(false);
      setType("error");
      setMessage("Please select a customer");
      return;
    }

    if (
      details.name === "" ||
      details.phoneNumber === "" ||
      details.email === ""
    ) {
      setLoading(false);
      setType("error");
      setMessage("Name, Mobile and Email cannot be empty");
      return;
    }

    firebase
      .firestore()
      .collection("customer")
      .doc(details.id)
      .update(details)
      .then((res) => {
        setLoading(false);
        setType("success");
        setMessage("Successfully updated");
        console.log(res);
      })
      .catch(() => {
        setLoading(false);
        setType("error");
        setMessage("Please refresh the page and try again");
      });
  };

  return (
    <div class="content">
      <div class="panel-header bg-primary-gradient">
        <div class="page-inner py-5">
          <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 class="text-white pb-2 fw-bold">Customer Master</h2>
              <h5 class="text-white op-7 mb-2">
                Laundromat Information System
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="page-inner mt--5">
        <div class="row mt--1 justify-content-center">
          <div class="col-lg-6 position-sticky">
            <div class="card">
              <div class="card-body">
                <div class="card-header row py-3">
                  <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item">
                      <div class="card-title">Edit customer details</div>
                      <div class="card-category">
                        Fill below fields to edit customer details
                      </div>
                    </li>
                  </ul>
                  <form class="input-group mb-3 mt-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Customer Mobile
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search customer by customer mobile"
                      aria-describedby="basic-addon1"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    <button
                      type="submit"
                      class="btn btn-outline-info"
                      data-toggle="modal"
                      data-target="#itemSearchModal"
                      onClick={searchCustomer}
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </form>
                </div>
                <div class="row py-3 ml-5 mr-5">
                  <div class="card-category mb-2">General details</div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Customer Name
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="John Doe"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="name"
                      value={details.name}
                      onChange={setValue}
                    />
                  </div>
                  <div class="card-category mb-2">Contact details</div>
                  {/* Customer contact details */}
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Customer mobile
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="0779492569"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="phoneNumber"
                      value={details.phoneNumber}
                      onChange={setValue}
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Email
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="user@laundromat.lk"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="email"
                      value={details.email}
                      onChange={setValue}
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        House No
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="126"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="houseNo"
                      value={details.houseNo}
                      onChange={setValue}
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        Street
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Reid Avenue"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="street"
                      value={details.street}
                      onChange={setValue}
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <label
                        class="input-group-text"
                        htmlFor="inputGroupSelect01"
                      >
                        City
                      </label>
                    </div>
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      name="city"
                      value={details.city}
                      onChange={setValue}
                    >
                      <option value="default">Choose...</option>
                      {locations.map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="ml-5 mr-5">
                  <CustomMapWrapper
                    latLng={details.latLng}
                    setLatLng={(latLng) =>
                      setDetails((prevDetails) => ({ ...prevDetails, latLng }))
                    }
                    oriLatLng={originalValues?.latLng}
                  />
                </div>
                <div class="row py-3 ml-5 mr-5 justify-content-center">
                  <button
                    type="button"
                    class="btn btn-secondary mr-2"
                    onClick={() => setDetails({ ...originalValues })}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary mr-2"
                    onClick={updateCustomer}
                  >
                    Update Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlbsCmpMsgModal
          show={show}
          onHide={closeHandler}
          message={message}
          confirmBtnOnClick={updateCustomer}
          type={type}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BackendEditCustomers;
