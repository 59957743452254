import Axios from "axios";
import React, { Component } from "react";
import { DropdownList } from "react-widgets";
import VaveCard from "../../components/AlbsCmpCard/CmpGenCard/CmpGenCard";
import DatePicker from "../../components/AlbsCmpDatePicker/AlbsCmpDatePicker";
import MsgModal from "../../components/AlbsCmpMsgModal/AlbsCmpMsgModal";
import SearchModal from "../../components/AlbsCmpSearchModal/AlbsCmpSearchModal";
import CustomMapWrapper from "../../components/Map/MapWrapper";
import RRibbon from "../../components/VaveCmpRedRibbon/VaveCmpRedRibbon";
import BootstrapTxt from "../../components/VaveTextInput/BootstrapTxtInput/BootstrapTxtInput";
import firebase from "../../fbConfig/fbConfig";

const initialState = {
  inputs: {
    id: "",
    name: "",
    phoneNumber: "",
    email: "",
    houseNo: "",
    street: "",
    city: "",
    landmark: "",
    customerRefId: null,
    pickupDateTime: "",
    deliveryDateTime: "",
    paymentType: "Cash",
    itemCode: "",
    itemName: "",
    washingType: "",
    qty: "",
    isHanger: false,
    isCreased: false,
    remarks: "",
    deliveryAmount: 0,
    itemUnitPrice: [],
    latLng: null,
  },
  latLng: null,
  orderItems: [],
  dataset: {
    filters: ["No Data"],
    data: [{ NoData: "No Data" }],
  },
  cusMobile: "",
  locations: [],
  locMobile: false,
  showModal: false,
  searchModalTitle: "No data",
  modalField: "",
  logedInUserCode: "",
  totalOrderValue: 0,
  totalQty: 0,
  bgColor: "",
  color: "",
  showItems: false,
  locationDeliveryAmount: 0,
  deliveryAmountType: "inPD",
  loading: false,
};

const notification = {
  notificationShow: false,
  notification: "",
  crudOperationType: "",
  type: "",
};
class VavePgsOnCallPd extends Component {
  state = { ...initialState, notification };

  changeDeliveryAmount = (method) => {
    if (method == "exPD") {
      this.setState((cur) => ({
        ...cur,
        inputs: {
          ...cur.inputs,
          deliveryAmount: parseFloat(0),
        },
        deliveryAmountType: "exPD",
      }));
    } else if (method == "inPD") {
      this.setState((cur) => ({
        ...cur,
        inputs: {
          ...cur.inputs,
          deliveryAmount: parseFloat(cur.locationDeliveryAmount),
        },
        deliveryAmountType: "inPD",
      }));
    } else {
      this.setState((cur) => ({
        ...cur,
        inputs: {
          ...cur.inputs,
          deliveryAmount: parseFloat(cur.locationDeliveryAmount) / 2,
        },
        deliveryAmountType: "inPORD",
      }));
    }
  };

  /**
   * Add locations when component did mount
   */
  componentDidMount() {
    this.getLocations();
  }

  /**
   * Search modal show and deissapear
   * Depend on a the showModal in states
   */
  searchModalToggler = () => {
    this.setState((current) => ({
      ...current,
      showModal: current.showModal ? false : true,
    }));
  };

  /**
   * load data to the search modal
   * title = Modal Title
   * field = output of the seach module maps to the field of state
   * apiUrl = Url use to load data into search modal
   */
  extraSearch = (title, field, apiUrl) => {
    firebase
      .firestore()
      .collection("item")
      .get()
      .then((snap) => {
        const arr = [];
        snap.forEach((doc) => {
          arr.push({
            itemCode: doc.ref.id,
            itemName: doc.data().name,
            category: doc.data().category,
          });
        });
        if (arr.length != 0) {
          this.setState((cur) => ({
            ...cur,
            dataset: {
              filters: Object.keys(arr[0]),
              data: arr,
            },
          }));
          this.searchModalToggler();
          this.setState((cur) => ({
            ...cur,
            searchModalTitle: title,
            modalField: field,
          }));
        } else {
          this.notificationToggler();
          this.setState((cur) => ({ ...cur, notification: "No Data" }));
        }
      })
      .catch((error) => {
        this.notificationToggler();
        this.setState((cur) => ({ ...cur, notification: error.message }));
      });
  };

  /**
   * Add selection id from search modal
   */
  modalRowClick = (val) => {
    this.addInputs(this.state.modalField, val);
    this.searchModalToggler();
    this.getItemDetails(val);
  };

  /**
   * Update state.inputs when change the input fields
   */
  addInputs = (key, val) => {
    this.setState((cur) => ({
      ...cur,
      inputs: { ...cur.inputs, [key]: val.toString() },
    }));
  };

  setLatLng = (val) => this.setState((cur) => ({ ...cur, latLng: val }));

  /**
   * refresh page
   */
  refreshPage = () => {
    this.setState(initialState);
    this.getLocations();
  };

  /**
   * Get all the delivery locaitons
   */
  getLocations = () => {
    firebase
      .firestore()
      .collection("location")
      .get()
      .then((snap) => {
        const arr = [];
        snap.forEach((doc) => {
          arr.push(doc.data().city);
        });
        this.setState((cur) => ({ ...cur, locations: arr.sort() }));
      })
      .catch((error) => console.log(error));
  };

  /**
   * Get item details from itemCode
   */
  getItemDetails = (itemCode) => {
    firebase
      .firestore()
      .collection("item")
      .doc(itemCode)
      .get()
      .then((doc) => {
        let data = {
          itemCode: doc.ref.id,
          itemName: doc.data().name,
          itemUnitPrice: [
            doc.data().drycleaningPrice,
            doc.data().washingPrice,
            doc.data().pressingPrice,
          ],
        };
        this.setState((cur) => ({
          ...cur,
          inputs: { ...cur.inputs, ...data },
        }));
      })
      .catch((error) => console.log(error));
  };

  /**
   * Get customer details from mobile number
   */
  getCustomerDetails = () => {
    this.setState((cur) => ({ ...cur, loading: true }));
    this.notificationToggler("information", "", "");
    Axios.get("/customer/tellNo/" + this.state.inputs.phoneNumber)
      .then((result) => {
        if (result.data.status != "No Data") {
          this.setState((cur) => ({
            ...cur,
            inputs: {
              ...cur.inputs,
              id: result.data.data.CM_CODE,
              name: result.data.data.CM_NAME,
            },
            cusMobile: cur.inputs.phoneNumber,
          }));
          firebase
            .firestore()
            .collection("customer")
            .where("phoneNumber", "==", this.state.inputs.phoneNumber)
            .get()
            .then((res) => {
              if (res.docs.length > 0) {
                firebase
                  .firestore()
                  .collection("location")
                  .where("city", "==", res.docs[0].data().city)
                  .get()
                  .then((amount) => {
                    this.setState(
                      (cur) => ({
                        ...cur,
                        inputs: {
                          ...cur.inputs,
                          id: result.data.data.CM_CODE,
                          name: result.data.data.CM_NAME,
                          email: res.docs[0].data().email,
                          houseNo: res.docs[0].data().houseNo,
                          street: res.docs[0].data().street,
                          city: res.docs[0].data().city,
                          latLng: res.docs[0].data().latLng,
                          customerRefId: res.docs[0].id,
                        },
                        latLng: res.docs[0].data().latLng,
                        locationDeliveryAmount: parseFloat(
                          amount.docs[0].data().price
                        ),
                        cusMobile: cur.inputs.phoneNumber,
                      }),
                      () => {
                        this.changeDeliveryAmount(
                          this.state.deliveryAmountType
                        );
                      }
                    );
                  });
              }
            });
          this.setState((cur) => ({ ...cur, locMobile: true }));
          this.setState((cur) => ({ ...cur, loading: false }));
          this.notificationToggler(null, null, null);
        } else {
          firebase
            .firestore()
            .collection("customer")
            .where("phoneNumber", "==", this.state.inputs.phoneNumber)
            .get()
            .then((res) => {
              if (res.docs.length > 0) {
                firebase
                  .firestore()
                  .collection("location")
                  .where("city", "==", res.docs[0].data().city)
                  .get()
                  .then((amount) => {
                    this.setState(
                      (cur) => ({
                        ...cur,
                        inputs: {
                          ...cur.inputs,
                          id: res.docs[0].id,
                          name: res.docs[0].data().name,
                          email: res.docs[0].data().email,
                          houseNo: res.docs[0].data().houseNo,
                          street: res.docs[0].data().street,
                          city: res.docs[0].data().city,
                          latLng: res.docs[0].data().latLng,
                          customerRefId: res.docs[0].id,
                        },
                        latLng: res.docs[0].data().latLng,
                        locationDeliveryAmount: parseFloat(
                          amount.docs[0].data().price
                        ),
                        cusMobile: cur.inputs.phoneNumber,
                      }),
                      () => {
                        this.changeDeliveryAmount(
                          this.state.deliveryAmountType
                        );
                      }
                    );
                    this.setState((cur) => ({ ...cur, locMobile: true }));
                    this.setState((cur) => ({ ...cur, loading: false }));
                    this.notificationToggler(null, null, null);
                  })
                  .catch((error) => {
                    this.setState((cur) => ({ ...cur, loading: false }));
                    this.notificationToggler("error", error.message, null);
                  });
              } else {
                this.setState((cur) => ({ ...cur, locMobile: true }));
                this.setState((cur) => ({ ...cur, loading: false }));
                this.notificationToggler(
                  "error",
                  "No customers from this number.",
                  null
                );
              }
            })
            .catch((error) => {
              this.setState((cur) => ({ ...cur, loading: false }));
              this.notificationToggler("error", error.message, null);
            });
        }
      })
      .catch((error) => {
        this.setState((cur) => ({ ...cur, loading: false }));
        this.notificationToggler("error", error.message, null);
      });
  };

  /**
   * Change amount when city change
   */
  onCityChange = (city) => {
    this.setState((cur) => ({ ...cur, inputs: { ...cur.inputs, city: city } }));
    firebase
      .firestore()
      .collection("location")
      .where("city", "==", city)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          this.setState(
            (cur) => ({
              ...cur,
              inputs: {
                ...cur.inputs,
                city: city,
              },
              locationDeliveryAmount: snap.docs[0].data().price,
            }),
            () => {
              this.changeDeliveryAmount(this.state.deliveryAmountType);
            }
          );
        }
      });
  };

  /**
   * Async function for save customer details
   */
  addCustomerDataToDb = async () => {
    const {
      phoneNumber,
      email,
      name,
      houseNo,
      street,
      city,
      landmark,
      customerRefId,
    } = this.state.inputs;
    const { latLng } = this.state;
    let customerDoc;
    if (customerRefId == null) {
      customerDoc = await firebase
        .firestore()
        .collection("customer")
        .add({
          phoneNumber,
          email,
          name,
          houseNo,
          street,
          city,
          landmark,
          latLng,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          crBy: firebase.auth().currentUser.email,
        });
      if (customerDoc)
        return {
          customerRefId: customerDoc.id,
          phoneNumber,
          email,
          name,
          houseNo,
          street,
          city,
          landmark,
        };
      else return false;
    } else {
      customerDoc = await firebase
        .firestore()
        .collection("customer")
        .doc(customerRefId)
        .update({
          name,
          phoneNumber,
          email,
          houseNo,
          street,
          city,
          landmark,
          latLng,
          updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          updatedBy: firebase.auth().currentUser.email,
        });
      return {
        customerRefId,
        phoneNumber,
        email,
        name,
        houseNo,
        street,
        city,
        latLng,
        landmark,
      };
    }
  };

  /**
   * Add Order details into order collection
   */
  addOrderHeader = async (customerDetails) => {
    let order = await firebase
      .firestore()
      .collection("order")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        paymentType: this.state.inputs.paymentType,
        customerId: customerDetails.customerRefId,
        delCharge: this.state.inputs.deliveryAmount,
        deliveryDate: this.state.inputs.deliveryDateTime,
        orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
        payment: "pending",
        pickupDate: this.state.inputs.pickupDateTime,
        quantity: parseInt(this.state.totalQty),
        subtot: parseInt(this.state.totalOrderValue).toFixed(2),
        name: customerDetails.name,
        phoneNumber: customerDetails.phoneNumber,
        houseNo: customerDetails.houseNo,
        street: customerDetails.street,
        city: customerDetails.city,
        latLng: customerDetails.latLng,
        crBy: firebase.auth().currentUser.email,
        item:
          this.state.orderItems.length != 0
            ? this.state.orderItems.map((order) => {
                const {
                  itemCode,
                  itemName,
                  washingType,
                  qty,
                  isHanger,
                  isCreased,
                  remarks,
                  itemUnitPrice,
                  totalAmount,
                } = order;
                return {
                  deliveryform: isHanger ? "Hanged" : "folded",
                  item: itemName,
                  price: itemUnitPrice,
                  quantity: qty,
                  subtot: totalAmount,
                  washingmethod: washingType,
                  itemCode,
                  isCreased,
                  remarks,
                };
              })
            : null,
      });

    let countRef = firebase.firestore().collection("order").doc("--stats--");
    let orderRef = firebase.firestore().collection("order").doc(order.id);

    let idGen;
    await firebase.firestore().runTransaction((trx) => {
      return trx.get(countRef).then((countDoc) => {
        if (!countDoc.exists) throw Error("Document does not exist!");
        const nextCount = countDoc.data().count + 1;
        idGen = "00000000" + nextCount;
        idGen = "PD" + idGen.substr(idGen.length - 8);
        trx.update(orderRef, { generatedId: idGen });
        trx.update(countRef, { count: nextCount });
      });
    });
    if (order != undefined) {
      return { orderId: order.id, generatedId: idGen };
    } else {
      return false;
    }
  };

  /**
   * Async function for add items to an order
   */
  addOrderItems = async (customerDetails, orderId) => {
    let batch = firebase.firestore().batch();
    this.state.orderItems.forEach((obj) => {
      batch.set(firebase.firestore().collection("orderItem").doc(), {
        note: obj.remarks,
        item: obj.itemName,
        itemCode: obj.itemCode,
        quantity: obj.qty,
        washingmethod: obj.washingType,
        deliveryform: obj.isHanger ? "Hanged" : "folded",
        price: obj.itemUnitPrice,
        subtot: obj.totalAmount,
        customerId: customerDetails.customerRefId,
        paymentType: this.state.inputs.paymentType,
        payment: "pending",
        orderId: orderId.orderId,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        name: customerDetails.name,
        phoneNumber: customerDetails.phoneNumber,
        houseNo: customerDetails.houseNo,
        street: customerDetails.street,
        city: customerDetails.city,
        latLng: customerDetails.latLng,
        crBy: firebase.auth().currentUser.email,
        isCreased: obj.isCreased,
        generatedId: orderId.generatedId,
      });
    });
    await batch.commit();
    return true;
  };

  /**
   * Send Email
   */
  sendEmailToCustomer = async (cmDet, orderId, withItems) => {
    if (withItems) {
      try {
        let result = await Axios.post("/sendEmails/pickupEmailWithItems", {
          orderNumber: orderId.generatedId,
          cusName: cmDet.name,
          cusTell: cmDet.phoneNumber,
          emailAdd: cmDet.email,
          houseNo: cmDet.houseNo,
          street: cmDet.street,
          city: cmDet.city,
          deliveryDate: this.state.inputs.deliveryDateTime,
          pickupDate: this.state.inputs.pickupDateTime,
          collection: this.state.orderItems,
          orderAmount: parseFloat(this.state.totalOrderValue).toFixed(2),
          deliveryFee: parseFloat(this.state.inputs.deliveryAmount),
          totalAmount: (
            parseFloat(this.state.totalOrderValue) +
            parseFloat(this.state.inputs.deliveryAmount)
          ).toFixed(2),
        });
        return result;
      } catch (err) {
        return err;
      }
    } else {
      try {
        let result = await Axios.post("/sendEmails/pickupEmailWithoutItems", {
          orderNumber: orderId.generatedId,
          cusName: cmDet.name,
          cusTell: cmDet.phoneNumber,
          emailAdd: cmDet.email,
          houseNo: cmDet.houseNo,
          street: cmDet.street,
          city: cmDet.city,
          deliveryDate: this.state.inputs.deliveryDateTime,
          pickupDate: this.state.inputs.pickupDateTime,
        });
        return result;
      } catch (err) {
        return err;
      }
    }
  };

  /**
   * Create order transaction
   */
  createOrderTransaction = async () => {
    this.setState((cur) => ({ ...cur, loading: true }));
    let customerDet = await this.addCustomerDataToDb();
    if (customerDet) {
      let orderId = await this.addOrderHeader(customerDet);
      if (orderId.orderId) {
        if (this.state.orderItems.length != 0) {
          let orderItemsresult = await this.addOrderItems(customerDet, orderId);
          if (orderItemsresult) {
            this.sendEmailToCustomer(customerDet, orderId, true);
            this.sendSms(customerDet.phoneNumber, "/sms/newOrder", {
              orderNo: orderId.generatedId,
            });
            this.setState((cur) => ({ ...cur, loading: false }));
            this.notificationToggler(
              "success",
              "Order has been created successfully.",
              null
            );
            return true;
          } else {
            return false;
          }
        } else {
          this.sendEmailToCustomer(customerDet, orderId, false);
          this.sendSms(customerDet.phoneNumber, "/sms/newOrder", {
            orderNo: orderId.generatedId,
          });
          this.setState((cur) => ({ ...cur, loading: false }));
          this.notificationToggler(
            "success",
            "Order has been placed without items.",
            null
          );
          return true;
        }
      } else {
        this.setState((cur) => ({ ...cur, loading: false }));
        this.notificationToggler("error", "Order cannot be completed.", null);
      }
    } else {
      this.setState((cur) => ({ ...cur, loading: false }));
      this.notificationToggler(
        "error",
        "Customer cannot be updated.Transaction faild.",
        null
      );
    }
    return false;
  };

  //Send SMS
  sendSms = async (mobile, link, data) => {
    let ts = Date.now();
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes2 = date_ob.getMinutes() + 2;
    let minutes = date_ob.getMinutes();
    Axios.post(link, {
      phoneNumber: mobile,
      ...data,
      s_time:
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        "00",
      e_time:
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes2 +
        ":" +
        "00",
    });
  };

  /**
   * Handle Checkout
   */
  checkoutHandler = async () => {
    if (!this.state.inputs.phoneNumber) {
      this.notificationToggler("error", "Fill the customer details.", null);
    } else if (!this.state.inputs.city) {
      this.notificationToggler("error", "Fill the delivery address.", null);
    } else if (
      this.state.inputs.pickupDateTime == "" &&
      this.state.inputs.deliveryDateTime == ""
    ) {
      this.notificationToggler("error", "Fill the delivery timeline.", null);
    } else {
      this.notificationToggler("confirmation", "Are you sure?", "insert");
    }
  };

  ////////////////////////////////////////////////////////////////
  /**
   *
   * @param {string} notificationType
   * @param {string} message
   * @param {string} crudOperationType
   */
  notificationToggler = (notificationType, message, crudOperationType) => {
    if (
      notificationType == null &&
      message == null &&
      crudOperationType == null
    ) {
      this.setState((cur) => ({
        ...cur,
        notification: {
          notificationShow: false,
          notification: message,
          confirmed: false,
          type: notificationType,
          crudOperationType: crudOperationType,
        },
      }));
    } else {
      this.setState((cur) => ({
        ...cur,
        notification: {
          notificationShow: true,
          notification: message,
          confirmed: false,
          type: notificationType,
          crudOperationType: crudOperationType,
        },
      }));
    }
  };

  /**
   * Get Confirmation
   */
  afterConfirm = async () => {
    switch (this.state.notification.crudOperationType) {
      case "read":
        break;
      case "insert":
        if (await this.createOrderTransaction()) {
          this.refreshPage();
        }
        break;
      case "update":
        break;
      case "delete":
        break;
      default:
        break;
    }
  };

  ////////////////////////////////////////////////////////////////

  /**
   * Add Item details into  grid
   */
  addDetailsToRow = () => {
    // let duplicateIndex = this.state.orderItems.map((item, index) => {
    //     if (item.itemCode == this.state.inputs.itemCode &&
    //         item.washingType == this.state.inputs.washingType &&
    //         item.isHanger == this.state.inputs.isHanger &&
    //         item.isCreased == this.state.inputs.isCreased &&
    //         item.remarks == this.state.inputs.remarks)
    //         return index
    // })
    this.setState((cur) => ({
      ...cur,
      orderItems: [
        ...cur.orderItems,
        {
          itemCode: cur.inputs.itemCode,
          itemName: cur.inputs.itemName,
          washingType: cur.inputs.washingType,
          qty: cur.inputs.qty,
          isHanger: cur.inputs.isHanger,
          isCreased: cur.inputs.isCreased,
          remarks: cur.inputs.remarks,
          itemUnitPrice:
            cur.inputs.washingType == "Washing"
              ? cur.inputs.itemUnitPrice[1]
              : cur.inputs.washingType == "Pressing"
              ? cur.inputs.itemUnitPrice[2]
              : cur.inputs.itemUnitPrice[0],
          totalAmount:
            parseInt(cur.inputs.qty) *
            parseInt(
              cur.inputs.washingType == "Washing"
                ? cur.inputs.itemUnitPrice[1]
                : cur.inputs.washingType == "Pressing"
                ? cur.inputs.itemUnitPrice[2]
                : cur.inputs.itemUnitPrice[0]
            ),
        },
      ],
      totalOrderValue:
        parseInt(cur.totalOrderValue) +
        parseInt(cur.inputs.qty) *
          parseInt(
            cur.inputs.washingType == "Washing"
              ? cur.inputs.itemUnitPrice[1]
              : cur.inputs.washingType == "Pressing"
              ? cur.inputs.itemUnitPrice[2]
              : cur.inputs.itemUnitPrice[0]
          ),
      totalQty: parseInt(cur.totalQty) + parseInt(cur.inputs.qty),
    }));
  };

  /**
   * Remove order items
   */
  removeItemHandler = (index) => {
    let arr = this.state.orderItems;
    let removeAmount = this.state.orderItems.filter(
      (value, index) => index == index
    )[0].totalAmount;
    let removeQty = this.state.orderItems.filter(
      (value, index) => index == index
    )[0].qty;
    arr.splice(index, 1);
    this.setState((cur) => ({
      ...cur,
      orderItems: arr,
      totalOrderValue:
        parseFloat(cur.totalOrderValue) - parseFloat(removeAmount),
      totalQty: parseInt(cur.totalQty) - parseInt(removeQty),
    }));
  };

  render() {
    return (
      <div className="w-100">
        <RRibbon Title="On Call Pickup and Delivery" icon="fas fa-truck" />
        <div className="row justify-content-center no-gutters w-100">
          <VaveCard>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="row justify-content-center align-items-center">
                  <div className="col-sm-4 col-md-1 text-center d-none d-md-block">
                    <i className="fas fa-file-contract fa-2x"></i>
                  </div>
                  <div className="col-sm-8 col-md-6">
                    <div className="mb-1">
                      <b>Fill below header</b>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 text-md-right text-sm-center py-2">
                    <button
                      onClick={() => this.refreshPage()}
                      className="btn btn-primary btn-sm mx-2 mx-md-2"
                      type="submit"
                    >
                      Refresh
                    </button>
                    <button
                      disabled={this.state.orderItems.length > 0}
                      onClick={() =>
                        this.setState((cur) => ({
                          ...cur,
                          showItems: cur.showItems ? false : true,
                        }))
                      }
                      className="btn btn-primary btn-sm mx-2 mx-md-2"
                      type="submit"
                    >
                      {this.state.showItems ? "Hide Items" : "Add Items"}
                    </button>
                    <button
                      onClick={() => this.checkoutHandler()}
                      className="btn btn-primary btn-sm mx-2 mx-md-2 btn-danger"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2" style={{ borderColor: "#707070" }} />
            <div className="row w-100">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                <div className="mb-3">Customer Details</div>
                <BootstrapTxt
                  placeHolder="Enter and search"
                  prependText="Mobile"
                  value={this.state.inputs.phoneNumber}
                  onChange={(e) =>
                    this.addInputs("phoneNumber", e.target.value)
                  }
                  appendShow
                  appendOnClick={this.getCustomerDetails}
                  readOnly={this.state.locMobile}
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? this.getCustomerDetails() : null
                  }
                />
                <BootstrapTxt
                  readOnly={!this.state.locMobile}
                  placeHolder="Customer Name"
                  prependText="Customer Name"
                  value={this.state.inputs.name}
                  onChange={(e) => this.addInputs("name", e.target.value)}
                />
                <BootstrapTxt
                  readOnly={!this.state.locMobile}
                  placeHolder="Email"
                  prependText="Email"
                  value={this.state.inputs.email}
                  onChange={(e) => this.addInputs("email", e.target.value)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                <div className="mb-3">Delivery Address</div>
                <BootstrapTxt
                  readOnly={!this.state.locMobile}
                  placeHolder="No."
                  prependText="No."
                  value={this.state.inputs.houseNo}
                  onChange={(e) => this.addInputs("houseNo", e.target.value)}
                />
                <BootstrapTxt
                  readOnly={!this.state.locMobile}
                  placeHolder="Street Name"
                  prependText="Street Name"
                  value={this.state.inputs.street}
                  onChange={(e) => this.addInputs("street", e.target.value)}
                />
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend d-none d-md-block">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      City
                    </span>
                  </div>
                  <DropdownList
                    readOnly={!this.state.locMobile}
                    value={this.state.inputs.city}
                    onChange={(city) => this.onCityChange(city)}
                    filter
                    data={this.state.locations}
                    style={{ width: "60%", height: "31px", fontSize: "13px" }}
                    placeholder="City"
                  />
                </div>
                <CustomMapWrapper
                  latLng={this.state.latLng}
                  setLatLng={this.setLatLng}
                  oriLatLng={this.state.inputs?.latLng}
                />
              </div>
            </div>
            <hr className="mt-2" style={{ borderColor: "#707070" }} />
            <div className="row w-100 mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                <div className="mb-3">Delivery Timeline</div>
                <DatePicker
                  onChange={(e) =>
                    this.setState((cur) => ({
                      ...cur,
                      inputs: {
                        ...cur.inputs,
                        pickupDateTime:
                          new Date(e._d).getHours() == 0
                            ? new Date(e._d.setHours(8))
                            : e._d,
                      },
                    }))
                  }
                  placeholder="Pickup date and time"
                  InputName="Pickup data and time"
                />
                <DatePicker
                  onChange={(e) =>
                    this.setState((cur) => ({
                      ...cur,
                      inputs: {
                        ...cur.inputs,
                        deliveryDateTime:
                          new Date(e._d).getHours() == 0
                            ? new Date(e._d.setHours(8))
                            : e._d,
                      },
                    }))
                  }
                  placeholder="Delivery date and time"
                  InputName="Delivery date and time"
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                <div className="mb-3">Payment Methods</div>
                <div className="input-group mb-3 input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="radio"
                        aria-label="Checkbox for following text input"
                        checked={
                          this.state.inputs.paymentType == "Cash" ? true : false
                        }
                        onChange={() =>
                          this.setState((cur) => ({
                            ...cur,
                            inputs: { ...cur.inputs, paymentType: "Cash" },
                          }))
                        }
                      />
                      {/* <input type="radio" aria-label="Checkbox for following text input" checked={false} onChange={} /> */}
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value="Pay on delivery - Cash"
                  />
                </div>
                <div className="input-group mb-3 input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="radio"
                        aria-label="Checkbox for following text input"
                        checked={
                          this.state.inputs.paymentType == "Card" ? true : false
                        }
                        onChange={() =>
                          this.setState((cur) => ({
                            ...cur,
                            inputs: { ...cur.inputs, paymentType: "Card" },
                          }))
                        }
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value="Pay on delivery - Card"
                  />
                </div>
                <div className="mb-3">Delivery Charges</div>
                <div className="input-group mb-3 input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="radio"
                        aria-label="Checkbox for following text input"
                        checked={this.state.deliveryAmountType == "inPD"}
                        onChange={() => this.changeDeliveryAmount("inPD")}
                      />
                      {/* <input type="radio" aria-label="Checkbox for following text input" checked={false} onChange={} /> */}
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value="Include Pickup & Delivery Charges"
                  />
                </div>
                <div className="input-group mb-3 input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="radio"
                        aria-label="Checkbox for following text input"
                        checked={this.state.deliveryAmountType == "inPORD"}
                        onChange={() => this.changeDeliveryAmount("inPORD")}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value="Include Pickup or Delivery Charges"
                  />
                </div>
                <div className="input-group mb-3 input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="radio"
                        aria-label="Checkbox for following text input"
                        checked={this.state.deliveryAmountType == "exPD"}
                        onChange={() => this.changeDeliveryAmount("exPD")}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    value="Exclude Pickup & Delivery Charges"
                  />
                </div>
              </div>
            </div>
          </VaveCard>
        </div>
        {this.state.showItems && (
          <div className="row justify-content-center no-gutters w-100">
            <VaveCard>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-1 text-center d-none d-md-block">
                      <i className="fas fa-sitemap fa-2x"></i>
                    </div>
                    <div className="col-sm-8 col-md-6">
                      <div className="mb-1">
                        <b>Add Items to Order</b>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 text-md-right text-sm-center py-2">
                      <button
                        className="btn btn-primary btn-sm my-sm-1 mx-md-2"
                        type="submit"
                        onClick={this.addDetailsToRow}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2" style={{ borderColor: "#707070" }} />
              <div className="row w-100">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                  <BootstrapTxt
                    placeHolder="Enter and search"
                    prependText="Item Code"
                    appendShow
                    appendOnClick={() =>
                      this.extraSearch("Items", "itemCode", "/items")
                    }
                    onChange={(e) => this.addInputs("itemCode", e.target.value)}
                    value={this.state.inputs.itemCode}
                    onKeyDown={(e) =>
                      e.keyCode === 13
                        ? this.getItemDetails(e.target.value)
                        : null
                    }
                  />
                  <BootstrapTxt
                    placeHolder="Item Name"
                    prependText="Item Name"
                    value={this.state.inputs.itemName}
                    readOnly
                  />
                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend d-none d-md-block">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-sm"
                      >
                        Washing Type
                      </span>
                    </div>
                    <DropdownList
                      value={this.state.inputs.washingType}
                      onChange={(washingType) => {
                        this.setState((cur) => ({
                          ...cur,
                          inputs: { ...cur.inputs, washingType },
                        }));
                      }}
                      filter
                      data={["Washing", "Drycleaning", "Pressing"]}
                      style={{ width: "60%", height: "31px", fontSize: "13px" }}
                      placeholder="Washing Type"
                    />
                  </div>
                  <BootstrapTxt
                    placeHolder="Enter Qty"
                    prependText="Qty"
                    value={this.state.inputs.qty}
                    onChange={(e) => this.addInputs("qty", e.target.value)}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-12">
                  <div className="input-group mb-3 input-group-sm">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          value={this.state.inputs.isHanger}
                          onClick={() =>
                            this.setState((cur) => ({
                              ...cur,
                              inputs: {
                                ...cur.inputs,
                                isHanger: cur.inputs.isHanger ? false : true,
                              },
                            }))
                          }
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Text input with checkbox"
                      value="Delivery with Hanger"
                    />
                  </div>
                  <div className="input-group mb-3 input-group-sm">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          value={this.state.inputs.isCreased}
                          onClick={() =>
                            this.setState((cur) => ({
                              ...cur,
                              inputs: {
                                ...cur.inputs,
                                isCreased: cur.inputs.isCreased ? false : true,
                              },
                            }))
                          }
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Text input with checkbox"
                      value="Deliver with crease"
                    />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Remarks</span>
                    </div>
                    <textarea
                      class="form-control"
                      aria-label="With textarea"
                      value={this.state.inputs.remarks}
                      onChange={(e) =>
                        this.addInputs("remarks", e.target.value)
                      }
                    ></textarea>
                    <div
                      className="input-group-append"
                      style={{ cursor: "pointer" }}
                      onClick={this.addDetailsToRow}
                    >
                      <span className="input-group-text" id="basic-addon2">
                        {" "}
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </VaveCard>
          </div>
        )}
        {this.state.showItems && (
          <div className="row justify-content-center no-gutters w-100">
            <div className="container container-fluid px-2 CmpGenCard_med_mob_padding mt-2">
              <div class="table-responsive container-fluid">
                <table class="table no-box-shadow">
                  <tbody>
                    <thead>
                      <tr style={{ backgroundColor: "black", color: "white" }}>
                        <th scope="col">#</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Washin Method</th>
                        <th scope="col">Is Hanger</th>
                        <th scope="col">Crease</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Remove</th>
                      </tr>
                    </thead>
                    {this.state.orderItems.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.itemName}</td>
                        <td>{item.washingType}</td>
                        <td>{item.isHanger ? "Hanger" : "Folded"}</td>
                        <td>
                          {item.isCreased ? "With Crease" : "Without Crease"}
                        </td>
                        <td>{item.remarks}</td>
                        <td>{parseFloat(item.itemUnitPrice).toFixed(2)}</td>
                        <td>{item.qty}</td>
                        <td className="text-right">
                          {parseFloat(item.totalAmount).toFixed(2)}
                        </td>
                        <td className="text-right">
                          <button
                            onClick={() => this.removeItemHandler(index)}
                            className="btn btn-danger"
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr style={{ backgroundColor: "GrayText", color: "white" }}>
                      <th colSpan="9" className="text-right" scope="col">
                        Qty
                      </th>
                      <th colSpan="2" className="text-right" scope="col">
                        {this.state.totalQty}
                      </th>
                    </tr>
                    <tr style={{ backgroundColor: "GrayText", color: "white" }}>
                      <th colSpan="9" className="text-right" scope="col">
                        Gross amount
                      </th>
                      <th colSpan="2" className="text-right" scope="col">
                        {parseFloat(this.state.totalOrderValue).toFixed(2)}
                      </th>
                    </tr>
                    <tr style={{ backgroundColor: "GrayText", color: "white" }}>
                      <th colSpan="9" className="text-right" scope="col">
                        Delivery Charge
                      </th>
                      <th colSpan="2" className="text-right" scope="col">
                        {parseFloat(this.state.inputs.deliveryAmount).toFixed(
                          2
                        )}
                      </th>
                    </tr>
                    <tr style={{ backgroundColor: "GrayText", color: "white" }}>
                      <th colSpan="9" className="text-right" scope="col">
                        Net amount
                      </th>
                      <th colSpan="2" className="text-right" scope="col">
                        {(
                          parseFloat(this.state.totalOrderValue) +
                          parseFloat(this.state.inputs.deliveryAmount)
                        ).toFixed(2)}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <SearchModal
          onClick={(e) => this.modalRowClick(e.target.id)}
          title={this.state.searchModalTitle}
          dataset={this.state.dataset}
          show={this.state.showModal}
          onHide={this.searchModalToggler}
        />
        <MsgModal
          show={this.state.notification.notificationShow}
          onHide={() => this.notificationToggler(null, null, null)}
          message={this.state.notification.notification}
          confirmBtnOnClick={this.afterConfirm}
          type={this.state.notification.type}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default VavePgsOnCallPd;
