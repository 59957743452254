import React, { useState } from "react";
import "./VaveOrderRows.css";
import List from "./orderitems";
import collapsed from "./collapsed@2x.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const VaveOrderRows = (props) => {
  const selItem = (e) => {
    const {
      CO_Number,
      collectedDate,
      NetAmount,
      DeliveryDate,
      dispatch,
    } = props.detObj;
    props.onChange(e, {
      CO_Number,
      collectedDate,
      NetAmount,
      DeliveryDate,
      dispatch,
    });
  };
  const [orderShow, setOrderShow] = useState("none");

  const onPressHandler = () => {
    setOrderShow(orderShow === "none" ? "flex" : "none");
  };

  const [startDate, setStartDate] = useState(new Date(props.delDate));

  const updateDate = (date) => {
    setStartDate(date);
    props.updateDate(props.orderNumber, date);
  };

  return (
    <div>
      <div className="VaveOrderRows_container container-fluid w-100">
        <div className="vaveorderRows_checkBoxBlock">
          <div className="page__toggle">
            <label className="toggle">
              <input
                checked={props.checked}
                className="toggle__input"
                type="checkbox"
                onChange={selItem}
              />
              <span className="toggle__label">
                <span className="toggle__text"></span>
              </span>
            </label>
          </div>
        </div>
        <div className="vaveOrderRows_orderDet">
          <label className="vaveOrderRows_orderDet_order">
            {props.orderNumber}
          </label>
          <label className="vaveOrderRows_orderDet_deliveryDate">
            {props.OrderDate}
          </label>
        </div>
        <div className="vaveOrderRows_amountDet">
          <label className="vaveOrderRows_amountTitle">Amount</label>
          <label className="vaveOrderRows_amount">{props.amount}</label>
        </div>

        <div className="vaveOrderRows_delDate">
          {!props.dispatch ? (
            <>
              <label className="vaveOrderRows_delDateTitle">
                Delivery date
              </label>
              {/* <label className='vaveOrderRows_delDate'>{props.delDate}</label> */}
              {/* <div className="form-control"> */}
              <DatePicker
                className="form-control vaveorderRow_datepicker"
                minDate={new Date(props.delDate)}
                selected={startDate}
                onChange={updateDate}
              />
              {/* </div> */}
            </>
          ) : (
              ""
            )}
        </div>
        <div>
          <img
            width="28px"
            style={{
              transform: orderShow === "none" ? "rotate(0)" : "rotate(180deg)",
            }}
            src={collapsed}
            className="viewitem-img"
            alt="collapse"
            onClick={onPressHandler}
          ></img>
        </div>
      </div>
      <div style={{ display: orderShow, float: "right" }}>
        <List orders={[...props.items]} />
      </div>
    </div>
  );
};

export default VaveOrderRows;
