import * as actionTypes from './actions'

const initialState = {
    isFrontEnd: true,
    userDetails: {
        userName: '',
        email: '',
        group: '',
        userPrv: [],
        groupDescription: '',
        userMobile: '',
        userAddress1: '',
        userAddress2: '',
        userAddress3: '',
        userDescription: '',
        userProfilePic: '',
    },
    isSlideDrawerOpen: true,
    reportData: {
        fields: {
            "index": "Index",
            "guid": "GUID"
        },
        data: [
            { index: 0, guid: 'asdf231234' },
            { index: 1, guid: 'wetr2343af' }
        ]
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ISFRONTEND:
            return {
                ...state,
                isFrontEnd: action.val
            };
        case actionTypes.CHANGEUSER:
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    userName: action.userName,
                    email: action.email,
                    group: action.group,
                    userPrv: action.userPrv,
                    groupDescription: action.groupDescription,
                    userMobile: action.userMobile,
                    userAddress1: action.userAddress1,
                    userAddress2: action.userAddress2,
                    userAddress3: action.userAddress3,
                    userDescription: action.userDescription,
                    userProfilePic: action.userProfilePic,
                }
            };
        case actionTypes.SLIDEDRAWER:
            return {
                ...state,
                isSlideDrawerOpen: state.isSlideDrawerOpen ? false : true
            }
        case actionTypes.REPORTDATA:
            return {
                ...state,
                reportData: {
                    ...state.reportData,
                    fields: action.fields,
                    data: action.data
                }
            }
        default:
            return state;
    }
};

export default reducer;