import React, { useState, useEffect } from 'react'
import firebase from '../../fbConfig/fbConfig'
import defItemImage from '../../assets/images/addNewimage.png'

const BackendCreateItems = props => {

    const [createItemStatus, setCreateItemStatus] = useState('');
    const [itemImage, setItemImage] = useState({ fileForFb: defItemImage })
    const [catImage, setCatImage] = useState({ fileForFb: defItemImage })
    const [inputs, setInputs] = useState({
        name: '',
        description: '',
        weight: '',
        drycleaningPrice: '',
        washingPrice: '',
        pressingPrice: '',
        category: '',
        categoryDescription: '',
        categoryImgUrl: '',
        image: '',
        itemCrDate: '',
        itemCrBy: ''
    });
    const [categoriesForSearch, setCategoriesForSearch] = useState([]);
    const [catFilter, setCatFilter] = useState('');

    const createItem = () => {
        var uploadTask = firebase.storage().ref('lmatWeb/itemImages/' + inputs.name).put(itemImage.fileForFb);
        uploadTask.on('state_changed',
            function progress(snapshot) {
                setCreateItemStatus('Uploading images...');
            },
            function error(err) {
                setCreateItemStatus('Error: ' + err);
            },
            function complete() {
                uploadTask.snapshot.ref.getDownloadURL()
                    .then(function (downloadURL) {
                        var uploadTask2 = firebase.storage().ref('lmatWeb/itemCategoryImages/' + inputs.category).put(URL.revokeObjectURL(catImage.fileForFb));
                        uploadTask2.on('state_changed',
                            function progress(snapshot) {
                                setCreateItemStatus('Uploading images...');
                            },
                            function error(err) {
                                setCreateItemStatus('Error: ' + err);
                            },
                            function complete() {
                                uploadTask2.snapshot.ref.getDownloadURL()
                                    .then(function (downloadURL2) {
                                        firebase.firestore().collection('item').doc().set({ ...inputs, image: downloadURL, categoryImgUrl: downloadURL2 })
                                            .then(function () {
                                                setCreateItemStatus('Successfully added the details');
                                            })
                                            .catch(error => {
                                                setCreateItemStatus(error);
                                            })
                                    })
                            })
                    })
            }
        )
    }

    const removeDuplicates = (arr) => {
        return [...new Map(arr.map(item => [item.category, item])).values()]
    }

    const loadCatForSearch = () => {
        const arr = [];
        firebase.firestore().collection('item').get()
            .then(function (snap) {
                snap.forEach(element => {
                    arr.push({ category: element.data().category, categoryDescription: element.data().categoryDescription, categoryImgUrl: element.data().categoryImgUrl });
                });
                setCategoriesForSearch(removeDuplicates(arr));
            })
            .catch(error => {
                console.log(error);
            })
    }

    const addItemImgHandler = event => {
        setItemImage({ fileForFb: URL.createObjectURL(event.target.files[0]), fileName: event.target.files[0].name });
    }

    const addCatImgHandler = event => {
        setCatImage({ fileForFb: URL.createObjectURL(event.target.files[0]), fileName: event.target.files[0].name });
    }

    const onValueChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    const handleFilterText = (e) => {
        setCatFilter(e.target.value);
    }

    const handleCategorySelect = (data) => {
        setInputs({ ...inputs, category: data.category, categoryDescription: data.categoryDescription, categoryImgUrl: data.categoryImgUrl });
        setCatImage({ ...catImage, fileForFb: data.categoryImgUrl })
    }

    const resetButtonHandler = () => {
        setCreateItemStatus('');
        setItemImage({ fileForFb: defItemImage })
        setCatImage({ fileForFb: defItemImage })
        setInputs({
            name: '',
            description: '',
            weight: '',
            drycleaningPrice: '',
            washingPrice: '',
            pressingPrice: '',
            category: '',
            categoryDescription: '',
            categoryImgUrl: '',
            image: '',
            itemCrDate: '',
            itemCrBy: ''
        });
        setCategoriesForSearch([]);
        setCatFilter('');
    }
    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">Item Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" >
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Create new Item</div>
                                            <div class="card-category">Fill below fields to create new items</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="input-group mb-3 justify-content-center ">
                                        <img src={itemImage.fileForFb} style={{ width: '150px', height: '150px' }} className='img-thumbnail'></img>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Upload</span>
                                        </div>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="inputGroupFile01" onChange={addItemImgHandler} />
                                            <label class="custom-file-label" for="inputGroupFile01">{itemImage.fileName ? itemImage.fileName : 'Choose file'}</label>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Item name must be unique" aria-label="Username" aria-describedby="basic-addon1" value={inputs.name} name="name" onChange={onValueChange} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" value={inputs.description} name="description" onChange={onValueChange} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item Weight</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Messure weight on grams" aria-label="Username" aria-describedby="basic-addon1" value={inputs.weight} name="weight" onChange={onValueChange} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item DryCleaning Price</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter LKR Price with 2 decimals" aria-label="Username" aria-describedby="basic-addon1" value={inputs.drycleaningPrice} name="drycleaningPrice" onChange={onValueChange} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item Washing Price</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter LKR Price with 2 decimals" aria-label="Username" aria-describedby="basic-addon1" value={inputs.washingPrice} name="washingPrice" onChange={onValueChange} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Item Pressing Price</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter LKR Price with 2 decimals" aria-label="Username" aria-describedby="basic-addon1" value={inputs.pressingPrice} name="pressingPrice" onChange={onValueChange} />
                                    </div>
                                    <div class="dropdown-divider input-group mb-5 mt-5"></div>
                                    <div class="input-group mb-3 justify-content-center">
                                        <img src={catImage.fileForFb} style={{ width: '150px', height: '150px' }} className='img-thumbnail'></img>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Upload</span>
                                        </div>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="inputGroupFile01" onChange={addCatImgHandler} />
                                            <label class="custom-file-label" for="inputGroupFile01">{catImage.fileName ? catImage.fileName : 'Choose file'}</label>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Category Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Category name must be uniqe" aria-label="Username" aria-describedby="basic-addon1" value={inputs.category} name="category" onChange={onValueChange} />
                                        <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#searchModal" onClick={loadCatForSearch}><i class="fas fa-search" ></i></button>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Category Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" value={inputs.categoryDescription} name="categoryDescription" onChange={onValueChange} />
                                    </div>
                                </div>
                                <div class="row py-3 ml-5 mr-5 justify-content-center">
                                    <p class="font-weight-bold mr-2">
                                        {createItemStatus ? createItemStatus : inputs.name ? inputs.category ? "No error has found" : "Fill Category before submit" : "Fill Item Name before submit"}
                                    </p>
                                </div>
                                <div class="row py-3 ml-5 mr-5 justify-content-center">
                                    <button type="button" class="btn btn-secondary mr-2" onClick={resetButtonHandler}>Reset</button>
                                    <button type="button" class="btn btn-primary mr-2" onClick={createItem}>Create Item</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {Modal for search} */}
                <div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog" >
                        <div class="modal-content">
                            <div class="modal-header align-center">
                                <div class="input-group ml-2" style={{ width: '80%' }}>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">Search...</span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Type keywords" aria-label="Username" aria-describedby="basic-addon1" value={catFilter} onChange={(e) => handleFilterText(e)} />
                                </div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body align-center">
                                <div class="table-responsive align-center">
                                    <table id="basic-datatables" class="display table table-striped table-hover" >
                                        <tbody>
                                            <tr>
                                                <th>Categorry</th>
                                                <th>Description</th>
                                            </tr>
                                            {categoriesForSearch.filter((data) => JSON.stringify(data).toLowerCase().indexOf(catFilter.toLowerCase()) !== -1).map((rows) => <tr style={{ cursor: 'Pointer' }} onClick={() => handleCategorySelect(rows)} data-toggle="modal" data-target="#searchModal"><td>{rows.category}</td><td>{rows.categoryDescription}</td></tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackendCreateItems;