import firebase from "../../../fbConfig/fbConfig";

export const rep_dailyHomeDeliverySummery = async (inputs) => {
  var reportRes = {
    fields: [
      "#",
      "Order ID",
      "Order Placed Date",
      "Customer Name",
      "Telephone",
      "City",
      "Amount",
    ],
    data: [],
  };
  var beginningDateObject2 = new Date(inputs.dateRange.startDate);
  var beginningDateObject = new Date(inputs.dateRange.endDate);
  if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
    beginningDateObject.setHours(23);
    beginningDateObject.setMinutes(59);
    beginningDateObject.setSeconds(59);
  }

  const res = await firebase
    .firestore()
    .collection("deliveryOrder")
    .where("DeliveryDate", ">", beginningDateObject2)
    .where("DeliveryDate", "<", beginningDateObject)
    .get();

  for (let a = 0; a < res.docs.length; a++) {
    var d = res.docs[a].data().createdAt.toDate();
    reportRes.data.push({
      CO_Number: res.docs[a].data().CO_Number,
      createdAt: d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear(),
      name: res.docs[a].data().name,
      phoneNumber: res.docs[a].data().phoneNumber,
      city: res.docs[a].data().city,
      NetAmount: res.docs[a].data().NetAmount,
    });
  }

  let total = 0.00;
  reportRes.data.forEach(element => {
    if (element.NetAmount != undefined)
      total = total + parseFloat(element.NetAmount);
  });
  if (total != 0.00) {
    reportRes.data.push({
      CO_Number: 'Total',
      createdAt: '',
      name: '',
      phoneNumber: '',
      city: '',
      NetAmount: total,
    })
  }

  return reportRes;
};
