import React, { useEffect, useState } from "react";
import "./OrderSelectionPage.css";
import VaveOrderRow from "../../../components/VaveOrderRows/VaveOrderRows";
import firebase from "../../../fbConfig/fbConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MsgModal from '../../../components/AlbsCmpMsgModal/AlbsCmpMsgModal'
import Axios from 'axios'


const OrderSelectionPage = (props) => {

  const [details, setDetails] = useState([]);

  const [total, setTotal] = useState(0);

  const [delCharge, setDelCharge] = useState([0, 0]);

  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);

  const [dispatchDate, setDispatchDate] = useState(null);

  const [cashPay, setCashPay] = useState(true)

  const [startDate, setStartDate] = useState(null);

  const [totalOrders, setTotalOrdes] = useState(0)



  /**
   * Refresh order details form
   */
  const restForm = () => {
    setCashPay(true)
    setDispatchDate(null)
    setSelected([])
    setDelCharge([0, 0])
    setTotal(0)
    setDetails([])
  }

  /**
   * Change orders if telephone number change
   */
  useEffect(() => {
    setTotal(0)
    setSelected([])
    if (props.cusMobile != '')
      getCustomerOrders(props.cusMobile)
    else
      restForm()
  }, [props.cusMobile])


  /**
   * Update Location price if locaiton has changed
   */
  useEffect(() => {
    firebase
      .firestore()
      .collection("location")
      .where("city", "==", props.location)
      .get()
      .then((snap) => {
        if (!snap.empty) {
          setDelCharge([
            parseInt((snap.docs[0].data().price) / 2),
            deliveryAmountOprtions.include ? parseInt((snap.docs[0].data().price) / 2) : 0,
          ]);
        }
      });
  }, [props.location])


  /**
 * Update total delivery dates if selection updates
 */
  useEffect(() => {
    setTotalOrdes(selected.filter((object, index) => selected.findIndex(obj => obj.DeliveryDate.toDateString() === object.DeliveryDate.toDateString()) === index).length);
  }, [selected])


  /**
   * Get orders related to the customers mobile using POS database
   */
  const getCustomerOrders = (tellNo) => {
    setLoading(true);
    Axios.get('/posIntegration/' + props.inputs.phoneNumber)
      .then((rsp) => {
        setLoading(false);
        if (rsp.data.status === 'Sucessfull') {
          let arr = [];
          rsp.data.data.forEach((row) => {
            const {
              CO_Number,
              dispatch,
              PossibleDate,
              NetAmount,
              collectedDate,
              item,
              qty,
            } = row;
            let x = true;
            arr.forEach((ele) => {
              if (row.CO_Number === ele.CO_Number) {
                ele.items.push({ item, qty });
                x = false;
              }
            });
            if (x) {
              arr.push({
                CO_Number,
                dispatch,
                PossibleDate: new Date(PossibleDate.substr(0, 10)),
                DeliveryDate: new Date(PossibleDate.substr(0, 10)),
                NetAmount,
                collectedDate,
                items: [{ item, qty }],
              });
              if (dispatch) {
                setDispatchDate(new Date(PossibleDate.substr(0, 10)));
                setStartDate(new Date(PossibleDate.substr(0, 10)));


              }
            }
          });
          setDetails(arr);
        } else {
          setLoading(false)
          notificationToggler('error', 'No orders have found.', null);
        }
      })
      .catch((error) => {
        setLoading(false)
        notificationToggler('error', error.message, null);
      });

    firebase
      .firestore()
      .collection("location")
      .where("city", "==", props.inputs.city)
      .get()
      .then((snap) => {
        if (!snap.empty)
          setDelCharge([
            parseInt(snap.docs[0].data().price / 2),
            parseInt(snap.docs[0].data().price / 2),
          ]);
      }).catch(error => {
        setLoading(false)
        notificationToggler('error', error.message, null);
      })
  }


  /**
  * Update dates
  */
  const updateDate = (orderNumber, date) => {
    setDetails(
      details.map((row) => {
        if (row.CO_Number === orderNumber)
          return { ...row, DeliveryDate: date };
        else return row;
      })
    );
    setSelected(
      selected.map((row) => {
        if (row.CO_Number === orderNumber)
          return { ...row, DeliveryDate: date };
        else return row;
      })
    );
  };


  /**
   * Update dispatch date 
   */
  const updateDispatchDate = (date) => {
    setStartDate(() => date);
    setDetails(
      details.map((row) => {
        if (row.dispatch) return { ...row, DeliveryDate: date };
        else return row;
      })
    );
    setSelected(
      selected.map((row) => {
        if (row.dispatch) return { ...row, DeliveryDate: date };
        else return row;
      })
    );
  };


  const checkSelected = (obj) => {
    let x = false;
    selected.forEach((ele) => {
      if (ele.CO_Number === obj.CO_Number) x = true;
    });
    return x;
  };


  const handleCheck = (e, obj) => {
    if (e.target.checked) {
      setSelected((cur) => [...cur, obj]);
      setTotal((cur) => cur + parseInt(obj.NetAmount));
    } else {
      setSelected((cur) => cur.filter((ref) => JSON.stringify(ref) !== JSON.stringify(obj)));
      setTotal((cur) => cur - parseInt(obj.NetAmount));
    }
  };


  /**
   * Async function for add items to an order
   */
  const addOrderItems = async (customerDetails, orderId, order) => {
    let batch = firebase.firestore().batch();
    order.items.forEach((obj) => {
      batch.set(
        firebase.firestore().collection("deliveryOrder").doc(),
        {
          customerId: customerDetails.customerRefId,
          paymentType: cashPay ? 'Cash' : 'Card',
          payment: "pending",
          orderId: orderId.uniqueOrder,
          generatedId: orderId.generatedId,
          ...obj,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          name: customerDetails.name,
          phoneNumber: customerDetails.phoneNumber,
          houseNo: customerDetails.houseNo,
          street: customerDetails.street,
          city: customerDetails.city,
          crBy: firebase.auth().currentUser.email
        }
      );
    });
    await batch.commit()
    return true
  }


  /**
   * Async functions for add orders into OrderHeader
   */
  const addOrderUniqueData = async (customerDetails, order) => {
    let uniqueOrder = await firebase.firestore().collection("deliveryUnique")
      .add({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        paymentType: cashPay ? 'Cash' : 'Card',
        customerId: customerDetails.customerRefId,
        payment: "pending",
        name: customerDetails.name,
        phoneNumber: customerDetails.phoneNumber,
        houseNo: customerDetails.houseNo,
        street: customerDetails.street,
        city: customerDetails.city,
        quantity: order.items.length,
        subtot: order.subTotal,
        item: order.items.map((obj) => { return { ...obj }; }),
        delCharge: delCharge[1],
        deliveryDate: order.DeliveryDate,
        crBy: firebase.auth().currentUser.email
      })

    let countRef = firebase.firestore().collection("deliveryUnique").doc("--stats--");
    let orderRef = firebase.firestore().collection("deliveryUnique").doc(uniqueOrder.id);

    let idGen;
    await firebase.firestore().runTransaction((trx) => {
      return trx.get(countRef).then((countDoc) => {
        if (!countDoc.exists) throw Error("Document does not exist!");
        const nextCount = countDoc.data().count + 1;
        idGen = "00000000" + nextCount;
        idGen = "HD" + idGen.substr(idGen.length - 8);
        trx.update(orderRef, { generatedId: idGen });
        trx.update(countRef, { count: nextCount });
      });
    })

    if (uniqueOrder != undefined) {
      return { uniqueOrder: uniqueOrder.id, generatedId: idGen }
    } else {
      return false
    }
  }


  /**
   * Async function for save customer details
   */
  const addCustomerDataToDb = async () => {
    const { phoneNumber, email, name, houseNo, street, city, landmark, customerRefId } = props.inputs;
    let customerDoc;
    if (customerRefId == null) {
      customerDoc = await firebase.firestore().collection("customer").add({
        phoneNumber, email, name, houseNo, street, city, landmark,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        crBy: firebase.auth().currentUser.email
      })
      if (customerDoc)
        return { customerRefId: customerDoc.id, phoneNumber, email, name, houseNo, street, city, landmark }
      else
        return false
    } else {
      customerDoc = await firebase.firestore().collection("customer").doc(customerRefId).update({
        name, phoneNumber, email, houseNo, street, city, landmark,
        updatedBy: firebase.auth().currentUser.email,
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
      });
      return { customerRefId, phoneNumber, email, name, houseNo, street, city, landmark }

    }
  }


  /**
    * Send Email
    */
  const sendEmailToCustomer = async (cmDet, orderId, orderItems) => {
    try {
      let result = await Axios.post('/sendEmails/deliveryEmail', {
        orderNumber: orderId.generatedId,
        cusName: cmDet.name,
        cusTell: cmDet.phoneNumber,
        emailAdd: cmDet.email,
        houseNo: cmDet.houseNo,
        street: cmDet.street,
        city: cmDet.city,
        deliveryDate: orderItems.DeliveryDate,
        collection: orderItems.items.map((obj) => { return { ...obj }; }),
        orderAmount: parseFloat(orderItems.subTotal).toFixed(2),
        deliveryFee: parseFloat(delCharge[1]),
        totalAmount: (parseFloat(orderItems.subTotal) + parseFloat(delCharge[1])).toFixed(2),
      })
      return result
    }
    catch (err) {
      return err
    }
  }


  /**
   * Create order transaction
   */
  const createOrderTransaction = async () => {
    setLoading(true)
    if (getTotalOrders().length > 0) {
      let customerDet = await addCustomerDataToDb()
      let totalOrders = getTotalOrders().length
      if (customerDet) {
        for (let i = 0; i < totalOrders; i++) {
          let orderId = await addOrderUniqueData(customerDet, getTotalOrders()[i])
          if (orderId.uniqueOrder) {
            await addOrderItems(customerDet, orderId, getTotalOrders()[i])
            sendEmailToCustomer(customerDet, orderId, getTotalOrders()[i]).then((res) => {
            }).catch((err) => {
              console.log(err);
            })
            sendSms(customerDet.phoneNumber, "/sms/newOrder", { orderNo: orderId.generatedId })
            setLoading(false)
            notificationToggler('success', 'Order has been created successfully.', null);
            if (i + 1 == totalOrders)
              return true
          } else {
            setLoading(false)
            notificationToggler('error', 'Order cannot be completed.', null);
            break
          }
        }
      }
      else {
        setLoading(false)
        notificationToggler('error', 'Customer cannot be updated.Transaction faild.', null);
      }
    } else {
      setLoading(false)
      notificationToggler('error', 'Please add at least one item to checkout.', null);
    }
    return false
  }


  //Send SMS 
  const sendSms = async (mobile, link, data) => {
    let ts = Date.now();
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes2 = date_ob.getMinutes() + 2;
    let minutes = date_ob.getMinutes();
    Axios.post(link, {
      phoneNumber: mobile,
      ...data,
      s_time: year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + "00",
      e_time: year + "-" + month + "-" + date + " " + hours + ":" + minutes2 + ":" + "00"
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }


  /**
   * Get all the unique delivery day orders from selected orders
   */
  const getTotalOrders = () => {
    let lOrder = []
    selected.filter((object, index) => selected.findIndex(obj => obj.DeliveryDate.toDateString() === object.DeliveryDate.toDateString()) === index)
      .forEach(obj => {
        lOrder.push({
          DeliveryDate: obj.DeliveryDate,
          items: selected.filter((oldObj) => oldObj.DeliveryDate.toDateString() === obj.DeliveryDate.toDateString()),
          subTotal: selected.filter((oldObj) => oldObj.DeliveryDate.toDateString() === obj.DeliveryDate.toDateString()).map((item) => { return item.NetAmount }).reduce((a, b) => a + b, 0)
        })
      })
    return lOrder
  }


  /**
   * Handle Checkout
   */
  const checkoutHandler = async () => {
    if (!props.inputs.phoneNumber) {
      notificationToggler('error', 'Enter customer mobile number.', null)
    } else if (!props.inputs.city) {
      notificationToggler('error', 'Incomplete customer address.', null)
    } else if (selected.length == 0) {
      notificationToggler('error', 'Select at least one order.', null)
    }
    else {
      notificationToggler('confirmation', 'Are you sure?', 'insert')
    }
  }

  ////////////////////////////////////////////////////////////////
  /**
   * 
   * @param {string} notificationType 
   * @param {string} message 
   * @param {string} crudOperationType 
   */
  const notificationToggler = (notificationType, message, crudOperationType) => {
    if (notificationType == null && message == null && crudOperationType == null) {
      setNotification((cur) => ({
        notificationShow: false,
        notification: message,
        confirmed: false,
        type: notificationType,
        crudOperationType: crudOperationType
      }))
    } else {
      setNotification((cur) => ({
        notificationShow: true,
        notification: message,
        confirmed: false,
        type: notificationType,
        crudOperationType: crudOperationType
      }))
    }

  }

  /**
   * Get Confirmation
   */
  const afterConfirm = async () => {
    switch (notifiaction.crudOperationType) {
      case 'read':
        break;
      case 'insert':
        if (await createOrderTransaction()) {
          restForm()
          props.refreshPage()
        }
        break;
      case 'update':
        break;
      case 'delete':
        break;
      default:
        break;
    }
  }

  //Notification details
  const [notifiaction, setNotification] = useState({
    notificationShow: false,
    notification: '',
    crudOperationType: '',
    type: ''
  })
  ////////////////////////////////////////////////////////////////



  const [deliveryAmountOprtions, setDeliveryAmountOptions] = useState({
    include: true,
    exclude: false
  })

  /**
   * Change delivery charge apply mode
   */
  const changeDeliveryFeeFormat = (method) => {
    if (method == 'include') {
      setDeliveryAmountOptions(cur => ({ include: true, exclude: false }))
      setDelCharge(cur => [cur[0], cur[0]])
    } else {
      setDeliveryAmountOptions(cur => ({ include: false, exclude: true }))
      setDelCharge(cur => [cur[0], 0])
    }
  }


  return (
    <div className='row w-100'>
      <div className='col-12 col-lg-8'>
        <div className='row'>
          <div className='col-12 col-md-12'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-1 text-center'>
                <i className="fas fa-file-contract fa-2x"></i>
              </div>
              <div className='col'>
                <div className="mb-1">
                  <b>Select orders from below</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-2" style={{ borderColor: "#707070" }} />
        <div className="row order-selection-div w-100 mx-1 py-1">
          <div className="col-xs-12 col-sm-6 align-items-center">
            Ready to collect
          </div>
          <div className="col-xs-12 col-sm-6 d-flex justify-content-sm-end align-items-center">
            {details.find((element) => element.dispatch) && (
              <DatePicker
                className="rounded"
                minDate={dispatchDate}
                selected={startDate}
                onChange={updateDispatchDate}
                placeholderText="Select date"
              />
            )}
          </div>
        </div>
        {loading ? (
          <div className='text-center'>
            <CircularProgress />
          </div>

        ) : !details.find((element) => element.dispatch) ? (
          <h5 className='mx-1 my-2'>No Ready to collect orders</h5>
        ) : (
          details.map((obj) => {
            if (obj.dispatch)
              return (
                <VaveOrderRow
                  dispatch={obj.dispatch}
                  updateDate={updateDate}
                  checked={checkSelected(obj)}
                  items={[...obj.items]}
                  detObj={{ ...obj }}
                  onChange={handleCheck}
                  key={obj.CO_Number}
                  orderNumber={obj.CO_Number}
                  OrderDate={obj.collectedDate.substr(0, 10)}
                  amount={obj.NetAmount}
                  delDate={obj.PossibleDate}
                />
              );
            else return null;
          })
        )}
        <div className="row order-selection-div w-100 mx-1 py-1">
          <div className="col-xs-12 col-sm-6 align-items-center">
            Still washing
          </div>
          <div className="col-xs-12 col-sm-6"></div>
        </div>
        {loading ? (
          <div className='text-center'>
            <CircularProgress />
          </div>
        ) : !details.find((element) => !element.dispatch) ? (
          <h5 className='mx-1 my-2'>No ongoing orders</h5>
        ) : (
          details.map((obj) => {
            if (!obj.dispatch)
              return (
                <VaveOrderRow
                  dispatch={obj.dispatch}
                  updateDate={updateDate}
                  checked={checkSelected(obj)}
                  items={[...obj.items]}
                  detObj={{ ...obj }}
                  onChange={handleCheck}
                  key={obj.CO_Number}
                  orderNumber={obj.CO_Number}
                  OrderDate={obj.collectedDate.substr(0, 10)}
                  amount={obj.NetAmount}
                  delDate={obj.PossibleDate}
                />
              );
            else return null;
          })
        )}
      </div>
      <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
        <div className='row'>
          <div className='col-12 col-md-12'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-1 text-center'>
                <i className="fas fa-file-contract fa-2x"></i>
              </div>
              <div className='col'>
                <div className="mb-1">
                  <b>Billing Summary</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-2" style={{ borderColor: "#707070" }} />
        <table className="table-sm table-striped w-100">
          <tbody>
            <tr>
              <td>Total Delivery Amount</td>
              <td>{delCharge[1].toFixed(2) * totalOrders}</td>
            </tr>
            <tr>
              <td>Number of orders</td>
              <td>{selected.length}</td>
            </tr>
            <tr>
              <td>Total order amount</td>
              <td>{total !== 0 ? (total).toFixed(2) : "0.00"}</td>
            </tr>
            <tr style={{ height: '50px', fontWeight: 'bold' }}>
              <td>Total</td>
              <td>{total !== 0 ? (parseInt(total) + parseInt(delCharge[1].toFixed(2) * totalOrders)).toFixed(2) : "0.00"}</td>
            </tr>
            <tr>
              <td colSpan='2' style={{ backgroundColor: '#004B90', color: 'white' }}>
                Delivery Charges
              </td>
            </tr>
            <tr>
              <td colSpan='2' className='row'>
                <div className="input-group py-2 w-100">
                  <div className="col-12 input-group-prepend ml-md-2">
                    <input checked={deliveryAmountOprtions.include} onChange={() => changeDeliveryFeeFormat('include')} className='mr-1' type="radio" aria-label="Radio button for following text input" />
                    <lable>Include Delivery Fee</lable>
                  </div>
                  <div className="col-12 input-group-prepend ml-md-2">
                    <input checked={deliveryAmountOprtions.exclude} onChange={() => changeDeliveryFeeFormat('exclude')} className='mr-1' type="radio" aria-label="Radio button for following text input" />
                    <label>Exclude Delivery Fee</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan='2' style={{ backgroundColor: '#004B90', color: 'white' }}>
                Payment Method
              </td>
            </tr>
            <tr>
              <td colSpan='2' className='row'>
                <div className="input-group py-2 w-100">
                  <div className="col-12 input-group-prepend ml-md-2">
                    <input checked={cashPay} onChange={() => setCashPay((cur) => !cur)} className='mr-1' type="radio" aria-label="Radio button for following text input" />
                    Pay on delivery - Cash
                  </div>
                  <div className="col-12 input-group-prepend ml-md-2">
                    <input checked={!cashPay} onChange={() => setCashPay((cur) => !cur)} className='mr-1' type="radio" aria-label="Radio button for following text input" />
                    Pay on delivery - Card
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan='2' className='text-center'>
                <button onClick={checkoutHandler} className="btn btn-danger btn-sm w-100" type="submit">Checkout</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <MsgModal
        show={notifiaction.notificationShow}
        onHide={() => notificationToggler(null, null, null)}
        message={notifiaction.notification}
        type={notifiaction.type}
        confirmBtnOnClick={afterConfirm}
        loading={loading}
      />
    </div>
  );
};

export default OrderSelectionPage;
