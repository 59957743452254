import React, { useEffect } from 'react';
import './VaveBackEndNavBar.css'
import LmatLogo from '../../assets/images/logo/white-logo@2x.png'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import firebase from '../../fbConfig/fbConfig'
import UserImage from '../../assets/images/admin.png'


const VaveBackEndNavBar = (props) => {
    const history = useHistory();
    const signOutHandler = () => {
        firebase.auth()
            .signOut()
            .then(() => {
                props.deactivateBakend();
                props.removeUser();
                history.push('/login');
            })
    }

    return (
        <div className="main-header" hidden={props.hidden}>
            <div className="logo-header" style={{ backgroundColor: '#004B90' }}>
                <div className="nav-toggle">
                    <button className="btn btn-toggle toggle-sidebar" onClick={props.openSb}>
                        <i className="icon-menu"></i>
                    </button>
                </div>
                <a href="index.html" className="logo">
                    <img src={LmatLogo} alt="navbar brand" className="navbar-brand" style={{ width: '100px', height: '40px' }} />
                </a>
                <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" href="#slBar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <i className="icon-menu"></i>
                    </span>
                </button>
                <button className="topbar-toggler more"><i className="icon-options-vertical"></i></button>
                <div className="nav-toggle">
                    <button className="btn btn-toggle toggle-sidebar" onClick={props.trigerSlideDrawer}>
                        <i className="icon-menu"></i>
                    </button>
                </div>
            </div>

            <nav className="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
                <div className="container-fluid">
                    <div className="collapse" id="search-nav">
                        <form className="navbar-left navbar-form nav-search mr-md-3">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="submit" className="btn btn-search pr-1">
                                        <i className="fa fa-search search-icon"></i>
                                    </button>
                                </div>
                                <input type="text" placeholder="Search ..." className="form-control" />
                            </div>
                        </form>
                    </div>
                    <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                        <li className="nav-item toggle-nav-search hidden-caret">
                            <a className="nav-link" data-toggle="collapse" href="#search-nav" role="button" aria-expanded="false" aria-controls="search-nav">
                                <i className="fa fa-search"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown hidden-caret collapse">
                            <a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
                                <i className="icon-layers"></i>
                            </a>
                            <div className="dropdown-menu quick-actions quick-actions-info animated fadeIn">
                                <div className="quick-actions-header">
                                    <span className="title mb-1">Quick Actions</span>
                                    <span className="subtitle op-8">Shortcuts</span>
                                </div>
                                <div className="quick-actions-scroll scrollbar-outer">
                                    <div className="quick-actions-items">
                                        <div className="row m-0">
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-file-1"></i>
                                                    <span className="text">Pickup and Delivery Report</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-add-user"></i>
                                                    <span className="text">Add New Customer</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-placeholder-1"></i>
                                                    <span className="text">Add New location</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-box-1"></i>
                                                    <span className="text">Create New Item</span>
                                                </div>
                                            </a>
                                            <a className="col-6 col-md-4 p-0" href="#">
                                                <div className="quick-actions-item">
                                                    <i className="flaticon-delivery-truck"></i>
                                                    <span className="text">Add New Route</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown hidden-caret">
                            <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
                                <div className="avatar-sm">
                                    <img src={UserImage} alt="..." className="avatar-img rounded-circle" />
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-user animated fadeIn">
                                <div className="dropdown-user-scroll scrollbar-outer">
                                    <li>
                                        <div className="user-box">
                                            <div className="avatar-lg"><img src={UserImage} alt="image profile" className="avatar-img rounded" /></div>
                                            <div className="u-text">
                                                <h4>{props.userDetails.userName}
                                                </h4>
                                                <p className="text-muted">{props.userDetails.email}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider"></div>
                                        <Link to="/backendUserProfile">
                                            <span className="dropdown-item">My Profile</span>
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Settings</a>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item" onClick={signOutHandler}>Logout</button>
                                    </li>
                                </div>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        userDetails: state.userDetails,
    };
}

const mapDespatchToProps = dispatch => {
    return {
        deactivateBakend: () => dispatch({ type: 'ISFRONTEND', val: true }),
        removeUser: () => dispatch({ type: 'CHANGEUSER', userName: '', userEmail: '', userGroup: '', userPrv: [{}] }),
        trigerSlideDrawer: () => dispatch({ type: 'SLIDEDRAWER' })
    };
}



export default connect(mapStateToProps, mapDespatchToProps)(VaveBackEndNavBar);