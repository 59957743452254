import React, { useState, useEffect } from 'react'
import firebase from '../../fbConfig/fbConfig'
import MsgModal from '../../components/AlbsCmpMsgModal/AlbsCmpMsgModal'
import validator from 'validator'

const BackendNewUsers = props => {

    //Whole inputs
    const [inputs, setInputs] = useState({
        userName: '',
        userDescription: '',
        userMobile: '',
        email: '',
        userAddress1: '',
        userAddress2: '',
        userAddress3: '',
        group: '',
        groupDescription: '',
        userActive: true
    });

    //Circular progress bar on
    const [loading, setLoading] = useState(false);

    //Show user groups 
    const [userGroupsList, setUserGroupsList] = useState([]);

    //Add user group details into text boxes
    const rowOnClick = (group, groupDescription) => {
        setInputs((current) => ({ ...current, group, groupDescription }));
    }

    //Values change in text box
    const inputChange = (e) => {
        e.persist();
        setInputs((current) => ({ ...current, [e.target.name]: e.target.value }))
    }

    //Add data set into firebase
    const addDataToFirebase = () => {
        let message = validationFunction()
        if (!message) {
            notificationToggler('confirmation', 'Are you sure?', 'insert');
        } else {
            notificationToggler('error', message, null);
        }
    }

    const validationFunction = () => {
        if (!validator.isMobilePhone(inputs.userMobile))
            return "Enter valid mobile number"
        if (!validator.isEmail(inputs.email))
            return "Enter valid email"
        return false
    }

    const insertFunction = () => {
        setLoading(true)
        firebase.firestore().collection('user').add(inputs)
            .then(() => {
                resetButtonHandler();
                notificationToggler('success', 'User has created successfuly.', null);
                setLoading(false)
            })
            .catch((err) => {
                notificationToggler('error', err.message, null);
                setLoading(false)
            })
    }

    //Reset all the fields 
    const resetButtonHandler = () => {
        setInputs({
            userName: '',
            userDescription: '',
            userMobile: '',
            email: '',
            userAddress1: '',
            userAddress2: '',
            userAddress3: '',
            group: '',
            groupDescription: ''
        });
    }

    ////////////////////////////////////////////////////////////////
    /**
     * 
     * @param {string} notificationType 
     * @param {string} message 
     * @param {string} crudOperationType 
     */
    const notificationToggler = (notificationType, message, crudOperationType) => {
        if (notificationType == null && message == null && crudOperationType == null) {
            setNotification((cur) => ({
                notificationShow: false,
                notification: message,
                confirmed: false,
                type: notificationType,
                crudOperationType: crudOperationType
            }))
        } else {
            setNotification((cur) => ({
                notificationShow: true,
                notification: message,
                confirmed: false,
                type: notificationType,
                crudOperationType: crudOperationType
            }))
        }

    }

    /**
     * Get Confirmation
     */
    const afterConfirm = async () => {
        switch (notifiaction.crudOperationType) {
            case 'read':
                break;
            case 'insert':
                insertFunction()
                break;
            case 'update':
                break;
            case 'delete':
                break;
            default:
                break;
        }
    }

    //Notification details
    const [notifiaction, setNotification] = useState({
        notificationShow: false,
        notification: '',
        crudOperationType: '',
        type: ''
    })
    ////////////////////////////////////////////////////////////////


    const getUserGroups = () => {
        setUserGroupsList(() => []);
        firebase.firestore().collection('userGroups').get()
            .then((docs) => {
                docs.forEach((doc) => {
                    setUserGroupsList((current) => ([...current, { group: doc.data().groupName, groupDescription: doc.data().groupDescription }]))
                })
            })
    }


    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">User Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" >
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Add new user</div>
                                            <div class="card-category">Fill below fields to add a new user</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="card-category mb-2">General details</div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Add name with Innecials" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userName" value={inputs.userName} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userDescription" value={inputs.userDescription} />
                                    </div>
                                    <div class="card-category mb-2">Contact details</div>

                                    {/* Customer contact details */}
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User mobile</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Ex:-0779492569" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userMobile" value={inputs.userMobile} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Email</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter proper email address" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="email" value={inputs.email} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Address 1</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="12/Main" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress1" value={inputs.userAddress1} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Address 2</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Apartment / Floor" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress2" value={inputs.userAddress2} />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Address 3</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="City" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress3" value={inputs.userAddress3} />
                                    </div>

                                    <div class="card-category mb-2">User Group details</div>
                                    {/* Category details */}
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Group Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Category name must be uniqe" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="group" value={inputs.group} readOnly />
                                        <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#searchModal" onClick={getUserGroups}><i class="fas fa-search"></i></button>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">User Group Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="groupDescription" value={inputs.groupDescription} readOnly />
                                    </div>

                                </div>
                                <div class="row py-3 ml-5 mr-5 justify-content-center">
                                    <button type="button" class="btn btn-secondary mr-2" onClick={resetButtonHandler}>Reset</button>
                                    <button type="button" class="btn btn-primary mr-2" onClick={addDataToFirebase}>Create User</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {Modal for search} */}
                <div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <table class="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <th scope="col text-left" >Group Name</th>
                                            <th scope="col text-left" >Group description</th>
                                        </tr>
                                        {userGroupsList.map((rows) =>
                                            <tr style={{ cursor: 'Pointer' }} data-toggle="modal" data-target="#searchModal" onClick={() => rowOnClick(rows.group, rows.groupDescription)}>
                                                <td>{rows.group}</td>
                                                <td>{rows.groupDescription}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MsgModal
                show={notifiaction.notificationShow}
                onHide={() => notificationToggler(null, null, null)}
                message={notifiaction.notification}
                type={notifiaction.type}
                confirmBtnOnClick={afterConfirm}
                loading={loading}
            />
        </div>
    )
}

export default BackendNewUsers;