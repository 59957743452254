import firebase from '../../../fbConfig/fbConfig'


export const rep_outstandingDeliveries = async (inputs) => {
    var reportRes = {
        fields: ['#', 'Order ID', 'Order type', 'Order Date', 'Delivery Date', 'Customer Name', 'Total Amount'],
        data: []
    };
    var beginningDateObject2 = new Date(inputs.dateRange.startDate);
    var beginningDateObject = new Date(inputs.dateRange.endDate);
    if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
        beginningDateObject.setHours(23);
        beginningDateObject.setMinutes(59);
        beginningDateObject.setSeconds(59);
    }
    const res = await firebase.firestore().collection('deliveryUnique').where('deliveryDate', '>', beginningDateObject2).where('deliveryDate', '<', beginningDateObject).get()
    for (let a = 0; a < res.docs.length; a++) {
        if (res.docs[a].data().deliveryComplete != true) {
            var d = res.docs[a].data().createdAt.toDate();
            var d2 = res.docs[a].data().deliveryDate.toDate();
            reportRes.data.push({
                CO_Number: res.docs[a].data().generatedId,
                ordertype: 'HD',
                createdAt: d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
                deliveryDate: d2.getDate() + '/' + (d2.getMonth() + 1) + '/' + d2.getFullYear(),
                name: res.docs[a].data().name,
                NetAmount: (parseFloat(res.docs[a].data().subtot) + parseFloat(res.docs[a].data().delCharge)).toFixed(2)
            })
        }
    }

    const res2 = await firebase.firestore().collection('order').where('deliveryDate', '>', beginningDateObject2).where('deliveryDate', '<', beginningDateObject).get()
    for (let a = 0; a < res2.docs.length; a++) {

        if (res2.docs[a].data().deliveryComplete != true) {
            var d = res2.docs[a].data().orderDate.toDate();
            var d2 = res2.docs[a].data().deliveryDate.toDate();
            reportRes.data.push({
                CO_Number: res2.docs[a].data().generatedId,
                ordertype: 'PD',
                createdAt: d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
                deliveryDate: d2.getDate() + '/' + (d2.getMonth() + 1) + '/' + d2.getFullYear(),
                name: res.docs[a].data().name,
                NetAmount: (parseFloat(res2.docs[a].data().subtot) + parseFloat(res2.docs[a].data().delCharge)).toFixed(2)
            })
        }
    }

    let total = 0.00;
    reportRes.data.forEach(element => {
        console.log(element.NetAmount);
        if (element.NetAmount != undefined)
            total = parseFloat(total) + parseFloat(element.NetAmount);
    });
    if (total != 0.00) {
        reportRes.data.push({
            CO_Number: "Total",
            ordertype: '',
            createdAt: '',
            deliveryDate: '',
            name: '',
            NetAmount: (total).toFixed(2)
        })
    }


    return (reportRes)
}