import React from "react";
import "./VaveCard.css";

const VaveCard = (props) => {
  return (
    <div style={{ width: props.tWidth }} className="vaveCard_constiner">
      <div className="vaveCard_titlebar">
        <div className="vaveCard_titlebar_title">
          <p className="vaveCard_titlebar_title_text">{props.title}</p>
        </div>
        <div className="vaveCard_titlebar_image">
          <img alt="" className="vaveCard_titlebar_img" src={props.src} />
        </div>
      </div>
      <div className="vaveCard_subTitlebar">
        <p className="vaveCard_subTitlebar_text">{props.subTitle}</p>
      </div>
      <div className="vaveCard_children">{props.children}</div>
    </div>
  );
};

export default VaveCard;
