import React, { useState, useEffect } from 'react'
import firebase from '../../fbConfig/fbConfig'
import proPic from '../../assets/images/admin.png'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const BackendUserProfile = props => {

    const history = useHistory();

    //Whole inputs
    const [inputs, setInputs] = useState({
        userName: props.userDetails.userName,
        email: props.userDetails.email,
        group: props.userDetails.group,
        groupDescription: props.userDetails.groupDescription,
        userMobile: props.userDetails.userMobile,
        userAddress1: props.userDetails.userAddress1,
        userAddress2: props.userDetails.userAddress2,
        userAddress3: props.userDetails.userAddress3,
        userDescription: props.userDetails.userDescription,
    })

    //Values change in text box
    const inputChange = (e) => {
        e.persist();
        setInputs((current) => ({ ...current, [e.target.name]: e.target.value }))
    }

    //ErrorHandler
    const ErrorHandler = () => {
        if (inputs.userName == "") {
            alert('Enter userName');
            return 0;
        }
        else {
            return 1;
        }
    }

    //Update data set into firebase
    const addDataToFirebase = () => {
        firebase.firestore().collection('user').where('email', '==', inputs.email).get()
            .then((result) => {
                result.forEach((doc) => {
                    if (ErrorHandler) {
                        firebase.firestore().collection('user').doc(doc.ref.id).update(inputs)
                            .then(() => {
                                alert('Successfully updated')
                            })
                            .catch((err) => {
                                alert(err);
                            })
                    }
                })
            })
    }

    useEffect(() => {
        if (!(props.userDetails.email)) {
            history.push('/adminLogin')
        }
    }, [])

    return (
        <div className="content">
            <div className="panel-header bg-primary-gradient">
                <div className="page-inner py-5">
                    <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div className="mr-3">
                            <div className="input-group mb-3 justify-content-center">
                                <img src={proPic} style={{ width: '100px', height: '100px' }}></img>
                            </div>
                            <div className="input-group mb-3">
                            </div>
                        </div>
                        <div className="ml-2">
                            <h2 className="text-white pb-2 fw-bold">{props.userDetails.userName}</h2>
                            <h5 className="text-white op-7 mb-2">{props.userDetails.group}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-inner mt--5">
                <div className="row mt--1 justify-content-center">
                    <div className="col-lg-6 position-sticky" >
                        <div className="card">
                            <div className="card-body">
                                <div className="card-header row py-3" >
                                    <ul className="nav nav-pills card-header-pills">
                                        <li className="nav-item">
                                            <div className="card-title">Edit your details</div>
                                            <div className="card-category">You can edit your details from below</div>
                                        </li>
                                    </ul>

                                    <div className="input-group mb-3 mt-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User Email</span>
                                        </div>
                                        <input type="text" className="form-control" aria-describedby="basic-addon1" onChange={inputChange} name="email" value={inputs.email} readOnly />
                                    </div>
                                </div>
                                <div className="row py-3 ml-5 mr-5">
                                    <div className="card-category mb-2">General details</div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User Name</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Add name with Innecials" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userName" value={inputs.userName} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User description</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userDescription" value={inputs.userDescription} />
                                    </div>
                                    <div className="card-category mb-2">Contact details</div>

                                    {/* Customer contact details */}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User mobile</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Ex:-0779492569" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userMobile" value={inputs.userMobile} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Address 1</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="12/Main" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress1" value={inputs.userAddress1} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Address 2</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Apartment / Floor" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress2" value={inputs.userAddress2} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Address 3</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Apartment / Floor" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="userAddress3" value={inputs.userAddress3} />
                                    </div>

                                    <div className="card-category mb-2">User Group details</div>
                                    {/* Category details */}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User Group Name</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Category name must be uniqe" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="group" value={inputs.group} readOnly />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">User Group Description</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" onChange={inputChange} name="groupDescription" value={inputs.groupDescription} readOnly />
                                    </div>
                                </div>
                                <div className="row py-3 ml-5 mr-5 justify-content-center">
                                    <button type="button" className="btn btn-primary mr-2" onClick={addDataToFirebase}>Update Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userDetails: state.userDetails
    }
}

export default connect(mapStateToProps, null)(BackendUserProfile);