import React, { useState } from 'react'
import firebase from '../../fbConfig/fbConfig'
import { useHistory } from 'react-router-dom'

const RowRouteGroup = rowProps => {
    return (
        <div class="shadow-sm p-1 mb-1 mt-1 bg-white rounded row" type="button">
            <div className="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Route Name</div>
                {rowProps.gName}
            </div>
            <div class="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Description</div>
                {rowProps.description}
            </div>
            <div class="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Discount Amt.</div>
                {rowProps.discountAmount}
            </div>
            <div class="col-2">
                <div style={{ fontSize: '12px', color: 'black' }}>Discount %</div>
                {rowProps.discountPres}
            </div>
            <div class="col-2">
                <div style={{ fontSize: '12px', color: 'black' }}>Del. Charge</div>
                {rowProps.delCharge}
            </div>
        </div>
    )
}

const RowLocations = rowProps => {
    return (
        <div class="shadow-sm p-1 mb-1 mt-1 bg-white rounded row " type="button">
            <div class="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Location</div>
                {rowProps.gName}
            </div>
            <div class="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Description</div>
                {rowProps.description}
            </div>
            <div class="col">
                <div style={{ fontSize: '12px', color: 'black' }}>Discount Amount</div>
                {rowProps.discountAmount}
            </div>
            <div class="col-2">
                <div style={{ fontSize: '12px', color: 'black' }}>Discount %</div>
                {rowProps.discountPres}
            </div>
            <div class="col-2">
                <div style={{ fontSize: '12px', color: 'black' }}>Route</div>
                {rowProps.routeName}
            </div>
        </div>
    )
}

const rowSelecter = (type, routeDatas, filt) => {
    if (type === 'location') {
        return (<div class="container">
            {routeDatas.map((data) => <RowLocations key={data.name} gName={data.name} description={data.description} discountAmount={data.discountAmount} discountPres={data.discountPres} routeName={data.routeCode} />)}
        </div>)

    }
    else {
        return (<div class="container">
            {routeDatas.map((data) => <RowRouteGroup key={data.routeCode} gName={data.routeCode} description={data.routeDescription} discountAmount={data.routeDiscountAmount} discountPres={data.routeDiscountPress} delCharge={data.routeDeliCharge} />)}
        </div>)
    }
}

const BackendLocations = props => {

    const [dataSet, setdataSet] = useState([]);
    const history = useHistory();
    const [buttonSelector, setButtonSelector] = useState('none');
    const [confirmAlert, setConfirmAlert] = useState();
    const [selectedField, setSelectedField] = useState([]);



    let createNewForm;
    let editorTitile;
    let editorDescription;
    var locationDetails = {
        name: '',
        description: '',
        geoTag: '',
        discountAmount: '',
        discountPres: '',
        routeCode: '',
        routeDescription: '',
        routeDiscountAmount: '',
        routeDiscountPress: '',
        routeDeliCharge: '',
        crDate: Date.now(),
        crBy: ''
    }

    const routeData = () => {
        firebase.firestore().collection('testLocation').get().then((snap) => {
            const arr = []
            snap.forEach((doc) => {
                arr.push(doc.data());
            })
            setdataSet(arr.sort());
            const arr2 = [{ name: 'Route Name' }, { name: 'Delivery Charge' }]
            setSelectedField(arr2)

        }).catch(error => console.log(error))
        setButtonSelector('route')


    }

    const locationData = () => {
        firebase.firestore().collection('testLocation').get().then((snap) => {
            const arr = []
            snap.forEach((doc) => {
                arr.push(doc.data());
            })
            setdataSet(arr.sort());
            const arr2 = [{ name: 'Location' }, { name: 'Discount Amount' }]
            setSelectedField(arr2)

        }).catch(error => console.log(error))
        setButtonSelector('location')
    }

    const submitButtonHandler = () => {
        if (locationDetails.name != '' && locationDetails.routeCode != '') {
            setConfirmAlert('Adding records......');
            firebase.firestore().collection('testLocation').doc().set(locationDetails)
                .then(function () {
                    setConfirmAlert('Successfully added the details');
                })
                .catch(function (error) {
                    setConfirmAlert(error);
                });
        }
        else {
            setConfirmAlert('Please fill the required fields.');
        }
    }

    if (props.isUpdate) {
        createNewForm = "input-group py-3";
        editorTitile = "Update locations";
        editorDescription = "Make changes in below area";
    }

    if (props.isNewLocation) {
        createNewForm = "input-group py-3 collapse";
        editorTitile = "Create locations";
        editorDescription = "Add new location details in below area";
    }

    const [dropdown, setDropDown] = useState('Select field');

    const [searchKeyword, setSearchKeyword] = useState('');

    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">Location Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" id="edit">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">{editorTitile}</div>
                                            <div class="card-category">{editorDescription}</div>
                                        </li>
                                    </ul>
                                    <div class={createNewForm}>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search search-icon"></i></span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon1" />
                                        <button type="button" class="btn btn-outline-info" onClick={locationData}><i class="fas fa-angle-right" ></i></button>
                                    </div>
                                </div>
                                <div class="row py-3">
                                    <div class="d-flex flex-wrap pb-2 pt-4">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Location</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Location" aria-label="Location" aria-describedby="basic-addon1" onChange={(val) => locationDetails.name = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Description</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Location description" aria-label="Username" aria-describedby="basic-addon1" onChange={(val) => locationDetails.description = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Geotag</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add geotag" aria-label="Username" aria-describedby="basic-addon1" onChange={(val) => locationDetails.geoTag = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Discount amount</span>
                                            </div>
                                            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Add discount value related to this location" onChange={(val) => locationDetails.discountAmount = val.target.value} />
                                            <div class="input-group-append">
                                                <span class="input-group-text">.00</span>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Discount %</span>
                                            </div>
                                            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder="Add discount precentage related to this location" onChange={(val) => locationDetails.discountPres = val.target.value} />
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        <div class="dropdown-divider input-group mb-5 mt-5"></div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Route Name</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add route code related to location" aria-label="Route code" aria-describedby="basic-addon1" onChange={(val) => locationDetails.routeCode = val.target.value} />
                                            <button type="button" class="btn btn-outline-info" onClick={routeData}><i class="fas fa-angle-right" ></i></button>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Route Description</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add route code related to location" aria-label="Route code" aria-describedby="basic-addon1" onChange={(val) => locationDetails.routeDescription = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Route Discount amount</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add route code related to location" aria-label="Route code" aria-describedby="basic-addon1" onChange={(val) => locationDetails.routeDiscountAmount = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Route Discount %</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add route code related to location" aria-label="Route code" aria-describedby="basic-addon1" onChange={(val) => locationDetails.routeDiscountPress = val.target.value} />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Route delivery amount</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Add route code related to location" aria-label="Route code" aria-describedby="basic-addon1" onChange={(val) => locationDetails.routeDeliCharge = val.target.value} />
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    {
                                        confirmAlert ?
                                            <div class="float-left text-danger" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                                <i class="icon-fire"></i>
                                                <label class="text-danger" style={{ fontWeight: 'bold' }}>{confirmAlert}</label>
                                            </div>
                                            : ''
                                    }
                                    <div class="float-right">
                                        <button type="button" class="btn btn-danger mr-2" onClick={() => history.go(0)}> Clear </button>
                                        <button type="button" class="btn btn-primary mr-2" data-toggle="modal" data-target="#exampleModalCenter">{props.isUpdate ? "Update" : "Submit"} </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Advance search */}
                    <div class="col-lg-5 position-sticky" style={{ maxHeight: '100vh', overflow: 'auto' }} id="searchAdvance">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Advance search option</div>
                                            <div class="card-category">Search keywords by selecting fields</div>
                                        </li>
                                    </ul>
                                    <div class="input-group py-3">
                                        <div class="dropdown show">
                                            <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {dropdown}

                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {selectedField.map((data) => <button class="dropdown-item" onClick={() => setDropDown(data.name)}>{data.name}</button>)}
                                            </div>
                                        </div>
                                        <input type="text" value={searchKeyword} class="form-control" placeholder="Search keywords" aria-label="Search" aria-describedby="basic-addon1" onChange={(val) => setSearchKeyword(val.target.value)} />
                                        <div class="input-group-prepend">
                                            <button class="input-group-text" id="basic-addon1"><i class="fa fa-search search-icon"></i></button>
                                        </div>
                                    </div>
                                    {rowSelecter(buttonSelector, dataSet, searchKeyword)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure?
                             </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" onClick={submitButtonHandler}
                                    data-dismiss="modal">{props.isUpdate ? "Save changes" : "Create location"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}

export default BackendLocations;

