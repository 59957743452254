import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import imageMy from "../../assets/images/home/header-bg.png";
import signUp from "../../assets/images/key.png";
import laundromatLogo from "../../assets/images/logo/white-logo@2x.png";
import vaveLogo from "../../assets/images/logoRecreation.png";
import VaveButton from "../../components/VaveButton01/VaveButton01";
import VaveCard from "../../components/VaveCard/VaveCard";
import firebase from "../../fbConfig/fbConfig";
import "./BackendSignUp.css";

const BackendSignUp = () => {
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [conPassword, setConPassword] = useState();
  const [cuserror, setError] = useState();
  const history = useHistory();

  const signUpButtonHandler = () => {
    if (userName && email && password && conPassword) {
      firebase
        .firestore()
        .collection("user")
        .where("email", "==", email)
        .get()
        .then((snap) => {
          snap.forEach(() => {
            if (password == conPassword) {
              firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(() => history.push("/BackendUserProfile"))
                .catch(function (error) {
                  setError(error.message);
                });
            } else {
              setError("Passwords did not match");
            }
          });
        });
    } else {
      setError("Please fill all the blanks");
    }
  };

  return (
    <div>
      <div
        className="leftDiv card"
        style={{ borderRadius: "0px", backgroundImage: `url(${imageMy})` }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            backgroundImage:
              "linear-gradient(to bottom, rgb(1,0,0,0.1) , rgb(0,0,0,0.8))",
          }}
        >
          <div className="row w-100 justify-content-center">
            <div className="text-center">
              <p style={{ color: "white", fontSize: "30px" }}>
                Laundromat Online Portal
              </p>
              <hr style={{ backgroundColor: "white" }} />

              <div className="col-12">
                <img width="200px" src={laundromatLogo} />
              </div>
              <div className="col-12" style={{ marginTop: "300px" }}>
                <img width="100px" src={vaveLogo} />
              </div>

              <p
                style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
              >
                VaVe Digital Solutions<br></br>
                <a style={{ color: "silver" }} href="www.vave.lk">
                  www.vave.lk
                </a>
                <br></br>
              </p>
              <p style={{ color: "#f0f0f0", fontSize: "16px" }}>
                0770440083<br></br>
                Moratuwa
              </p>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="rightDiv">
        <VaveCard
          title="Sign Up"
          subTitle="Enter your sign up details"
          src={signUp}
        >
          <div className="row w-100 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="email2">User Name</label>
                <input
                  class="form-control"
                  name="User Name"
                  placeholder="User Name"
                  onChange={(val) => setUserName(val.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="email2">Email</label>
                <input
                  class="form-control"
                  name="Email"
                  placeholder="Email"
                  onChange={(val) => setEmail(val.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="email2">Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="Password"
                  placeholder="Password"
                  onChange={(val) => setPassword(val.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="email2">Password</label>
                <input
                  type="password"
                  class="form-control"
                  name="Confirem password"
                  placeholder="Confirm password"
                  onChange={(val) => setConPassword(val.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VaveButton title="Sign up" onClick={signUpButtonHandler} />
            <br></br>
          </div>
          <div className="BackendSignUp_errorDiv">
            <div className="BackendSignUp_errorDiv_message">{cuserror}</div>
          </div>
        </VaveCard>
      </div>
    </div>
  );
};

export default BackendSignUp;
