import React, { useState } from 'react'
import { connect } from "react-redux";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import firebase from '../../fbConfig/fbConfig'
import './BackendPickup.css'
import { CircularProgress } from "@material-ui/core";
import Axios from 'axios';
import DatePicker2 from '../../components/AlbsCmpDatePicker/AlbsCmpDatePicker'



const BackendPickups = props => {

    //Rearrnaged date 
    const [reDate, setRedate] = useState()


    const [inputs, setinputs] = useState({
        deliveryDate: ''
    });

    const [trigerModals, setTrigerModals] = useState({
        orderModal: false,
        selectedOrder: '',
        customerModal: false,
        selectedCustomerTel: '',
        activeModalTitle: 'Pickup orders',
        activeModal: 'orderModal'
    })

    const [cmDetails, setCmDetails] = useState({
        name: 'asda',
        city: '',
        email: '',
        houseNo: '',
        phoneNumber: '',
        street: ''
    });

    const [orderHeader, setOrderHeader] = useState([]);

    const [orderDetails, setOrderDetails] = useState([]);

    //Add pickup bag details into firebase
    const [bagDetInput, setBagDetInput] = useState({
        dryCleaningBag: '',
        washingBag: '',
        pressingBag: ''
    });

    const [remarks, setRemarks] = useState('')


    const ExampleCustomInput = ({ value, onClick }) => (
        <div class="input-group mb-3" onClick={onClick}>
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-calendar"
                ></i>
                </span>
            </div>
            <input type="text" class="form-control" placeholder="Select pickup date" aria-label="Username" aria-describedby="basic-addon1" value={value} />
        </div>
    );

    const orderOnClick = (genOrder, val, cmTell, isPickuped) => {
        setTrigerModals(() => ({
            ...trigerModals,
            selectedOrder: val,
            orderModal: true,
            activeModal: 'orderModal',
            activeModalTitle: 'Picup Orders'
        }));
        OrderDetailsModalBody(genOrder, val);
        setSelectOrderDet((cur) => ({ ...cur, isPickuped }))
        CustomerDetailsModalBody(cmTell);
    }

    const telephoneOnClick = (val) => {
        val.persist();
        setTrigerModals(() => ({
            ...trigerModals,
            selectedCustomerTel: val.target.value,
            customerModal: true,
            activeModal: 'customerModal',
            activeModalTitle: 'Customer Details'
        }));
        CustomerDetailsModalBody(val.target.value);
    }

    const CustomerDetailsModalBody = (cmTelephone) => {
        var arr = []
        firebase.firestore().collection('customer').where('phoneNumber', '==', cmTelephone).get()
            .then((snap) => {
                snap.forEach((doc) => {
                    arr.push(doc.data());
                });
                setCmDetails((current) => ({
                    ...current,
                    name: arr[0].name,
                    city: arr[0].city,
                    email: arr[0].email,
                    houseNo: arr[0].houseNo,
                    phoneNumber: arr[0].phoneNumber,
                    street: arr[0].street
                }));
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const [selectOrderDet, setSelectOrderDet] = useState({
        orderQty: 0,
        orderAmount: 0,
        orderNo: ''
    });

    const OrderDetailsModalBody = (genOrder, orderNo) => {
        setOrderDetails([])
        setSelectOrderDet({
            orderQty: 0,
            orderAmount: 0,
            orderNo: orderNo,
            genOrder
        })
        firebase.firestore().collection('orderItem').where("orderId", "==", orderNo).get()
            .then((snap) => {
                snap.forEach((doc) => {
                    setOrderDetails((current) => ([...current, doc.data()]));
                    setSelectOrderDet((current) => ({
                        ...current,
                        orderNo,
                        orderQty: parseInt(current.orderQty) + parseInt(doc.data().quantity),
                        orderAmount: (parseFloat(current.orderAmount) + parseFloat(doc.data().subtot)).toFixed(2),
                        genOrder: genOrder,
                        pickupComplete: doc.data().pickupComplete
                    }))
                });
            })
            .catch((error) => {
                console.log(error);
            })
        setBagDetInput((current) => ({
            ...current,
            washingBag: (orderHeader.filter((row) => row.orderID == orderNo))[0].washingBag,
            pressingBag: (orderHeader.filter((row) => row.orderID == orderNo))[0].pressingBag,
            dryCleaningBag: (orderHeader.filter((row) => row.orderID == orderNo))[0].dryCleaningBag
        }))
        setRemarks((orderHeader.filter((row) => row.orderID == orderNo))[0].remarks)
    }

    /**
    * check whether user have prvCode priviladge
    * @param {string} prvCode 
    * @returns 
    */
    const prvCheck = (prvCode) => {
        return (props.userPrv.find((obj) => obj.privCode == prvCode))
    }

    ///Load data to the main table whichis shows in th e
    const loadOrderToTheGrid = (date) => {
        setinputs((values) => ({ ...values, deliveryDate: date }))
        setOrderHeader([]);
        var showAllData = prvCheck("DB00605");
        var beginningDateObject2 = new Date(date);
        var beginningDateObject = new Date(date);
        beginningDateObject.setHours(24);
        beginningDateObject.setMinutes(0);
        beginningDateObject.setSeconds(0);
        firebase.firestore().collection('order').where('pickupDate', '>', beginningDateObject2).where('pickupDate', '<', beginningDateObject).get()
            .then((snap) => {
                snap.forEach((orderDoc) => {
                    if ((orderDoc.data().payment == "Completed" || orderDoc.data().paymentType != 'Online') && (orderDoc.data().pickupAsignedUserEmail == firebase.auth().currentUser.email || showAllData)) {
                        setOrderHeader((current) => ([...current,
                        {
                            orderID: orderDoc.ref.id,
                            orderDate: orderDoc.data().orderDate,
                            name: orderDoc.data().name,
                            phoneNumber: orderDoc.data().phoneNumber,
                            city: orderDoc.data().city,
                            isPickuped: orderDoc.data().pickupComplete,
                            pickupRejected: orderDoc.data().pickupRejected,
                            washingBag: orderDoc.data().washingBag,
                            pressingBag: orderDoc.data().pressingBag,
                            dryCleaningBag: orderDoc.data().dryCleaningBag,
                            generatedId: orderDoc.data().generatedId,
                            payment: orderDoc.data().payment,
                            subtot: orderDoc.data().subtot,
                            delCharge: orderDoc.data().delCharge,
                            orderItems: orderDoc.data().item,
                            itemQty: orderDoc.data().quantity,
                            paymentType: orderDoc.data().paymentType,
                            remarks: orderDoc.data().pickupRemarks
                        }]))

                    }
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const bagInputs = (e) => {
        e.persist();
        setBagDetInput((crnt) => ({ ...crnt, [e.target.name]: e.target.value }))
    }

    const saveBagDetails = (orderNo) => {
        let ts = Date.now();
        let date_ob = new Date(ts);
        let date = date_ob.getDate();
        let month = date_ob.getMonth() + 1;
        let year = date_ob.getFullYear();
        let hours = date_ob.getHours();
        let minutes2 = date_ob.getMinutes() + 2;
        let minutes = date_ob.getMinutes();
        firebase.firestore().collection('order').doc(orderNo).update({
            dryCleaningBag: bagDetInput.dryCleaningBag == undefined ? '' : bagDetInput.dryCleaningBag,
            washingBag: bagDetInput.washingBag == undefined ? '' : bagDetInput.washingBag,
            pressingBag: bagDetInput.pressingBag == undefined ? '' : bagDetInput.pressingBag,
            pickupComplete: true,
            pickupRemarks: remarks == undefined ? '' : remarks
        }).then(() => {
            sendSms(cmDetails.phoneNumber, "/sms/pickupComplete", { date: year + "-" + month + "-" + date, time: hours + ":" + minutes, orderNo: selectOrderDet.genOrder });
            setTrigerModals({ ...trigerModals, [trigerModals.activeModal]: false })
            loadOrderToTheGrid(inputs.deliveryDate)
        })
    }

    //Reject order when customer not in the home
    const [reasonForReject, setReasonForReject] = useState('Customer not at home');

    const rejectOrder = (orderNo) => {
        let ts = Date.now();
        let date_ob = new Date(ts);
        let date = date_ob.getDate();
        let month = date_ob.getMonth() + 1;
        let year = date_ob.getFullYear();
        let hours = date_ob.getHours();
        let minutes = date_ob.getMinutes();
        firebase.firestore().collection('order').doc(orderNo).update({
            pickupRejected: true,
            reasonForReject: reasonForReject,
            rejectedBy: firebase.auth().currentUser.email,
            pickupDate: reDate,
            pickupAsignedUserEmail: null
        }).then(() => {
            sendSms(cmDetails.phoneNumber, "/sms/rejectOrder", { date: year + "-" + month + "-" + date, time: hours + ":" + minutes, orderNo: selectOrderDet.genOrder, rejectReason: reasonForReject });
            setTrigerModals({ ...trigerModals, [trigerModals.activeModal]: false })
            loadOrderToTheGrid(inputs.deliveryDate)
        })
    }

    //Send SMS 
    const sendSms = async (mobile, link, data) => {
        let ts = Date.now();
        let date_ob = new Date(ts);
        let date = date_ob.getDate();
        let month = date_ob.getMonth() + 1;
        let year = date_ob.getFullYear();
        let hours = date_ob.getHours();
        let minutes2 = date_ob.getMinutes() + 2;
        let minutes = date_ob.getMinutes();
        Axios.post(link, {
            phoneNumber: mobile,
            ...data,
            s_time: year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + "00",
            e_time: year + "-" + month + "-" + date + " " + hours + ":" + minutes2 + ":" + "00"
        })
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }

    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">Operations - Pickups</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                            <div class="input-group mb-3">
                                <DatePicker
                                    selected={inputs.deliveryDate}
                                    onChange={(date) => { loadOrderToTheGrid(date) }}
                                    customInput={<ExampleCustomInput />}
                                    dateFormat="MMMM d, yyyy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="card text-center">
                        <table class="table table-responsive">
                            <tbody>
                                <tr>
                                    <th scope="col text-left" >Order Number</th>
                                    <th scope="col text-left" >Customer Name</th>
                                    <th scope="col text-left" >Customer Telephone</th>
                                    <th scope="col text-left" >City</th>
                                    <th scope="col text-left" >Payment Status</th>
                                    <th scope="col text-left" >Payment Type</th>
                                    <th scope="col text-left" >Total Amount</th>
                                </tr>
                                {orderHeader.map((row) =>
                                    <tr style={row.isPickuped ? { backgroundColor: '#8DC25F' } : row.pickupRejected ? { backgroundColor: '#FF4141' } : {}}>
                                        <td scope="col text-left" ><input type="button" onClick={(val) =>
                                            orderOnClick(
                                                row.generatedId,
                                                row.orderID,
                                                row.phoneNumber,
                                                row.isPickuped
                                            )}
                                            value={row.generatedId}
                                            style={{ outlineWidth: "0px", border: 'none', backgroundColor: 'rgba(0,0,0,0)' }} /></td>
                                        <td scope="col text-left" >{row.name}</td>
                                        <td scope="col text-left" ><input type="button" onClick={(val) => telephoneOnClick(val)} value={row.phoneNumber} style={{ outlineWidth: "0px", border: 'none', backgroundColor: 'rgba(0,0,0,0)' }} /></td>
                                        <td scope="col text-left" >{row.city}</td>
                                        <td scope="col text-left" >{row.payment}</td>
                                        <td scope="col text-left" >{row.paymentType != undefined ? row.paymentType : 'Online'}</td>
                                        <td scope="col text-left" >{parseInt(row.subtot) + parseInt(row.delCharge)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal backdrop="static" show={trigerModals.orderModal || trigerModals.customerModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title>{[trigerModals.activeModalTitle]}</Modal.Title>
                </Modal.Header>
                {trigerModals.orderModal ?
                    <Modal.Body>
                        <form class="needs-validation" novalidate>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltip01">Dry Cleaning</label>
                                    <input type="text" class="form-control" placeholder="DCleaning bag no" name="dryCleaningBag" value={bagDetInput.dryCleaningBag} onChange={(e) => bagInputs(e)} />
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationTooltip02">Washing</label>
                                    <input type="text" class="form-control" placeholder="Washing bag no" name="washingBag" value={bagDetInput.washingBag} onChange={(e) => bagInputs(e)} />
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltipUsername">Pressing</label>
                                    <input type="text" class="form-control" name="pressingBag" value={bagDetInput.pressingBag} placeholder="Pressing bag no" onChange={(e) => bagInputs(e)} />
                                </div>
                            </div>
                            <div class="form-row mb-3">
                                <label for="validationTooltip01">Special Remarks</label>
                                <input type="text" class="form-control" placeholder="Special Remarks" name="dryCleaningBag" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                            </div>
                            <div class="form-row">
                                <div class="card text-center">

                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <th scope="col text-left"  >Item Name</th>
                                                <th scope="col text-left" >Washing type</th>
                                                <th scope="col text-left" >Delivery form</th>
                                                <th scope="col text-left" >Qty</th>
                                                <th scope="col text-left" >Notes</th>
                                            </tr>
                                            {orderDetails.map((row) =>
                                                <tr>
                                                    <td scope="col text-left" >{row.item}</td>
                                                    <td scope="col text-left" >{row.washingmethod}</td>
                                                    <td scope="col text-left" >{row.deliveryform}</td>
                                                    <td scope="col text-left" >{row.quantity}</td>
                                                    <td scope="col text-left" >{row.note}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </Modal.Body> : <div></div>
                }

                {trigerModals.customerModal ?
                    <Modal.Body>
                        <form class="needs-validation" novalidate>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltip01">Customer Name</label>
                                    <input type="text" class="form-control" value={cmDetails.name} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label for="validationTooltip02">Telephone</label>
                                    <input type="text" class="form-control" value={cmDetails.phoneNumber} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltipUsername">Email</label>
                                    <input type="text" class="form-control" value={cmDetails.email} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-3 mb-3">
                                    <label for="validationTooltip03">House Number</label>
                                    <input type="text" class="form-control" value={cmDetails.houseNo} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltip04">Street</label>
                                    <input type="text" class="form-control" value={cmDetails.street} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="validationTooltip05">City</label>
                                    <input type="text" class="form-control" value={cmDetails.city} readOnly style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                            </div>
                        </form>
                    </Modal.Body> : <div></div>
                }
                <Modal.Footer>
                    {trigerModals.orderModal && !selectOrderDet.pickupComplete &&
                        <div className='row'>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text" for="inputGroupSelect01">Reason for reject</label>
                                </div>
                                <select class="custom-select" id="inputGroupSelect01" placeholder="Select reason if you reject this order" onChange={(val) => setReasonForReject(val.target.value)}>
                                    <option value="Customer is not available">Customer is not available</option>
                                    <option value="Cancel the order">Cancel the order</option>
                                    <option value="Can’t find the location">Can’t find the location</option>
                                    <option value="Other reason">Other reason</option>
                                </select>
                            </div>
                            <div class="input-group mb-3">
                                <DatePicker2
                                    onChange={(e) => setRedate((cur) => (new Date(e._d).getHours() == 0 ? new Date(e._d.setHours(8)) : e._d))}
                                    placeholder='Pickup Date & time'
                                    InputName='Pickup Date & time' />
                            </div>
                        </div>
                    }
                    {trigerModals.orderModal && selectOrderDet.orderNo != "" && !selectOrderDet.isPickuped ? <Button variant="danger" onClick={() => (reasonForReject && !(reDate == undefined)) ? rejectOrder(selectOrderDet.orderNo) : alert('Select reason and reschedule date for the rejection')}>Reject Order</Button> : <div></div>}
                    {trigerModals.orderModal && selectOrderDet.orderNo != "" && !selectOrderDet.isPickuped ? <Button variant="primary" onClick={() => saveBagDetails(selectOrderDet.orderNo)}>Complete pickup</Button> : <div></div>}
                    {selectOrderDet.orderNo == "" ? <CircularProgress /> : null}
                    <Button type="button" variant="primary" onClick={() => setTrigerModals({ ...trigerModals, [trigerModals.activeModal]: false })}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { userDetails: state.userDetails, userPrv: state.userDetails.userPrv };
};

export default connect(mapStateToProps)(BackendPickups)