import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyBoyeR6xfRy4kw6XwAi-w8Kk-PZsHm0PFA",
  authDomain: "laundromat-online-portal.firebaseapp.com",
  databaseURL: "https://laundromat-online-portal.firebaseio.com",
  projectId: "laundromat-online-portal",
  storageBucket: "laundromat-online-portal.appspot.com",
  messagingSenderId: "1071445187048",
  appId: "1:1071445187048:web:6804b10f907310ede06c5d",
  measurementId: "G-5TR5J0F21T",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBWFNH3l52ZHHZ7NJtVZCZ-XSfrP-4e8jQ",
//   authDomain: "laundromat-development.firebaseapp.com",
//   projectId: "laundromat-development",
//   storageBucket: "laundromat-development.appspot.com",
//   messagingSenderId: "593897977706",
//   appId: "1:593897977706:web:d404003512be954f3c9713",
//   measurementId: "G-H046HY54GD",
// };

firebase.initializeApp(firebaseConfig);

export default firebase;
