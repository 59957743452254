import React, { useState, useEffect, createRef, useRef } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import firebase from "../../fbConfig/fbConfig";
import "./BackendDelivery.css";
import { CircularProgress } from "@material-ui/core";
import Axios from "axios";
import DatePicker2 from "../../components/AlbsCmpDatePicker/AlbsCmpDatePicker";

const BackendDelivery = (props) => {
  //Rearrnaged date
  const [reDate, setRedate] = useState();

  //Open POS orders modal states
  const [posDetModal, setPosDetModal] = useState(false);

  //set pos details
  const [posOrderDet, setPosOrderDet] = useState([]);

  //Set pos order summery
  const [posOrderSum, setPosOrderSum] = useState({
    deliveryAmount: 0,
    orderAmount: 0,
    totalAmount: 0,
  });

  //Reject order when customer not in the home
  const [reasonForReject, setReasonForReject] = useState("type01");

  //Payment Method
  const [paymentMethod, setPaymentMethod] = useState("Cash");

  const [inputs, setinputs] = useState({
    deliveryDate: "",
  });

  const [trigerModals, setTrigerModals] = useState({
    orderModal: false,
    selectedOrder: "",
    customerModal: false,
    selectedCustomerTel: "",
    activeModalTitle: "Delivery orders",
    activeModal: "orderModal",
  });

  const [cmDetails, setCmDetails] = useState({
    // landmark: { lat: '7.115413', lng: '80.041140' },
    name: "asda",
    city: "",
    email: "",
    houseNo: "",
    phoneNumber: "",
    street: "",
  });

  const [orderHeader, setOrderHeader] = useState([]);

  const [orderDetails, setOrderDetails] = useState([]);

  const [waitingForDetails, setWaitingForDetails] = useState(false);

  /**
   * check whether user have prvCode priviladge
   * @param {string} prvCode
   * @returns
   */
  const prvCheck = (prvCode) => {
    return props.userPrv.find((obj) => obj.privCode == prvCode);
  };

  //This is for get date from date picker
  const ExampleCustomInput = ({ value, onClick }) => (
    <div class="input-group mb-3" onClick={onClick}>
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Select pickup date"
        aria-label="Username"
        aria-describedby="basic-addon1"
        value={value}
      />
    </div>
  );

  //Get customer  details and order details when clicking the orders
  const orderOnClick = (
    generatedId,
    orderId,
    orderItems,
    orderSection,
    subtot,
    delCharge,
    deliveryComplete,
    phoneNumber,
    paymentType
  ) => {
    setOrderDetails([]);
    setTrigerModals((cur) => ({
      ...cur,
      selectedOrder: orderId,
      orderModal: true,
      activeModal: "orderModal",
      activeModalTitle: "Delivery orders",
      generatedId: generatedId,
    }));
    setPaymentMethod(paymentType);
    if (orderSection == "pd") {
      if (orderItems != null) {
        orderItems.forEach((item) => {
          setOrderDetails((current) => [
            ...current,
            {
              product: item.item,
              washType: item.washingmethod,
              rate: item.price,
              qty: item.quantity,
            },
          ]);
        });
      } else {
        getOrderItemsPd(generatedId);
      }
      setSelectOrderDet((current) => ({
        orderNo: generatedId,
        orderAmount: subtot,
        delCharge,
        deliveryComplete,
        generatedId,
      }));
    } else {
      getOrderItemsHd(orderItems);
      setSelectOrderDet((current) => ({
        orderNo: generatedId,
        orderAmount: subtot,
        delCharge,
        deliveryComplete,
        generatedId,
      }));
    }
    CustomerDetailsModalBody(phoneNumber);
  };

  //Order details pd
  const getOrderItemsPd = (orderNumber) => {
    setWaitingForDetails(true);
    Axios.get("/posIntegration/items/" + orderNumber)
      .then((res) => {
        res.data.data.forEach((item) => {
          setOrderDetails((current) => [
            ...current,
            {
              product: item.item,
              washType: item.washingmethod,
              rate: item.price,
              qty: item.quantity,
            },
          ]);
        });
        setWaitingForDetails(false);
      })
      .catch((err) => {
        setWaitingForDetails(false);
        console.log(err);
      });
  };

  //Order details
  const getOrderItemsHd = (orderItems) => {
    let arr = orderItems.map((order) => order.CO_Number);
    let orderQty = 0;
    Axios.post("/posIntegration/items", { orders: arr })
      .then((res) => {
        res.data.data.forEach((item) => {
          setOrderDetails((current) => [
            ...current,
            {
              product: item.item,
              washType: item.washingmethod,
              rate: item.price,
              qty: item.quantity,
            },
          ]);
          orderQty = orderQty + item.quantity;
        });
        setSelectOrderDet((cur) => ({ ...cur, orderQty: orderQty }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Open customer details modal
  const telephoneOnClick = (val) => {
    val.persist();
    setTrigerModals(() => ({
      ...trigerModals,
      selectedCustomerTel: val.target.value,
      customerModal: true,
      activeModal: "customerModal",
      activeModalTitle: "Customer Details",
    }));
    CustomerDetailsModalBody(val.target.value);
  };

  //Get customer details
  const CustomerDetailsModalBody = (cmTelephone) => {
    console.log(cmTelephone);
    var arr = [];
    firebase
      .firestore()
      .collection("customer")
      .where("phoneNumber", "==", cmTelephone)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          arr.push(doc.data());
        });
        setCmDetails((current) => ({
          ...current,
          // landmark: { lat: arr[0].landmark.lat, lng: arr[0].landmark.lng },
          name: arr[0].name,
          city: arr[0].city,
          email: arr[0].email,
          houseNo: arr[0].houseNo,
          phoneNumber: arr[0].phoneNumber,
          street: arr[0].street,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Selected order Header
  const [selectOrderDet, setSelectOrderDet] = useState({
    orderQty: 0,
    orderAmount: 0,
    orderNo: "",
    generatedId: "",
    delCharge: 0,
  });

  //Load data to the main table whichis shows in th e
  const loadOrderToTheGrid = (date) => {
    setinputs((values) => ({ ...values, deliveryDate: date }));
    setOrderHeader([]);
    var showAllData = prvCheck("DB00605");
    var beginningDateObject2 = new Date(date);
    var beginningDateObject = new Date(date);
    beginningDateObject.setHours(23);
    beginningDateObject.setMinutes(59);
    beginningDateObject.setSeconds(59);
    firebase
      .firestore()
      .collection("deliveryUnique")
      .where("deliveryDate", ">", beginningDateObject2)
      .where("deliveryDate", "<", beginningDateObject)
      .get()
      .then((snap) => {
        snap.forEach((orderDoc) => {
          if (
            orderDoc.data().asignedUserEmail ==
              firebase.auth().currentUser.email ||
            showAllData
          ) {
            setOrderHeader((current) => [
              ...current,
              {
                name: orderDoc.data().name,
                phoneNumber: orderDoc.data().phoneNumber,
                city: orderDoc.data().city,
                deliveryComplete: orderDoc.data().deliveryComplete,
                deliveryRejected: orderDoc.data().deliveryRejected,
                orderSection: "hd",
                payment: orderDoc.data().payment,
                subtot: orderDoc.data().subtot,
                delCharge: orderDoc.data().delCharge,
                generatedId: orderDoc.data().generatedId,
                orderItems: orderDoc.data().item,
                paymentType: orderDoc.data().paymentType,
                orderId: orderDoc.ref.id,
                itemQty: 0,
                deliveryComplete:
                  orderDoc.data().deliveryComplete == undefined
                    ? false
                    : orderDoc.data().deliveryComplete,
                orderDeliveredBy:
                  orderDoc.data().orderDeliveredBy == undefined
                    ? false
                    : orderDoc.data().orderDeliveredBy,
                orderDeliveredAt:
                  orderDoc.data().orderDeliveredAt == undefined
                    ? false
                    : orderDoc.data().orderDeliveredAt,
                reasonForDeliveryRejected:
                  orderDoc.data().reasonForDeliveryRejected == undefined
                    ? false
                    : orderDoc.data().reasonForDeliveryRejected,
                rejectedBy:
                  orderDoc.data().rejectedBy == undefined
                    ? false
                    : orderDoc.data().rejectedBy,
              },
            ]);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

    firebase
      .firestore()
      .collection("order")
      .where("deliveryDate", ">", beginningDateObject2)
      .where("deliveryDate", "<", beginningDateObject)
      .get()
      .then((snap2) => {
        snap2.forEach((orderDoc) => {
          if (
            orderDoc.data().pickupComplete &&
            (orderDoc.data().asignedUserEmail ==
              firebase.auth().currentUser.email ||
              showAllData)
          ) {
            setOrderHeader((current) => [
              ...current,
              {
                orderId: orderDoc.ref.id,
                name: orderDoc.data().name,
                phoneNumber: orderDoc.data().phoneNumber,
                city: orderDoc.data().city,
                deliveryComplete: orderDoc.data().deliveryComplete,
                deliveryRejected: orderDoc.data().deliveryRejected,
                orderSection: "pd",
                payment: orderDoc.data().payment,
                subtot: orderDoc.data().subtot,
                delCharge: orderDoc.data().delCharge,
                generatedId: orderDoc.data().generatedId,
                orderItems: orderDoc.data().item,
                itemQty: orderDoc.data().totalQty,
                paymentType: orderDoc.data().paymentType,
                deliveryComplete:
                  orderDoc.data().deliveryComplete == undefined
                    ? false
                    : orderDoc.data().deliveryComplete,
                orderDeliveredBy:
                  orderDoc.data().orderDeliveredBy == undefined
                    ? false
                    : orderDoc.data().orderDeliveredBy,
                orderDeliveredAt:
                  orderDoc.data().orderDeliveredAt == undefined
                    ? false
                    : orderDoc.data().orderDeliveredAt,
                reasonForDeliveryRejected:
                  orderDoc.data().reasonForDeliveryRejected == undefined
                    ? false
                    : orderDoc.data().reasonForDeliveryRejected,
                rejectedBy:
                  orderDoc.data().rejectedBy == undefined
                    ? false
                    : orderDoc.data().rejectedBy,
              },
            ]);
          }
        });
      });
  };

  //Add delivery confirmation
  const deliveryConfirmation = async (orderNo) => {
    let ts = Date.now();
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let batch = firebase.firestore().batch();
    if (
      orderHeader.filter((order) => order.generatedId == orderNo)[0]
        .orderSection == "hd"
    ) {
      let orders = await firebase
        .firestore()
        .collection("deliveryOrder")
        .where("generatedId", "==", orderNo)
        .get();
      orders.forEach((doc) => {
        batch.update(
          firebase.firestore().collection("deliveryOrder").doc(doc.ref.id),
          {
            deliveryComplete: true,
            orderDeliveredBy: firebase.auth().currentUser.email,
            orderDeliveredAt: firebase.firestore.Timestamp.fromDate(new Date()),
            paymentType: paymentMethod,
          }
        );
      });

      let orderUnique = await firebase
        .firestore()
        .collection("deliveryUnique")
        .where("generatedId", "==", orderNo)
        .get();
      orderUnique.forEach((doc) => {
        batch.update(
          firebase.firestore().collection("deliveryUnique").doc(doc.ref.id),
          {
            deliveryComplete: true,
            payment: "Completed",
            orderDeliveredBy: firebase.auth().currentUser.email,
            orderDeliveredAt: firebase.firestore.Timestamp.fromDate(new Date()),
            paymentType: paymentMethod,
          }
        );
      });

      await batch.commit().then((res) => {
        sendSms(cmDetails.phoneNumber, "/sms/deliveryComplete", {
          date: year + "-" + month + "-" + date,
          time: hours + ":" + minutes,
          orderNo: selectOrderDet.orderNo,
        });
      });
    } else {
      let orders = await firebase
        .firestore()
        .collection("order")
        .where("generatedId", "==", orderNo)
        .get();
      orders.forEach((doc) => {
        batch.update(firebase.firestore().collection("order").doc(doc.ref.id), {
          deliveryComplete: true,
          payment: "Completed",
          orderDeliveredBy: firebase.auth().currentUser.email,
          orderDeliveredAt: firebase.firestore.Timestamp.fromDate(new Date()),
          paymentType: paymentMethod,
        });
      });
      await batch.commit().then((res) => {
        sendSms(cmDetails.phoneNumber, "/sms/deliveryComplete", {
          date: year + "-" + month + "-" + date,
          time: hours + ":" + minutes,
          orderNo: selectOrderDet.orderNo,
        });
      });
    }
    //sendSms(cmDetails.phoneNumber, "deliveryComplete");
    setTrigerModals({ ...trigerModals, [trigerModals.activeModal]: false });
    loadOrderToTheGrid(inputs.deliveryDate);
  };

  //Reject order when customer not in the home
  const rejectOrder = async (orderNo) => {
    let ts = Date.now();
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let batch = firebase.firestore().batch();
    if (
      orderHeader.filter((order) => order.generatedId == orderNo)[0]
        .orderSection == "hd"
    ) {
      let orders = await firebase
        .firestore()
        .collection("deliveryOrder")
        .where("generatedId", "==", orderNo)
        .get();
      orders.forEach((doc) => {
        batch.update(
          firebase.firestore().collection("deliveryOrder").doc(doc.ref.id),
          {
            deliveryRejected: true,
            reasonForDeliveryRejected: reasonForReject,
            rejectedBy: firebase.auth().currentUser.email,
            DeliveryDate: reDate,
            asignedUserEmail: null,
          }
        );
      });

      let orderUnique = await firebase
        .firestore()
        .collection("deliveryUnique")
        .where("generatedId", "==", orderNo)
        .get();
      orderUnique.forEach((doc) => {
        batch.update(
          firebase.firestore().collection("deliveryUnique").doc(doc.ref.id),
          {
            deliveryRejected: true,
            reasonForDeliveryRejected: reasonForReject,
            rejectedBy: firebase.auth().currentUser.email,
            deliveryDate: reDate,
            asignedUserEmail: null,
          }
        );
      });

      await batch.commit().then((res) => {
        sendSms(cmDetails.phoneNumber, "/sms/rejectDelivery", {
          date: year + "-" + month + "-" + date,
          time: hours + ":" + minutes,
          orderNo: selectOrderDet.orderNo,
          rejectReason: reasonForReject,
        });
      });
    } else {
      let orders = await firebase
        .firestore()
        .collection("order")
        .where("generatedId", "==", orderNo)
        .get();
      orders.forEach((doc) => {
        batch.update(firebase.firestore().collection("order").doc(doc.ref.id), {
          deliveryRejected: true,
          reasonForDeliveryRejected: reasonForReject,
          rejectedBy: firebase.auth().currentUser.email,
          deliveryDate: reDate,
          asignedUserEmail: null,
        });
      });
      await batch.commit().then((res) => {
        sendSms(cmDetails.phoneNumber, "/sms/rejectDelivery", {
          date: year + "-" + month + "-" + date,
          time: hours + ":" + minutes,
          orderNo: selectOrderDet.orderNo,
          rejectReason: reasonForReject,
        });
      });
    }
    setTrigerModals({ ...trigerModals, [trigerModals.activeModal]: false });
    loadOrderToTheGrid(inputs.deliveryDate);
  };

  //Send SMS
  const sendSms = async (mobile, link, data) => {
    let ts = Date.now();
    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes2 = date_ob.getMinutes() + 2;
    let minutes = date_ob.getMinutes();
    Axios.post(link, {
      phoneNumber: mobile,
      ...data,
      s_time:
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        "00",
      e_time:
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes2 +
        ":" +
        "00",
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  //No Pos orders
  const [noPosOrders, setNoPosOrders] = useState(false);

  //Get POS details
  const getPosDetails = async (
    orderSection,
    generatedId,
    orderItems,
    deliveryCharge
  ) => {
    setNoPosOrders(false);
    setPosOrderDet([]);
    setPosDetModal((current) => (current ? false : true));
    setPosOrderSum((cur) => ({
      orderAmount: 0,
      deliveryAmount: 0,
      totalAmount: 0,
    }));
    if (orderSection == "hd") {
      setPosOrderDet(orderItems);
      let netAmount = 0;
      orderItems.forEach((order) => (netAmount = netAmount + order.NetAmount));
      setPosOrderSum((cur) => ({
        orderAmount: netAmount,
        deliveryAmount: deliveryCharge,
        totalAmount: parseFloat(netAmount) + deliveryCharge,
      }));
    } else {
      Axios.get(`/posIntegration/orders/${generatedId}`)
        .then((res) => {
          if (res.data.data.length == 0) setNoPosOrders(true);
          else {
            setPosOrderDet(res.data.data);
            setPosOrderSum((cur) => ({
              orderAmount: res.data.sumery.NetAmount,
              deliveryAmount: deliveryCharge,
              totalAmount:
                parseFloat(res.data.sumery.NetAmount) + deliveryCharge,
            }));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [totals, setTotals] = useState({
    totalQty: 0,
    totalAmount: 0,
  });

  const getTotals = () => {
    let totalAmount;
    let totalQty;
    orderDetails.forEach((item) => {
      totalAmount = totalAmount + item.rate;
      totalQty = totalQty = item.qty;
    });
    setTotals(() => ({
      totalAmount,
      totalQty,
    }));
    console.log(orderDetails, totals);
  };

  const componentRef = useRef();

  const pageStyle = `@media all {
        .page-break {
          display: none;
        }
      }
      
      @media print {
        html, body {
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      
      @media print {
        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-before: auto;
        }
      }
      
      @page {
        size: 210mm 297mm;
        margin-top: 5mm;
      }`;

  return (
    <div class="content">
      <div class="panel-header bg-primary-gradient mb--5">
        <div class="page-inner py-5">
          <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 class="text-white pb-2 fw-bold">Operations - Deliveries</h2>
              <h5 class="text-white op-7 mb-2">
                Laundromat Information System
              </h5>
              <div class="input-group mb-3">
                <DatePicker
                  selected={inputs.deliveryDate}
                  onChange={(date) => {
                    loadOrderToTheGrid(date);
                  }}
                  customInput={<ExampleCustomInput />}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
              {/* <ReactTOPrint
                                pageStyle={pageStyle}
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <a class="btn" style={{ color: 'black', backgroundColor: 'white' }} role="button" >

                                        <table>
                                            <tr>
                                                <td className='text-center w-25'>
                                                    <i className="fa fa-print" ></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-center w-25 text-sm' style={{ fontSize: "14px", color: "black" }}>
                                                    Print
                                    </td>
                                            </tr>
                                        </table>
                                    </a>
                                }}
                                content={() => componentRef.current}
                            /> */}
            </div>
          </div>
        </div>
      </div>
      <div id="componentRef" ref={componentRef} class="page-inner mt--5">
        <div class="row mt--1 justify-content-center">
          <div class="card text-center">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <th scope="col text-left">#</th>
                  <th scope="col text-left">Order Number</th>
                  <th scope="col text-left">Ref</th>
                  <th scope="col text-left">Customer Name</th>
                  <th scope="col text-left">Customer Telephone</th>
                  <th scope="col text-left">City</th>
                  <th scope="col text-left">Payment Status</th>
                  <th scope="col text-left">Payment Type</th>
                  {/* <th scope="col text-left" >Total Amount</th> */}
                </tr>
                {orderHeader.map((row, index) => (
                  <tr
                    style={{
                      backgroundColor: row.deliveryComplete
                        ? "#8DC25F"
                        : row.deliveryRejected
                        ? "#FF4141"
                        : "",
                    }}
                  >
                    <td scope="col text-left">{index + 1}</td>
                    <td scope="col text-left">
                      <input
                        type="button"
                        onClick={(val) =>
                          orderOnClick(
                            row.generatedId,
                            row.orderId,
                            row.orderItems,
                            row.orderSection,
                            row.subtot,
                            row.delCharge,
                            row.deliveryComplete,
                            row.phoneNumber,
                            row.paymentType
                          )
                        }
                        value={
                          row.generatedId == undefined
                            ? row.orderId
                            : row.generatedId
                        }
                        style={{
                          outlineWidth: "0px",
                          border: "none",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      />
                    </td>
                    <td scope="col text-left">
                      <input
                        type="button"
                        onClick={() =>
                          getPosDetails(
                            row.orderSection,
                            row.generatedId,
                            row.orderItems,
                            parseFloat(row.delCharge)
                          )
                        }
                        value="View System Details"
                        style={{
                          outlineWidth: "0px",
                          border: "none",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      />
                    </td>
                    <td scope="col text-left">{row.name}</td>
                    <td scope="col text-left">
                      <input
                        type="button"
                        onClick={(val) => telephoneOnClick(val)}
                        value={row.phoneNumber}
                        style={{
                          outlineWidth: "0px",
                          border: "none",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      />
                    </td>
                    <td scope="col text-left">{row.city}</td>
                    <td scope="col text-left">{row.payment}</td>
                    <td scope="col text-left">
                      {row.paymentType != undefined
                        ? row.paymentType
                        : "Online"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {(trigerModals.orderModal ||
        trigerModals.customerModal ||
        orderHeader.length >= 1) && (
        <Modal
          backdrop="static"
          show={trigerModals.orderModal || trigerModals.customerModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>{[trigerModals.activeModalTitle]}</Modal.Title>
            {waitingForDetails ? <CircularProgress /> : null}
          </Modal.Header>
          {trigerModals.orderModal ? (
            <Modal.Body>
              <form class="needs-validation" novalidate>
                <div class="form-row">
                  <div class="card text-center">
                    <table class="table table-responsive">
                      <tbody>
                        <tr>
                          <th scope="col text-left">Item Name</th>
                          <th scope="col text-left">Washing type</th>
                          <th scope="col text-left">Amount</th>
                          <th scope="col text-left">Qty</th>
                        </tr>
                        {orderDetails.map((row) => (
                          <tr>
                            <td scope="col text-left">{row.product}</td>
                            <td scope="col text-left">{row.washType}</td>
                            <td scope="col text-left">
                              {parseFloat(row.rate).toFixed(2)}
                            </td>
                            <td scope="col text-left">{row.qty}</td>
                          </tr>
                        ))}
                        <tr>
                          <th colSpan="3" scope="col text-right">
                            Qty
                          </th>
                          <th scope="col text-left">
                            {selectOrderDet.orderQty}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="3" scope="col text-right">
                            Order Amount
                          </th>
                          <th scope="col text-left">
                            {parseFloat(selectOrderDet.orderAmount).toFixed(2)}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="3" scope="col text-right">
                            Delivery Charge
                          </th>
                          <th scope="col text-left">
                            {parseFloat(selectOrderDet.delCharge).toFixed(2)}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="3" scope="col text-right">
                            Total Amount
                          </th>
                          <th scope="col text-left">
                            {(
                              parseFloat(selectOrderDet.delCharge) +
                              parseFloat(selectOrderDet.orderAmount)
                            ).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </Modal.Body>
          ) : (
            <div></div>
          )}

          {trigerModals.customerModal ? (
            <Modal.Body>
              <div class="form-row justify-content-center">
                <div class="col-md-4 mb-3">
                  <label for="validationTooltip01">Customer Name</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.name}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label for="validationTooltip02">Telephone</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.phoneNumber}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationTooltipUsername">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.email}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div class="col-md-3 mb-3">
                  <label for="validationTooltip03">House Number</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.houseNo}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationTooltip04">Street</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.street}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="validationTooltip05">City</label>
                  <input
                    type="text"
                    class="form-control"
                    value={cmDetails.city}
                    readOnly
                    style={{ color: "black", fontWeight: "bold" }}
                  />
                </div>
              </div>
              {/* <div class="modal-body vaveMapsCustomStype">
                                {cmDetails.landmark.lat ? <Map
                                    google={props.google}
                                    style={{ width: "95%", height: '95%' }}
                                    className={"map"}
                                    zoom={16}
                                    center={cmDetails.landmark}>
                                    <Marker
                                        position={cmDetails.landmark}
                                    />
                                </Map> : <div>No geo location</div>
                                }
                            </div> */}
            </Modal.Body>
          ) : (
            <div></div>
          )}
          {trigerModals.orderModal && !selectOrderDet.deliveryComplete && (
            <Modal.Footer>
              <div className="col-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      Payment Method
                    </label>
                  </div>
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    placeholder="Select reason if you reject this order"
                    onChange={(val) => setPaymentMethod(val.target.value)}
                  >
                    <option selected={paymentMethod == "Cash"} value="Cash">
                      Cash
                    </option>
                    <option selected={paymentMethod == "Card"} value="Card">
                      Card
                    </option>
                    <option selected={paymentMethod == "Online"} value="Online">
                      Online
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-12 text-right">
                {trigerModals.orderModal &&
                selectOrderDet.orderNo != "" &&
                !selectOrderDet.deliveryComplete ? (
                  <Button
                    className="mr-2"
                    variant="primary"
                    onClick={() => deliveryConfirmation(selectOrderDet.orderNo)}
                  >
                    Complete Delivery
                  </Button>
                ) : (
                  <div></div>
                )}
                {selectOrderDet.orderNo == "" ? <CircularProgress /> : null}
                <Button
                  type="button"
                  variant="primary"
                  onClick={() =>
                    setTrigerModals({
                      ...trigerModals,
                      [trigerModals.activeModal]: false,
                    })
                  }
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          )}
          <Modal.Footer>
            {trigerModals.orderModal && !selectOrderDet.deliveryComplete && (
              <div className="col-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      Reason for reject
                    </label>
                  </div>
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    placeholder="Select reason if you reject this order"
                    onChange={(val) => setReasonForReject(val.target.value)}
                  >
                    <option value="type01">Order is not ready.</option>
                    <option value="type02">
                      Order is not ready.(Large Items)
                    </option>
                    <option value="type03">Delivery Delay.</option>
                    <option value="Payment is not ready.">
                      Payment is not ready.
                    </option>
                    <option value="Cannot locate address.">
                      Cannot locate address.
                    </option>
                    <option value="Customer not at home.">
                      Customer not at home.
                    </option>
                  </select>
                </div>
                <div class="input-group mb-3">
                  <DatePicker2
                    onChange={(e) =>
                      setRedate((cur) =>
                        new Date(e._d).getHours() == 0
                          ? new Date(e._d.setHours(8))
                          : e._d
                      )
                    }
                    placeholder="Delivery date and time"
                    InputName="Delivery date and time"
                  />
                </div>
              </div>
            )}
            <div className="col-12 text-right">
              {trigerModals.orderModal &&
              selectOrderDet.orderNo != "" &&
              !selectOrderDet.deliveryComplete ? (
                <Button
                  className="mr-2"
                  variant="danger"
                  onClick={() =>
                    reasonForReject && !(reDate == undefined)
                      ? rejectOrder(selectOrderDet.orderNo)
                      : alert(
                          "Select reson and reschedule date for the rejection"
                        )
                  }
                >
                  Reject Delivery
                </Button>
              ) : (
                <div></div>
              )}
              {selectOrderDet.orderNo == "" ? <CircularProgress /> : null}
              <Button
                type="button"
                variant="primary"
                onClick={() =>
                  setTrigerModals({
                    ...trigerModals,
                    [trigerModals.activeModal]: false,
                  })
                }
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        backdrop="static"
        show={posDetModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>POS Details</Modal.Title>
          {posOrderDet.length == 0 ? (
            noPosOrders ? (
              "No orders has found"
            ) : (
              <CircularProgress />
            )
          ) : null}
        </Modal.Header>
        <Modal.Body>
          <div class="form-row justify-content-center">
            <div class="card text-center">
              <table class="table table-responsive">
                <tbody>
                  <tr>
                    <th scope="col text-left">Order Number</th>
                    <th scope="col text-left">Net Amount</th>
                  </tr>
                  {posOrderDet.map((row) => (
                    <tr>
                      <td scope="col text-left">{row.CO_Number}</td>
                      <td scope="col text-left">{row.NetAmount}</td>
                    </tr>
                  ))}
                  <tr style={{ backgroundColor: "#eceff1" }}>
                    <td scope="col text-left">Order Value</td>
                    <td scope="col text-left">{posOrderSum.orderAmount}</td>
                  </tr>
                  <tr style={{ backgroundColor: "#cfd8dc" }}>
                    <td scope="col text-left">Delivery Charge</td>
                    <td scope="col text-left">{posOrderSum.deliveryAmount}</td>
                  </tr>
                  <tr style={{ backgroundColor: "#ffab91" }}>
                    <td scope="col text-left">Total Amount</td>
                    <td scope="col text-left">{posOrderSum.totalAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="primary"
            onClick={() => setPosDetModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userDetails: state.userDetails, userPrv: state.userDetails.userPrv };
};

export default connect(mapStateToProps)(BackendDelivery);
