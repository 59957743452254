import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import './AlbsCmpSearchModal.css'

const AlbsCmpSearchModal = props => {
    const [filterFrom, setFilterFrom] = useState('No Data')
    const [filteredDataset, setFilteredDataset] = useState()

    useEffect(() => {
        props.dataset == undefined ? setFilterFrom('No Data') : setFilterFrom(props.dataset.filters[0])
        props.dataset == undefined ? setFilteredDataset() : setFilteredDataset(props.dataset.data)
    }, [props.dataset])

    const filter = (key) => {
        setFilteredDataset((data) => props.dataset.data.filter(object => object[filterFrom].toString().toUpperCase().includes(key.toUpperCase())))
    }

    return (
        <Modal show={props.show} onHide={props.onHide} animation={true} size={"lg"} >
            <Modal.Header closeButton>
                <div className='row container-fluid'>
                    <div className='col-5'>
                        <Modal.Title>{'Search ' + props.title === undefined ? 'No Data' : props.title}</Modal.Title>
                    </div>
                    <div className='col-7'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{filterFrom}</button>
                                <div className="dropdown-menu">
                                    {props.dataset === undefined ? <button className="dropdown-item">No Data</button> : props.dataset.filters.map((filter, index) => <button key={index} onClick={(e) => setFilterFrom(e.target.value)} value={filter} className="dropdown-item">{filter}</button>)}
                                </div>
                            </div>
                            <input type="text" className="form-control" aria-label="Text input with dropdown button" onChange={(e) => filter(e.target.value)} />
                        </div>

                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {filteredDataset !== undefined ? <table className="table table-striped  no-box-shadow">
                    <tbody>
                        <tr>
                            <th scope="col">#</th>
                            {props.dataset.filters.map((filter, index) => <th key={index} scope="col">{filter}</th>)}
                        </tr>
                        {filteredDataset.map((obj, index) =>
                            <tr onClick={props.onClick} style={{ cursor: 'pointer' }} className='albsCmpSearchModals_tablerow' key={index}>
                                <th scope="row" >{index + 1}</th>
                                {Object.values(obj).map((element, index) => <td id={obj[Object.keys(obj)[0]]} key={index}>{element}</td>)}
                            </tr>)}
                    </tbody>
                </table> : 'No data has found'}
            </Modal.Body>
        </Modal>
    )
}

export default AlbsCmpSearchModal