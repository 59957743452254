import React, { useState, useEffect } from 'react'
import './BackendLogin.css'
import VaveCard from '../../components/VaveCard/VaveCard'
import loginImg from '../../assets/images/login.png'
import VaveButton from '../../components/VaveButton01/VaveButton01'
import firebase from '../../fbConfig/fbConfig'
import { useHistory, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import imageMy from '../../assets/images/home/header-bg.png'
import laundromatLogo from '../../assets/images/logo/white-logo@2x.png'
import vaveLogo from '../../assets/images/logoRecreation.png'


const BackendLogin = props => {
    const [custEmail, setCustEmail] = useState();
    const [custPassword, setCustPassword] = useState();
    const [cusError, setCusError] = useState();
    const history = useHistory();
    const [loading, setLoading] = useState(false)

    const loginButton = () => {
        if (custEmail && custPassword) {
            setLoading(true)
            firebase.auth().signInWithEmailAndPassword(custEmail, custPassword)
                .then(() => {
                    changeUserDetails(custEmail);
                })
                .catch(error => {
                    setLoading(false)
                    setCusError(error.message);
                });
        }
        else {
            setLoading(false)
            setCusError('Please fill all the blanks');
        }
    }

    const changeUserDetails = (userEmail) => {
        if (userEmail != null) {
            setLoading(true)
            firebase.firestore().collection('user').where("email", "==", userEmail)
                .get()
                .then((results) => {
                    firebase.firestore().collection('userGroups').where("groupName", "==", results.docs[0].data().group)
                        .get()
                        .then((groupDet) => {
                            props.activeBackendHeader();
                            props.changeUser(
                                results.docs[0].data().userName,
                                results.docs[0].data().email,
                                results.docs[0].data().group,
                                groupDet.docs[0].data().priv,
                                groupDet.docs[0].data().groupDescription,
                                results.docs[0].data().userMobile,
                                results.docs[0].data().userAddress1,
                                results.docs[0].data().userAddress2,
                                results.docs[0].data().userAddress3,
                                results.docs[0].data().userDescription,
                                results.docs[0].data().userProfilePic,
                            );
                            setLoading(false)
                            history.push('/BackendUserProfile');
                        })
                })
                .catch((err) => {
                    setLoading(false)
                })
        }
    }

    const passwordReset = () => {
        if (custEmail) {
            setLoading(true)
            var auth = firebase.auth();
            var emailAddress = custEmail;
            auth.sendPasswordResetEmail(emailAddress).then(function () {
                setLoading(false)
                setCusError('Password reset link has been sent to your email');
            }).catch(function (error) {
                setLoading(false)
                setCusError(error.message);
            });
        } else {
            setCusError('Please enter your email');
        }
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                changeUserDetails(firebase.auth().currentUser.email);
            }
        })
    }, [])



    return (
        <div>
            <div className='leftDiv card' style={{ borderRadius: '0px', backgroundImage: `url(${imageMy})` }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', backgroundImage: 'linear-gradient(to bottom, rgb(1,0,0,0.1) , rgb(0,0,0,0.8))' }}>
                    <div className='row w-100 justify-content-center' >
                        <div className='text-center'>
                            <p style={{ color: 'white', fontSize: '30px' }}>Laundromat Online Portal</p>
                            <hr style={{ backgroundColor: 'white' }} />

                            <div className='col-12'>
                                <img width='200px' src={laundromatLogo} />
                            </div>
                            <div className='col-12' style={{ marginTop: '300px' }}>
                                <img width='100px' src={vaveLogo} />
                            </div>

                            <p style={{ color: '#d6d6d6', fontSize: '20px', marginTop: '10px' }}>VaVe Digital Solutions<br></br>
                                <a style={{ color: '#d6d6d6' }} target='_blank' href='https://www.vave.lk'>www.vave.lk</a>
                                <br></br></p>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div className='rightDiv'>
                <VaveCard title='Sign In' subTitle='Enter your login details' src={loginImg}>
                    <div className="row w-100 justify-content-center">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email2">Email Address</label>
                                <input type="text" className="form-control" name='Email' placeholder='Email' onChange={(val) => setCustEmail(val.target.value)} />
                            </div>
                            <div class="form-group">
                                <label for="email2">Password</label>
                                <input type="password" className="form-control" name='Password' placeholder='Password' onChange={(val) => setCustPassword(val.target.value)} />
                            </div>
                        </div>
                    </div>
                    {loading
                        ? <div class="spinner-border text-primary" role="status"><span class="sr-only">Loading...</span></div>
                        : <VaveButton title='Login' onClick={loginButton}
                        />}
                    <div className="BackendLogin_errorDiv_message">
                        <a className="btn" onClick={() => passwordReset()}>Forget password ?</a>
                    </div>
                    <div className="BackendLogin_errorDiv_message">
                        <Link to='/signUp'>Sign up</Link>
                    </div>
                    <div className="BackendLogin_errorDiv">
                        <p style={{ color: 'red' }}>{cusError}</p>
                    </div>
                </VaveCard>
            </div>
        </div>
    )

}

const mapDespatchToProps = dispatch => {
    return {
        activeBackendHeader: () => dispatch({ type: 'ISFRONTEND', val: false }),
        changeUser: (
            userName,
            email,
            group,
            userPrv,
            groupDescription,
            userMobile,
            userAddress1,
            userAddress2,
            userAddress3,
            userDescription,
            userProfilePic,
        ) => dispatch({
            type: 'CHANGEUSER',
            userName,
            email,
            group,
            userPrv,
            groupDescription,
            userMobile,
            userAddress1,
            userAddress2,
            userAddress3,
            userDescription,
            userProfilePic,
        })
    };
}

export default connect(null, mapDespatchToProps)(BackendLogin);
