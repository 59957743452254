import * as fb_functions from "../../../functions/FirebaseFunctions";
import Axios from "axios";

export const rep_DailyDeliveriesStUse = async (inputs) => {
  var sDate = inputs.dateRange.startDate;
  var eDate = inputs.dateRange.endDate;
  var reportRes = {
    fields: [],
    data: [],
  };

  const response = await Axios.get(
    `/reports/deliveryDetails?startDate=${sDate}&endDate=${eDate}`
  );

  response.data.data
    .sort((a, b) => {
      let fa = a.phoneNumber.toLowerCase(),
        fb = b.phoneNumber.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    })
    .forEach((data) => {
      reportRes.data.push({
        onlineId: data.onlineId,
        coNumber: data.coNumber,
        deliveryDate: data.deliveryDate.split("T")[0],
        isHanger: data.isHanger,
        rackNumber: data.rackNumber,
        name: data.name,
        phoneNumber: data.phoneNumber,
        city: data.city,
        lat: data.latLng?.lat,
        lng: data.latLng?.lng,
        totalQty: data.totalQty,
        delCharge: data.delCharge,
        orderValue: data.orderValue,
        totalAmount: data.totalAmount,
        paymentType: data.paymentType,
      });
    });

  if (response.data.summary) {
    reportRes.data.push({
      onlineId: "Total",
      coNumber: "",
      deliveryDate: "",
      isHanger: "",
      rackNumber: "",
      name: "",
      phoneNumber: "",
      city: "",
      lat: "",
      lng: "",
      totalQty: response.data.summary.totalQty,
      delCharge: response.data.summary.totalDeliveryCharge,
      orderValue: response.data.summary.totalOrderAmount,
      totalAmount: response.data.summary.totalAmount,
      paymentType: "",
    });
  }

  reportRes.fields = ["#", ...response.data.reportHeader];

  return reportRes;
};
