import * as fb_functions from '../../../functions/FirebaseFunctions'
import Axios from 'axios'

export const rep_pkDetails = async (inputs) => {
    var sDate = inputs.dateRange.startDate
    var eDate = inputs.dateRange.endDate
    var reportRes = {
        fields: ['#', 'Order Name', 'Packing Date', 'Total Packets', 'Packeted By'],
        data: []
    };
    const response = await Axios.post('posIntegration/packeting', {
        stDate: sDate.getFullYear() + "-" + (sDate.getMonth() + 1) + "-" + sDate.getDate() + " 00:00:00",
        enDate: eDate.getFullYear() + "-" + (eDate.getMonth() + 1) + "-" + eDate.getDate() + " 23:59:59"
    })
    response.data.forEach((data) => {
        reportRes.data.push({
            PK_COL_ORDERNO: data.PK_COL_ORDERNO,
            PK_DATE: data.PK_DATE,
            PK_PACKETS: data.PK_PACKETS,
            PK_CRBY: data.PK_CRBY
        })
    })
    return (reportRes)
}