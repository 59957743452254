import Axios from "axios";

export const rep_upcoming_visits = async (inputs) => {
    const response = await Axios.get(`/reports/upcomingVisits?startDate=${inputs.dateRange.startDate}&endDate=${inputs.dateRange.endDate}`)

    const reportRes = {
        fields: ['#',...response.data.reportHeader],
        data: response.data.data
    };

    return (reportRes)
}