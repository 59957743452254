import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import './AlbsCmpMsgModal.css'

const AlbsCmpMsgModal = props => {

    const styles = {
        def: {
            bgColor: 'white',
            color: 'black',
            footer: false
        },
        error: {
            bgColor: 'red',
            color: 'white',
            footer: false
        },
        information: {
            bgColor: 'blue',
            color: 'white',
            footer: false
        },
        confirmation: {
            bgColor: 'blue',
            color: 'white',
            footer: true
        },
        success: {
            bgColor: 'green',
            color: 'white',
            footer: false
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide} animation={true} size="md" >
            <Modal.Header closeButton style={{ backgroundColor: styles[props.type ? props.type : 'def'].bgColor, color: styles[props.type ? props.type : 'def'].color }} >
                {props.loading ? 'Loading...' : 'Notification'}
            </Modal.Header>
            <Modal.Body>
                <div className='row text-center justify-content-center'>
                    {props.loading ? <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner> : props.message}
                </div>
            </Modal.Body>
            <div hidden={!styles[props.type ? props.type : 'def'].footer} className="modal-footer">
                <button onClick={props.onHide} type="button" className="btn btn-outline-secondary btn-sm" data-dismiss="modal">Close</button>
                <button onClick={props.confirmBtnOnClick} type="button" className="btn btn-primary btn-sm">Confirm</button>
            </div>
        </Modal>
    )
}

export default AlbsCmpMsgModal