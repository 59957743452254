import Axios from 'axios'


export const rep_CompletedPickupSummary = async (inputs) => {
    const response = await Axios.get(`/reports/completedPickups?startDate=${inputs.dateRange.startDate}&endDate=${inputs.dateRange.endDate}`)

    console.log();
    var reportRes = {
        fields: ['#',...response.data.reportHeader],
        data: response.data.data
    };

    reportRes.data.push({
        HED_REMARKS: "",
        CM_NAME: "",
        CM_TELEPHONE1: "",
        QTY:response.data.summary.totalQty,
        AMOUNT:response.data.summary.totalAmount,
        delCharge:response.data.summary.totalDeliveryAmount,
        netAmount:response.data.summary.totalNetAmount,
    })
    return (reportRes)

}