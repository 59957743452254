import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Test from "./Test";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./store/reducer";
import { BrowserRouter as Router } from "react-router-dom";
import Axios from "axios";

Axios.defaults.baseURL =
  "https://us-central1-laundromat-online-portal.cloudfunctions.net/app/lmatApi";
// Axios.defaults.baseURL =
//   "http://localhost:5000/laundromat-online-portal/us-central1/app/lmatApi";
// Axios.defaults.baseURL =
//   "https://us-central1-laundromat-development.cloudfunctions.net/app/lmatApi";

const storeCr = createStore(reducer);
const app = (
  <Provider store={storeCr}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
