import React, { useState, useEffect, useRef } from "react";
import "./PickupAllocation.css";
import "react-widgets/dist/css/react-widgets.css";
import firebase from "../../fbConfig/fbConfig";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import Card from '../../components/VaveCard/CmpGenCard/CmpGenCard'
import SettingsBar from '../../components/VaveCmpSettingsBar/VaveCmpSettingsBar'
import MsgModal from '../../components/AlbsCmpMsgModal/AlbsCmpMsgModal'




const PickupAllocation = (props) => {

  //Initial details
  const [initDet, setInitDet] = useState({
    userGroups: [],
    drivers: []
  });

  //Get inputs from report customize section
  const [inputs, setInputs] = useState({
    userGroups: null,
    email: null,
    dateRange: {
      startDate: "",
      endDate: "",
    },
  });

  //Data set
  const [dataSet, setDataSet] = useState([])

  //Select * orders
  const [selectAll, setSelectAll] = useState(false)

  //Circular progress bar on
  const [loading, setLoading] = useState(false);

  //Load details into the report customizer
  useEffect(() => {
    loadUserGroups()
  }, []);

  //load userGroups
  const loadUserGroups = () => {
    firebase
      .firestore()
      .collection("userGroups")
      .get()
      .then((snap) => {
        const arr = [];
        snap.forEach((doc) => {
          arr.push(doc.data().groupName);
        });
        setInitDet((current) => ({ ...current, userGroups: arr.sort() }));
      })
      .catch((error) => console.log(error));
  }

  //Load Emails related to the group
  const loadEmails = (group) => {
    firebase
      .firestore()
      .collection("user")
      .where('group', '==', group)
      .get()
      .then((snap) => {
        const arr = [];
        snap.forEach((doc) => {
          arr.push({ email: doc.data().email, name: doc.data().userName });
        });
        setInitDet((current) => ({ ...current, drivers: arr.sort() }));
      })
      .catch((error) => console.log(error));
  }

  //Reset all the states
  const resetFunction = () => {
    setInitDet({
      userGroups: [],
      drivers: []
    })

    setDataSet([])

    setSelectAll(false)

    setLoading(false)

    setInputs({
      userGroups: null,
      email: null,
      dateRange: {
        startDate: "",
        endDate: "",
      },
    })

    setNotification({
      notificationShow: false,
      notification: '',
      crudOperationType: '',
      type: ''
    })

    loadUserGroups()
  }

  //Load data to the main table whichis shows in th e
  const loadDeliveryOrders = (date) => {
    setDataSet([])
    var beginningDateObject2 = new Date(date);
    var beginningDateObject = new Date(date);
    beginningDateObject.setHours(23);
    beginningDateObject.setMinutes(59);
    beginningDateObject.setSeconds(59);
    firebase.firestore().collection('order')
      .where('pickupDate', '>', beginningDateObject2)
      .where('pickupDate', '<', beginningDateObject)
      .get()
      .then((snap) => {
        snap.forEach((orderDoc) => {
          if (!orderDoc.data().pickupComplete && !orderDoc.data().pickupAsignedUserEmail) {
            setDataSet((current) => ([...current,
            {
              id: orderDoc.id,
              name: orderDoc.data().name,
              city: orderDoc.data().city,
              generatedId: orderDoc.data().generatedId,
              type: "PD"
            }])
            )
          }
        });
      })
      .catch((error) => {
        console.log(error)
      })
  }

  //Values change in text box & drop downs
  const inputChange = (e) => {
    e.persist();
    setInputs((current) => ({ ...current, [e.target.name]: e.target.value }));
  };

  //Custom Input for date picker
  const ExampleCustomInput = ({ value, onClick }) => (
    <div class="input-group" onClick={onClick}>
      <div class="input-group mr-2">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fa fa-calendar"></i>
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Dates"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={value}
        />
      </div>
    </div>
  );

  //Sort data in the page
  const sortData = (field) => {
    setDataSet(cur => [...cur.sort((a, b) => {
      if (a[field] < b[field])
        return -1;
      if (a[field] > b[field])
        return 1;
      return 0;
    })])
  }

  //Styles for the printing page
  const pageStyle = `@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: 210mm 297mm;
    margin-top: 5mm;
  }`

  const componentRef = useRef();

  //Save data
  const saveDetails = () => {
    setLoading(true)
    let db = firebase.firestore()
    let pDbatch = db.batch();
    dataSet.forEach(elem => {
      if (elem.checked)
        pDbatch.update(db.collection('order').doc(elem.id), { "pickupAsignedUserEmail": inputs.email })
    })
    pDbatch.commit()
      .then(res => {
        notificationToggler('success', 'Driver has assigned successfuly.', null);
        setLoading(false)
        resetFunction()
      })
      .catch(error => {
        notificationToggler('error', error.message, null);
        setLoading(false)
      })
  }

  ////////////////////////////////////////////////////////////////
  /**
   * 
   * @param {string} notificationType 
   * @param {string} message 
   * @param {string} crudOperationType 
   */
  const notificationToggler = (notificationType, message, crudOperationType) => {
    if (notificationType == null && message == null && crudOperationType == null) {
      setNotification((cur) => ({
        notificationShow: false,
        notification: message,
        confirmed: false,
        type: notificationType,
        crudOperationType: crudOperationType
      }))
    } else {
      setNotification((cur) => ({
        notificationShow: true,
        notification: message,
        confirmed: false,
        type: notificationType,
        crudOperationType: crudOperationType
      }))
    }

  }

  /**
   * Get Confirmation
   */
  const afterConfirm = async () => {
    switch (notifiaction.crudOperationType) {
      case 'read':
        break;
      case 'insert':
        saveDetails()
        break;
      case 'update':
        break;
      case 'delete':
        break;
      default:
        break;
    }
  }

  //Notification details
  const [notifiaction, setNotification] = useState({
    notificationShow: false,
    notification: '',
    crudOperationType: '',
    type: ''
  })
  ////////////////////////////////////////////////////////////////


  /**
   * Handle Checkout
   */
  const checkoutHandler = async () => {
    if (!inputs.email) {
      notificationToggler('error', 'Please select driver.', null)
    }
    else {
      notificationToggler('confirmation', 'Are you sure?', 'insert')
    }
  }

  return (
    <div className="container" style={{ marginTop: '100px' }}>
      <Card>
        <SettingsBar
          title="Pickup Allocation"
          description="Online delivery portal pickup allocations"
          fafaIcon="fa fa-shopping-bag"
          // search
          // searchButtonOnClick={() => getDetails()}
          spinTogglar={loading}
          print
          pageStyle={pageStyle}
          componentRef={componentRef.current}
          save={true}
          saveButtonOnClick={() => checkoutHandler()}
          refresh
          refreshButtonOnClick={resetFunction}
        />
        <hr className="mt-2" style={{ borderColor: "#707070" }} />
        <div className='row mt-2 justify-content-start' >
          <div className='col-auto'>
            <label className='mb-1'>Delivery Date</label><br></br>
            <DatePicker
              selected={inputs.dateRange.startDate}
              onChange={(value) => {
                setInputs((current) => ({
                  ...current,
                  dateRange: {
                    ...current.dateRange,
                    startDate: value,
                  },
                }))
                loadDeliveryOrders(value)
              }
              }
              customInput={<ExampleCustomInput />}
              dateFormat="MMMM d, yyyy"
            />
          </div>
        </div>
        <div className='row mt-2 justify-content-start' >
          <div className='col-auto'>
            <label className='mb-1'>User Group</label><br></br>
            <div class="input-group">
              <select
                class="custom-select"
                id="inputGroupSelect01"
                name="userGroups"
                onChange={(e) => {
                  inputChange(e)
                  loadEmails(e.target.value)
                }}>
                <option hidden={inputs.userGroups} selected={!inputs.userGroups} value={0}>Choose...</option>
                {initDet?.userGroups.map((userGroups, index) => (<option selected={inputs.userGroups == userGroups} key={index} value={userGroups}>{userGroups}</option>))}
              </select>
            </div>
          </div>
          <div className='col-auto'>
            <label className='mb-1'>User</label><br></br>
            <div class="input-group">
              <select
                class="custom-select"
                id="inputGroupSelect01"
                name="email"
                onChange={inputChange}>
                <option hidden={inputs.email} selected={!inputs.email} value={0}>Choose...</option>
                {initDet?.drivers.map((driver, index) => (<option selected={inputs.email == driver.email} key={index} value={driver.email}>{driver.name}</option>))}
              </select>
            </div>
          </div>
        </div>
      </Card>

      {inputs.dateRange.startDate && <div id='componentRef' ref={componentRef} class="col-auto">
        <div class="row mt--1 justify-content-center">
          <div class="card">
            <table class="table table-responsive">
              <tbody className='col-12'>
                <tr>
                  <th scope="col text-left" >#</th>
                  <th scope="col text-left" ><input type='checkbox' onChange={() => {
                    setDataSet(cur => [...cur.map((value, ind) => ({ ...value, checked: selectAll ? false : true }))])
                    setSelectAll(cur => cur ? false : true)
                  }}></input> </th>
                  <th scope="col text-left" ><a type='button' style={{ color: "blue" }} onClick={(e) => sortData(e.target.dataset.value)} data-value={"generatedId"}>Order Number</a></th>
                  <th scope="col text-left" ><a type='button' style={{ color: "blue" }} onClick={(e) => sortData(e.target.dataset.value)} data-value={"name"}>Customer Name</a></th>
                  <th scope="col text-left" ><a type='button' style={{ color: "blue" }} onClick={(e) => sortData(e.target.dataset.value)} data-value={"city"}>City</a></th>
                </tr>
                {dataSet.map((row, index) =>
                  <tr role='button' onClick={() => { setDataSet(cur => [...cur.map((value, ind) => index == ind ? { ...value, checked: value.checked ? false : true } : { ...value })]) }}>
                    <td scope="col text-left" >{index + 1}</td>
                    <td scope="col text-left" ><input type='checkbox' checked={row.checked}></input></td>
                    <td scope="col text-left" >{row.generatedId}</td>
                    <td scope="col text-left" >{row.name}</td>
                    <td scope="col text-left" >{row.city}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>}
      <MsgModal
        show={notifiaction.notificationShow}
        onHide={() => notificationToggler(null, null, null)}
        message={notifiaction.notification}
        type={notifiaction.type}
        confirmBtnOnClick={afterConfirm}
        loading={loading}
      />

    </div>
  );
};

export default PickupAllocation;
