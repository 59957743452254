import React, { useState } from 'react'

const BackendNewCustomers = props => {
    return (
        <div class="content">
            <div class="panel-header bg-primary-gradient">
                <div class="page-inner py-5">
                    <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                        <div>
                            <h2 class="text-white pb-2 fw-bold">Customer Master</h2>
                            <h5 class="text-white op-7 mb-2">Laundromat Information System</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-inner mt--5">
                <div class="row mt--1 justify-content-center">
                    <div class="col-lg-6 position-sticky" >
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header row py-3" >
                                    <ul class="nav nav-pills card-header-pills">
                                        <li class="nav-item">
                                            <div class="card-title">Add new customer</div>
                                            <div class="card-category">Fill below fields to add a new customer</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row py-3 ml-5 mr-5">
                                    <div class="card-category mb-2">General details</div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Customer Name</span>
                                        </div>
                                        <div class="input-group-prepend">
                                            <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mr. </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#">Mr. </a>
                                                <a class="dropdown-item" href="#">Mrs. </a>
                                                <a class="dropdown-item" href="#">Miss </a>
                                                <a class="dropdown-item" href="#">Dr. </a>
                                                <a class="dropdown-item" href="#">Thero </a>
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Add name with Innecials" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Customer Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>

                                    <div class="card-category mb-2">Contact details</div>

                                    {/* Customer contact details */}
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Customer mobile</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Ex:-0779492569" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Email</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter proper email address" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Address 1</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="12/Main" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Address 2</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Apartment / Floor" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="input-group-text" for="inputGroupSelect01">City</label>
                                        </div>
                                        <select class="custom-select" id="inputGroupSelect01">
                                            <option selected>Choose...</option>
                                            <option value="1">Colombo 01</option>
                                            <option value="2">Colombo 02</option>
                                            <option value="3">Nugegoda</option>
                                        </select>
                                    </div>

                                    <div class="card-category mb-2">Category details</div>
                                    {/* Category details */}
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Category Name</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Category name must be uniqe" aria-label="Username" aria-describedby="basic-addon1" />
                                        <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#searchModal"><i class="fas fa-search" ></i></button>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Category Description</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter relevent description" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {Modal for search} */}
                <div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure?
                             </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackendNewCustomers;