import { Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { firebaseConfig } from "../../fbConfig/fbConfig";
import Map from "../Map/Map";
import Marker from "../Map/Marker";

const CustomMapWrapper = ({ latLng, setLatLng, oriLatLng, hideReset }) => {
    const [center, setCenter] = React.useState(oriLatLng ?? { lat: 6.897396832140912, lng: 79.85950853114169 });

    React.useEffect(() => {
        if(oriLatLng)
            setCenter(oriLatLng)
    },[oriLatLng])

    const onClick = (e) => {
        setLatLng(e.latLng.toJSON());
    };

    const onIdle = (m) => {
        setCenter(m.getCenter().toJSON());
    };

    const onReset = () => {
        setLatLng(oriLatLng);
        setCenter(oriLatLng ?? { lat: 6.897396832140912, lng: 79.85950853114169 });
    };

    return (
        <Accordion className="mb-4">
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    role="button"
                    className="d-flex justify-content-between"
                >
                    Location<span>▼</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Wrapper apiKey={firebaseConfig.apiKey} render={(status) => <h1>{status}</h1>}>
                            <Map
                                style={{ flexGrow: "1", height: "500px" }}
                                onClick={onClick}
                                center={center}
                                onIdle={onIdle}
                            >
                                {latLng && <Marker position={latLng} />}
                            </Map>
                        </Wrapper>
                        {!hideReset && (
                            <Button className="mt-4" onClick={onReset}>
                                Reset
                            </Button>
                        )}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default CustomMapWrapper;
