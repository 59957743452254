import firebase from '../../../fbConfig/fbConfig'

export const rep_dailyPickupDetails = async (inputs) => {
    var reportRes = {
        fields: ['#', 'Order ID', 'Order Date', 'Pickup Date', 'Customer Name', 'Item Name', 'Qty', 'Remarks', 'Amount'],
        data: []
    };
    var beginningDateObject2 = new Date(inputs.dateRange.startDate);
    var beginningDateObject = new Date(inputs.dateRange.endDate);
    if (beginningDateObject2.getDate() == beginningDateObject.getDate()) {
        beginningDateObject.setHours(23);
        beginningDateObject.setMinutes(59);
        beginningDateObject.setSeconds(59);
    }

    const res = await firebase.firestore().collection('order').where('pickupDate', '>', beginningDateObject2).where('pickupDate', '<', beginningDateObject).get()
    for (let a = 0; a < res.docs.length; a++) {
        var d = res.docs[a].data().orderDate.toDate();
        var pd = res.docs[a].data().pickupDate.toDate();
        for (let b = 0; b < (res.docs[a].data().item == null ? 0 : res.docs[a].data().item.length); b++) {
            reportRes.data.push({
                CO_Number: res.docs[a].data().generatedId === null ? 0 : res.docs[a].data().generatedId,
                orderDate: d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear(),
                pickupDate: pd.getDate() + '/' + (pd.getMonth() + 1) + '/' + pd.getFullYear(),
                name: res.docs[a].data().name,
                item: res.docs[a].data().item[b].item,
                quantity: res.docs[a].data().quantity,
                note: res.docs[a].data().note,
                subtot: res.docs[a].data().subtot
            })
        }
    }

    let total = 0.00;
    reportRes.data.forEach(element => {
        if (element.subtot != undefined)
            total = parseFloat(total) + parseFloat(element.subtot);
    });
    if (total != 0.00) {
        reportRes.data.push({
            CO_Number: 'Total',
            orderDate: '',
            pickupDate: '',
            name: '',
            item: '',
            quantity: '',
            note: '',
            subtot: total.toFixed(2)
        })
    }
    console.log(reportRes);
    return (reportRes)
}