import React from 'react'

const BootstrapTxtInput = (props) => {

    return (
        <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend d-none d-md-block">
                <span className="input-group-text" id="inputGroup-sizing-sm">{props.prependText}</span>
            </div>
            <input
                type="text"
                className="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                placeholder={props.placeHolder}
                onChange={props.onChange}
                value={props.value}
                readOnly={props.readOnly}
                onKeyDown={props.onKeyDown}
            />
            <div className="input-group-append" style={{ cursor: 'pointer' }} onClick={props.appendOnClick} hidden={!props.appendShow}>
                <span className="input-group-text" id="basic-addon2"> <i className="fas fa-search"></i></span>
            </div>
        </div>
    )
}

export default BootstrapTxtInput
